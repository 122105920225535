import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { CorporateProfileService } from '../../../../connexdoor/profile/corporate-profile/services/corporate-profile.service';
import { AppService } from '../../../../core/services/app.service';

@Component({
  selector: 'app-problem-statement-popup',
  templateUrl: './problem-statement-popup.component.html',
  styleUrls: ['./problem-statement-popup.component.scss']
})
export class ProblemStatementPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  showError:boolean = false;
  description: any;
  title: any;
  // title = popup.context.data.title;
  constructor(
    private corporateProfileService: CorporateProfileService,
    private appService: AppService
  ) {
   
   }

  onChange(event ?: any) {
    this.description = event.data;
    this.showError = false;
  }
  updateTitle(event ?: any) {
    this.title = event.target.value;
    this.showError = false;
    console.log(event.data);
    console.log(this.title);
    console.log(event);
  }
  

  /* Close Popup Icon Method */
  public closePopup(close_type ?: string){
    this.close.emit(close_type);
  }

  submit() {
    if(this.title === undefined || !this.title && 
      this.description === undefined || !this.description){
      this.showError = true;
      return false;
    }
    let param = {
      "corporate_problem_statement": {
        "title": this.title,
        "description": this.description,
        "company_profile_id": this.popup.context.companyProfileId
      }
    }
    if (this.popup.context.data && this.popup.context.data.id) {
      this.corporateProfileService.updateCorporateProblemStatement(this.popup.context.data.id, param).subscribe(response => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-center',
          context: {
            description: `Problem Statement updated successfully`
          }
        });
        this.closePopup();
        location.reload();
      });
    } else {
      this.corporateProfileService.addCorporateProblemStatement(param).subscribe(response => {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-center',
          context: {
            description: `Problem Statement added successfully`
          }
        });
        this.closePopup();
        location.reload();
      });
    }
  }
  

  ngOnInit(): void {
    // this.title = this.popup.context.data.title;
    // console.log(this.title);
    // console.log(this.popup.context.data);
  }

}
