<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap" rel="stylesheet">
<div class="fintech-menu">
    <div class="width100">
        <div class="flex flexResponsive width100 flexAlignItemsCenter robo">
            <div class="width100 flex flexResponsive flexJustifyStart flexAlignItemsCenter robo">
                <div class="mx-auto robo">
                    <span [ngClass]="{'active' : currentTab == 'FinteCastic Women'}" class="displayInline txtTransformUp color707070 font15 cursorPtr  robo" [routerLinkActive]="'current'" [routerLink]="['/fintecastic-women']">{{ 'FinteCastic Women' | translate }}</span>
                    <span [ngClass]="{'active' :currentTab == 'Research Reports'}" class="displayInline menu-item txtTransformUp color707070 font15  cursorPtr robo" [routerLinkActive]="'current'" [routerLink]="['/fintech-cafe']">{{ 'Fintech Cafe' | translate }}</span>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- ---------------------------------------------------------------------------------------->
<section id="fintecWomen">

    <div class="headSec">
        <div class="width80 mrgnauto">
            <div class="flex flexResponsive">
                <div class="width50">
                    <div class="headTxt padtop50">FINTECASTIC WOMEN </div>
                    <div class="subTxt ">WOMEN + FINTECH = FINTECASTIC!</div>
                    <div class="bodyTxt padtop30 padright20 robo">Let's get your business geared up with Fintech use cases. Come join us for our cohort-based sessions to know how can your business run flawlessly with some Fintech solutions!</div>
                    <div class="bodyTxt2 padtop30 padright20 robo">Join in for our next Fintecastic Women Program with NSRCEL- Goldman Sachs<br> 10,000 Women on <span class="bl"> 13th August'21 from 3pm to 6pm </span>Only few seats left!</div>
                    <div class="flex padtop50 padbtm30">
                        <div class="button-container">
                            <button class="font16 padtop15 padbottom15 btn btn-job ripple btn btn-continue bdrRadius50px" (click)="navigateForm()">REGISTER NOW</button>
                        </div>
                    </div>
                </div>
                <div class="width50">
                    <img src="assets/images/amico.png" />
                </div>
            </div>
        </div>
    </div>
    <div class="width80 padtop20 mrgnauto">

        <div class="head font24 fontWeight700 txtTransformUp txtCenter padtop20 padbtm5 color-3a3a3a">What's in it for Women entrepreneurs?</div>
        <div class="bodyTxt txtCenter padbtm30 robo">We aim at benefitting 2500+ Women Entrepreneurs<br />by December 2021.</div>
        <div class="flex flexResponsive">
            <div class="width32 finCard pad40">
                <div class="padbtm20"><img src="assets/images/know.png" /></div>
                <div class="head font20 fontWeight700 padbtm10 color-EAB328">Gain Knowledge</div>
                <div class="bodyTxt padbtm35 robo">Everything you wanted to know about Fintech, from basics to the latest trends.</div>
                <!-- <div class="padbtm20"><a href="#" class="font16 fontWeight700">Learn More</a></div> -->
            </div>
            <div class="width2">&nbsp;</div>
            <div class="width32 finCard pad40">
                <div class="padbtm20"><img src="assets/images/skill.png" /></div>
                <div class="head font20 fontWeight700 padbtm10 color-EAB328">Upgrade Skills</div>
                <div class="bodyTxt padbtm10 robo">Know about the most interesting Fintech use cases, and how can you best use it in growing your organization.</div>
                <!-- <div class="padbtm20"><a href="#" class="font16 fontWeight700">Learn More</a></div> -->
            </div>
            <div class="width2">&nbsp;</div>
            <div class="width32 finCard pad40">
                <div class="padbtm20"><img src="assets/images/grow.png" /></div>
                <div class="head font20 fontWeight700 padbtm10 color-EAB328">Interact & Grow</div>
                <div class="bodyTxt padbtm5 robo">Get a chance to connect to the Fintech Industry experts to learn and make the right business choices for growing your organization efficiently.</div>
                <!-- <div class="padbtm20"><a href="#" class="font16 fontWeight700">Learn More</a></div> -->
            </div>

        </div>

        <div class="flex flexResponsive flexAlignItemsCenter padtop70 color707070">
            <div class="font24 fontWeight700 color-3a3a3a padright20 txtRight width45">Recent Happenings</div>
            <div class="padleft20 bdrRgt5px">
                <div class="bodyTxt padbtm10 padtop5 robo">First session was held in partnership with IIM Bangalore’s NSRCEL - Women Startup Program (WSP 3.0). We were joined by over 200 Women entrepreneurs and it went 120 minutes long!</div>
                <!-- <div class="flex padbtm10 flexResponsive">
                    <div class="width40 flex flexAlignItemsCenter">
                        <img src="assets/images/cal-icon.png" class="padright20" />
                        <div class="font18 color-7a7a7a">26th March 2021</div>
                    </div>
                    <div class="width50 flex flexAlignItemsCenter">
                        <img src="assets/images/clock-icon.png" class="padright20" />
                        <div class="font18 color-7a7a7a">3:30 PM to 4:30 PM.</div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="head font24 fontWeight600 txtTransformUp txtCenter padtop70 padbtm15 color-3a3a3a posRelative">
            Speakers
            <div class="posAbsolute posTop posRight padtop60">
                <img src="assets/images/left-arrw.png" (click)="scrollLeft()" class="padright20" />
                <img src="assets/images/right-arrw.png" (click)="scrollRight()" />
            </div>

        </div>
        <div class="flex flexResponsive padbtm50">

            <div #scrollBox class="scrollBox">

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/AbhishantPant.jpg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Abhishant Pant</span> <br>Founder<br><span class="bodyTxt">The Fintech Meetup</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Sayali.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Sayali Karanjkar</span> <br>Fintech Entrepreneur<br><span class="bodyTxt">Angel Investor & Advisor</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Madhusudanan.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Madhusudanan R</span> <br>Co-Founder<br><span class="bodyTxt">YAP</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Jose.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Jose Thatill</span> <br>Co-Founder<br><span class="bodyTxt">PhiCommerce</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Chiranth.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Chiranth Patil</span> <br>Co-Founder<br><span class="bodyTxt">Riskcovry</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Parijat.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Parijat Garg</span> <br>Consultant<br><span class="bodyTxt">Digital Lending</span></div>
                </div>

            </div>

        </div>

    </div>

    <div class="fq-sec padtop30 padbtm30 mrgnbtm50">
        <div class="width80 mrgnauto">
            <div class="flex flexResponsive flexAlignItemsCenter">
                <div class="width50 robo">
                    <div class="font30 color-3a3a3a padtop30 padbtm30">Frequently Asked Questions</div>
                    <div class='faq'>
                        <input id='faq-a' type='checkbox'>
                        <label for='faq-a'>
                  <p class="faq-heading">Who can Participate? </p>
                  <div class='faq-arrow'></div>
                    <p class="faq-text">Any women entrepreneur who is actively pursuing the idea and has at least Prototype or MVP of the product / service ready. Incorporation is not necessary.</p>
                </label>
                        <input id='faq-b' type='checkbox'>
                        <label for='faq-b'>
                  <p class="faq-heading">How to Participate?</p>
                  <div class='faq-arrow'></div>
                    <p class="faq-text">Click on the registration link above, and fill the form. Once the form is filled, we will shortly send you a detailed mail along with the dates, timings and webinar link.</p>
                </label>
                        <input id='faq-c' type='checkbox'>
                        <label for='faq-c'>
                  <p class="faq-heading">How long will the session be?</p>
                  <div class='faq-arrow'></div>
                    <p class="faq-text">One session will typically last for anywhere between 60-90 minutes.</p>
                </label>
                        <input id='faq-d' type='checkbox'>
                        <label for='faq-d'>
                  <p class="faq-heading">Is there any deadline to apply for the program?</p>
                  <div class='faq-arrow'></div>
                    <p class="faq-text">No. It’s an open application, throughout the year. You can apply any time, we will provide you with the meeting details of the very next session. </p>
                </label>
                        <input id='faq-e' type='checkbox'>
                        <label for='faq-e'>
                  <p class="faq-heading">Can I change the time once I receive the meeting mail?</p>
                  <div class='faq-arrow'></div>
                    <p class="faq-text">No, we unfortunately won’t be able to accommodate the changes since the session happens in groups. You can apply again in case you have missed, we’ll invite you for the next session.</p>
                </label>
                        <input id='settings' type='checkbox'>
                        <input id='faq-f' type='checkbox'>
                        <label for='faq-f'>
                  <p class="faq-heading">Is there any cost for attending this meeting?</p>
                  <div class='faq-arrow'></div>
                    <p class="faq-text">No, we do not charge any fee for these sessions.</p>
                </label>
                    </div>
                    <div class=" pad20 font16 color-3a3a3a padbtm40"> Any other queries? Reach us out at <a href="mailto:info@connexdoor.com" style="text-decoration: underline !important;font-weight: bolder !important;">info@connexdoor.com</a></div>
                </div>
                <div class="width50 pad20">
                    <img src="assets/images/fq.png" />
                </div>
            </div>
        </div>
    </div>

</section>