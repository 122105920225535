import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
@Component({
  selector: 'app-preview-jd-popup',
  templateUrl: './preview-jd-popup.component.html',
  styleUrls: ['./preview-jd-popup.component.scss']
})
export class PreviewJdPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  jdDetails:any;
  location:string;
  role:string;
  skills:any =[];
  constructor() { }

  ngOnInit(): void {
    this.jdDetails = this.popup.context.formValues;
    this.location = this.popup.context.location;
    this.skills = this.popup.context.skills;
    this.role = this.popup.context.role;
  }
  closePopup(){
    this.close.emit('icon');
  }

}
