<div class="notification-with-action" *ngIf="notification.status == 'New'">
    <div class="notificationCreated">{{notification?.updated_at | date: 'dd-MMM-yyyy hh:mm' }}</div>

    <div class="notificationTitle mrgnbtm5">{{notification?.title}}</div>
    <div class="notificationMessage">
        {{notification.description}}
    </div>
    <div class="button-container">
        <button class="reject ripple" *ngIf="notification.reject_url"
            (click)="notificationCTA(notification.reject_url)">{{'reject' | translate}}</button>
        <button class="approve ripple mrgn0" *ngIf="notification.accept_url"
            (click)="notificationCTA(notification.accept_url)">{{'accept' | translate}}</button>
        <button class="approve ripple mrgn0" *ngIf="notification.confirm_url"
            (click)="notificationCTA(notification.confirm_url)">{{'confirm' | translate}}</button>    
    </div>
</div>