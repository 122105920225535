<footer>
    <div class="flex flexResponsive padtop25 padbtm40 flexJustifyBetween">

        <div class="flex1">
            <div class="flex padtop25">
                <img src="assets/images/footer-logo.svg" class="desktopVisible">
                <img src="assets/images/footer-logo.svg" class="mobiVisible connexDoorLogo">
            </div>
        </div>
        <div class="flex2">
            <div class="flex">
                <div class="flex1">
                    <ul class="listStyleNone">
                        <li class="link lineHeight36px" [routerLink]="['/jobs']">Jobs</li>
                        <li class="link lineHeight36px" [routerLink]="['/startup']">Startups</li>
                        <li class="link lineHeight36px" [routerLink]="['/investors']">Investors</li>
                        <li class="link lineHeight36px" [routerLink]="['/corporates']">Corporates</li>
                        <li class="link lineHeight36px" [routerLink]="['/people/list']">People</li>
                    </ul>
                </div>
                <div class="flex1">
                    <ul class="listStyleNone">
                        <li class="link lineHeight36px"[routerLink]="['/about-us']" target="_blank">About & Team</li>
                        <li class="link lineHeight36px" [routerLink]="['/contact-us']" target="_blank">Contact</li>
                        <li class="link lineHeight36px" [routerLink]="['/privacy-policy']" target="_blank">Terms</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="flex1">
            <ul class="listStyleNone padleft15 txtCenter">
                <li class="displayInline mrgn8 cursorPtr">
                    <a href="https://www.facebook.com/Connexdoor-104830341196315/?modal=admin_todo_tour" target="_blank">
                        <img src="assets/images/facebook.svg">
                    </a>
                </li>
                <li class="displayInline mrgn8">
                    <a href="https://www.instagram.com/connexdoor/" target="_blank"><img src="assets/images/instagram.svg"></a>
                </li>
                <li class="displayInline mrgn8">
                    <a href="https://www.linkedin.com/company/connexdoor1/?viewAsMember=true" target="_blank"><img src="assets/images/linkedin.svg"></a>
                </li>
                <li class="displayInline mrgn8">
                    <a href="https://www.youtube.com/channel/UCFGPWN20qIBHSk_lG7yeJqA" target="_blank"><img src="assets/images/youtube.svg"></a>
                </li>
            </ul>
        </div>
    </div>
</footer>