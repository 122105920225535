import { LocationModel } from './location.model';
import { ListingModel } from './other.model';
import { MediaModel } from './media.model';
import { ProfileDropDownModel } from './profile-form.model';

export class CompanyDetailModel {
    id?: any = 0;
    name?: string = '';
    company_profile: CompanyProfileModel = new CompanyProfileModel();
    logo ?: string = '';
    background_image ?: string = '';
    can_edit ?: boolean = false;
    can_invite ?: boolean = false;
    profile_completion ?: number = 0;
    connection_status ?:string;

}

export class CompanyProfileModel {
    id ?: number = 0;
    organisation_type ?: string = '';
    name ?: string='';
    logo ?: string = '';
    logo_id ?: number;
    company_id ?: any = 0;
    background_image ?: string = '';
    background_image_id ?:number;
    website_url ?: string = '';
    tagline ?: string = '';
    employees ?: string = '';
    email ?: string = '';
    overview ?: string= '';
    problem_statement ?: any;
    corporate_problem_statements_attributes?:any[] = [];
    solution ?: any;
    business_model ?: any;
    organisation_status ?: any;
    corporate_video_url ?: string = '';
    founding_date ?: string = '';
    growth_status ?: any;
    revenue ?: any;
    plans_for_funding ?: any;
    linkedin_url ?: string = '';
    facebook_url ?: string = '';
    youtube_url ?: string = '';
    twitter_url ?: string = '';
    angel_list ?: any;
    contact_number ?: string = '';
    address ?: string = '';
    media_mentions ?: MediaModel[] = []; 
    location ?: LocationModel = new LocationModel();
    industry ?: ListingModel = new ListingModel();
    industries ?:any[] = [];
    segment : ListingModel = new ListingModel();
    fundings ?: any[] = [];
    profile_completeness ?: number;
    investor_id ?: number = 0;
    user_profile_id?: number;
    preferred_funding_round?:string;
    preferred_funding_amount?:string;
    connect_status?: string;
    currently_hiring ?: any;
}

export class JobsListedCompanyProfileModel {
    can_edit ?: boolean = false;
    can_invite ?: boolean = false;
    can_delete ?: boolean = false;
    company_profile ?: CompanyProfileModel = new CompanyProfileModel();
}

export class CompanyFilterFormModel {
    industry : ProfileDropDownModel = new ProfileDropDownModel();
    segment: ProfileDropDownModel = new ProfileDropDownModel();
    location : ProfileDropDownModel = new ProfileDropDownModel();
}

export class CorporateListFormModel {
    location : ProfileDropDownModel = new ProfileDropDownModel();
    industry : ProfileDropDownModel = new ProfileDropDownModel();
    segment: ProfileDropDownModel = new ProfileDropDownModel();

}