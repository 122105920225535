import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppService } from './../../../../core/services/app.service';
import { PopupModel } from 'src/app/core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apply-job-success',
  templateUrl: './apply-job-success.component.html',
  styleUrls: ['./apply-job-success.component.scss']
})
export class ApplyJobSuccessComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();


  constructor(private appService: AppService,private router:Router) { }

  ngOnInit() {
    this.openPopup();
  }
  openPopup(){
    this.appService.togglePopup({
      type: 'apply-job-success',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {}
    });
  }
  closePopup() {
    this.router.navigate(["/jobs"]);
    this.close.emit('icon');
  }  
}
