<div class="feedback-section">
    <div class="feedback-button btn-1" (click)=enableFeedbackCard() *ngIf="!feedbackCard">
        <img src="assets/images/feedback-icon.png">
        <p>Feedback</p>
    </div>
    <div class="feedback-card animated fadeInUp" [formGroup]="feedbackForm" *ngIf="feedbackCard">
        <h3 (click)="feedbackCard = false">X</h3>
        <p>Your Feedback is important to us</p>
        <h2>we are open for any suggestions</h2>
        <input placeholder="Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
        <div *ngIf="submitted && f.name.errors">
            <h5 *ngIf="f.name.errors.required">Name is Required</h5>
            <h5 *ngIf="f.name.errors.pattern">Enter a valid name</h5>
        </div>
        <input placeholder="E-mail" formControlName="email" [ngClass]="{ 'is-invalid': submitted && invalidEmail }" appDebounce [debounceTime]="100" (debounceClick)="checkEmail()">
        <h5 *ngIf="invalidEmail">Email must be a valid email address</h5>
        <textarea class="roboto-regular" placeholder="Message" formControlName="discription" [ngClass]="{ 'is-invalid': submitted && f.discription.errors }"></textarea>
        <div *ngIf="submitted && f.discription.errors" class="invalid-feedback">
            <h5 class="mar-20" *ngIf="f.discription.errors.required">Message is required</h5>
        </div>
        <h5></h5>
        <button class="font18 btn btn-job ripple btn-1" (click)=submitFeedback()>SUBMIT</button>
    </div>
</div>
<div #outSection>
    <div class="container bg-white">
        <div class="flex  padtop100 flexJustifyBetween">
            <div>
                <img src="assets/images/group_437.svg">
            </div>
            <div>
                <img src="assets/images/path.svg">
            </div>
        </div>
        <section class="landing-container ">

            <div class="flex mobFlexReverse">
                <div class="flex2 ">
                    <div class="head font30 fontWeight600">{{'catalysing the journey of early stage startups in india beyond' | translate }}</div>
                    <div class="description fontWeight600 padtop15 font20 width70">{{ 'welcome to connexdoor' | translate}}</div>
                    <div class="description padtop10 font20 width70">{{ 'we are a digital platform for promoting entrepreneurship aiming to benefit all stakeholders by using a matchmaking algorithm' | translate}}</div>
                    <!-- <div class="head-1 font18 COLOR319966 padtop10 padbottom15">{{'finding the right connect can be as hard as nailing a jelly to the wall' | translate}}</div>  -->
                    <div class="head-1 font18 COLOR319966 padtop10 padbottom15">{{'connect. collaborate. grow' | translate}}</div>
                    <div class="flex flexResponsive padtop15">
                        <div class="button-container padright30">
                            <button class="font18 padtop15 padbottom15 btn btn-job ripple btn-1" (click)="scroll(target)">{{'explore our services' | translate}}</button>
                        </div>
                    </div>
                </div>
                <div class="flex1">
                    <img class="width100 mrgnleftM40p mobLandingImg" src="assets/images/connexbrand.png">
                </div>
            </div>

        </section>
        <div class="flex flexJustifyBetween">
            <div>
                <img class="mrgnleftM40p" src="assets/images/group_1.svg">
            </div>

            <div>
                <img class="width50" src="assets/images/group_435.svg">
            </div>
        </div>

        <app-landing-card [cardData]="recentJobs"></app-landing-card>

    </div>

    <div class="calc-bg">
        <div class="container">
            <div class="flex valuation-container mobFlexReverse">
                <div class="flex1 pdr5">
                    <div class="head fontWeight600">Startup Valuation Calculator</div>
                    <div class="flex ml-5">
                        <div class="description padtop15">Powered by</div>
                        <img src="/assets/images/Startup_Falcon_Logo 1.png">
                    </div>
                    <div class="list">
                        <div class="flex list-element">
                            <img src="/assets/images/tick.png">
                            <p>Uncover Your Startups Valuation</p>
                        </div>
                        <div class="flex list-element">
                            <img src="/assets/images/tick.png">
                            <p>Globally recognized valuation methods</p>
                        </div>
                        <div class="flex list-element">
                            <img src="/assets/images/tick.png">
                            <p>AI-Powered Calculator</p>
                        </div>
                        <div class="flex list-element">
                            <img src="/assets/images/tick.png">
                            <p>Benchmarking Data</p>
                        </div>
                        <div class="flex list-element mrb">
                            <img src="/assets/images/tick.png">
                            <p>Idea and market validation</p>
                        </div>
                        <p class="valbutton" (click)="navigateCalculator()">Get Your Valuation</p>
                    </div>
                </div>
                <div class="flex1 right">
                    <img class="clac-img" src="/assets/images/amico-cal.png">
                </div>
            </div>
        </div>
    </div>


    <div class="container bg-white">

        <div #target class="flex flexResponsive width100">
            <section class="width50 post-job mrgntop40 mrgnright40">
                <div class="flex flexResponsive">
                    <div class="flex6 content">
                        <div class="setHeight">
                            <div class="head-1 font30 padtop40 txtCenter">{{'bfsi corporate' | translate}}</div>
                            <div class="head-1 padtop30 padbottom30 fontWeight600 font22 headTxt">{{'tell us about the problem statements you are seeking help for' | translate}} </div>
                            <!-- <ul class="pad0">
                        <li class="font18 padbottom15">{{'explore the pool of fintech startups apply filters find the perfect match and connect' | translate}}</li>
                        <li class="font18 padbottom15">{{'grow your team by hiring perfect set of people give us your requirements and leave it to the algos' | translate}}</li>
                    </ul> -->
                        </div>


                        <div class="flex flexResponsive boxLine">
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/corporates/create']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/buildprofile.svg" height="35">
                                    </div>

                                    <!-- <i class="flex2 icns icon-post-job width100"></i> -->
                                    <p class=" btn btn-continue colorWhite ripple head-1 title padleft10 corporate-btn">{{'build profile' | translate}}</p>
                                </div>


                            </div>
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/startup']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/postjob.svg" height="35">
                                    </div>
                                    <!-- <p class="head-1 title padleft10">{{'post job' | translate}}</p> -->

                                    <p class=" btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'collaborate & invest in fintechs' | translate}}</p>
                                </div>
                            </div>
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/dashboard/mystartups']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/hiretalent.svg" height="35">
                                    </div>

                                    <p class="btn btn-continue colorWhite  head-1 title padleft10 corporate-btn">{{'hire talent' | translate}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="flex flexResponsive padtop35 flexJustifyCenter ">
                        <div class="button-container padright30">
                            <button class="btn btn-job ripple btn-1" (click)="jobCTA('bfsi corporate')">{{'explore fintechs now'| translate}}</button>
                        </div>
                    </div>
                    <div class="flex flexResponsive padtop15 flexJustifyCenter ">
                        <div class="button-container padright40 padtop20">
                            <button class="head-1 font14 post-now-btn txtDecorationUnderline cursorPtr" (click)="jobCTA('company')">{{'post job now' | translate}}</button>
                        </div>
                    </div> -->
                    </div>
                </div>
            </section>

            <section class="width50 next-post-job mrgntop40">
                <div class="flex flexResponsive">
                    <div class="flex6 content">
                        <div class="setHeight">
                            <div class="head-1 font30 padtop40 txtCenter">{{'fintech startups' | translate}}</div>
                            <div class="head-1 padtop30 fontWeight600 font22 headTxt">{{'Discover Fintechs and find investment opportunities' | translate}} </div>
                            <!-- <ul class="pad0">
                        <li class="font18 padbottom15">{{'hire the right co-founder cxos and startup employees with proven ability and passion to work in a startup' | translate}}</li>
                        <li class="font18 padbottom15">{{'find the investor for your company who not only brings capital but also subject matter expertise' | translate}}</li>
                        <li class="font18 padbottom15">{{'explore the corporates whose problem statement matches your solution' | translate}}</li>
                    </ul> -->
                        </div>
                        <div class="flex flexResponsive boxLine">
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/investors']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/buildprofile.svg" height="35">
                                    </div>

                                    <!-- <i class="flex2 icns icon-post-job width100"></i> -->
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'find investors' | translate}}</p>
                                </div>

                            </div>
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/corporates']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/postjob.svg" height="35">
                                    </div>
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'find bfsi partners' | translate}}</p>
                                </div>
                            </div>
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/dashboard/mystartups']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/hiretalent.svg" height="35">
                                    </div>
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'hire talent' | translate}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="flex flexResponsive padtop35 flexJustifyCenter ">
                        <div class="button-container padright30">
                            <button class="btn btn-continue ripple btn-1" (click)="jobCTA('fintech startup')">{{'main cta' | translate}}</button>
                        </div>
                    </div>
                    <div class="flex flexResponsive padtop15 flexJustifyCenter ">
                        <div class="button-container padright40 padtop20">
                            <button class="head-1 font14 post-now-btn txtDecorationUnderline cursorPtr" (click)="jobCTA('company')">{{'post job now' | translate}}</button>
                        </div>
                    </div> -->
                    </div>
                </div>
            </section>
        </div>
        <div class="flex flexResponsive width100">
            <section class="width50 next-post-job mrgntop40 mrgnright40">
                <div class="flex flexResponsive">
                    <div class="flex6 content">
                        <div class="setHeight">
                            <div class="head-1 font30 padtop40 txtCenter">{{'investors' | translate}}</div>
                            <div class="head-1 padtop30 fontWeight600 font22 headTxt">{{'Discover Fintechs and find investment opportunities' | translate}} </div>
                            <!-- <ul class="pad0">
                            <li class="font18 padbottom15">{{'explore fintech startups along with access to their pitch decks and other relevant information in one place' | translate}}</li>
                            <li class="font18 padbottom15">{{'explore fintech startups looking for investments' | translate}}</li>
                        </ul> -->
                        </div>
                        <div class="flex flexResponsive padbottom25 padtop25 boxLine">
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/investors/create']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/buildprofile.svg" height="35">
                                    </div>
                                    <!-- <i class="flex2 icns icon-post-job width100"></i> -->
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'build profile' | translate}}</p>
                                </div>

                            </div>
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/startup']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/postjob.svg" height="35">
                                    </div>
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'discover fintech' | translate}}</p>
                                </div>
                            </div>
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/startup']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/hiretalent.svg" height="35">
                                    </div>
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'find investments' | translate}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="flex flexResponsive padtop35 flexJustifyCenter ">
                            <div class="button-container padright30">
                                <button class="btn btn-continue ripple btn-1" (click)="jobCTA('investor')">{{'explore fintechs now' | translate}}</button>
                            </div>
                        </div>
                        <div class="flex flexResponsive padtop15 flexJustifyCenter ">
                            <div class="button-container padright40 padtop20">
                                <button class="head-1 font14 post-now-btn txtDecorationUnderline" (click)="jobCTA('finship')">{{'read finsip now' | translate}}</button>
                            </div>
                        </div> -->
                    </div>
                </div>
            </section>

            <section class="width50 post-job mrgntop40">
                <div class="flex flexResponsive">
                    <div class="flex6 content">
                        <div class="setHeight">
                            <div class="head-1 font30 padtop40 txtCenter">{{'individuals' | translate}}</div>
                            <div class="head-1 padtop30 fontWeight600 font24 headTxt">{{'Build your network and explore jobs in Fintechs' | translate}} </div>
                            <!-- <ul class="pad0">
                            <li class="font18 padbottom15">{{'find your dream job in fintech startups and bfsi corporates' | translate}}</li>
                            <li class="font18 padbottom15">{{'explore the fintech space with a quick glace at startups and fintech stories in a pararaph finsip' | translate}}</li>
                        </ul> -->
                        </div>
                        <div class="flex flexResponsive padbottom25 padtop25 boxLine">
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/user-profile/create-profile']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/buildprofile.svg" height="35">
                                    </div>
                                    <!-- <i class="flex2 icns icon-post-job width100"></i> -->
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'build profile' | translate}}</p>
                                </div>

                            </div>
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/jobs']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/postjob.svg" height="35">
                                    </div>
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'explore job opportunities' | translate}}</p>
                                </div>
                            </div>
                            <div class="flex2 mobTxtCenter cursorPtr mrgnright10">
                                <div class="txtCenter mobFlex corporate-container" [routerLink]="['/people/list']">
                                    <div class="inner corporate-img">
                                        <img class="iconWidth" src="assets/images/hiretalent.svg" height="35">
                                    </div>
                                    <p class="btn btn-continue colorWhite head-1 title padleft10 corporate-btn">{{'expand your network' | translate}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="flex flexResponsive padtop35 flexJustifyCenter ">
                            <div class="button-container padright30">
                                <button class="btn btn-job ripple btn-1" (click)="jobCTA('individual')">{{'find job now' | translate}}</button>
                            </div>
                        </div>
                        <div class="flex flexResponsive padtop15 flexJustifyCenter ">
                            <div class="button-container padright40 padtop20">
                                <button class="head-1 font12 post-now-btn txtDecorationUnderline cursorPtr" (click)="jobCTA('finship')">{{'read finsip now' | translate}}</button>
                            </div>
                        </div> -->
                    </div>
                </div>
            </section>
        </div>
        <hr class="mrgnbtm20 mrgntop20" />
        <!-- finsip story start-->
        <section class="flex pad10 flexJustifyStart storyContainer">
            <div class="flex2 stryHeadContainer">
                <img class="width100 mobLandingImg stryImg" src="assets/images/finsipStoryImg.png">

            </div>
            <div class="flex1">
                <div>
                    <div class="flex flexResponsive width100 takeDoseContainer">
                        <div class="button-container padright30 tkDose">
                            <button class="btn btn-continue ripple btn-1 btn-2" [routerLink]="['/finsip']">{{'take your dose' | translate}}</button>
                        </div>
                    </div>
                    <div class="storyTxtContainer">
                        <div class="head-1 COLOR38453F font16 padtop10 padbottom15 normal-white-space stryHead">
                            {{'fintech stories in a paragraph' | translate}}
                        </div>
                        <div class="roboto-regular font14 color58605D padbtm10 normal-white-space stryContent">
                            {{'mutual fund sip for creating wealth, weekly fin-sip for becoming the fintech expert' | translate}}
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <!-- finsip story end -->
        <!------------------------------------------Future Services -------------------------------->
        <!--  <div class="padtop15 flexJustifyStart ">
                <div class="flex flexResponsive head-1 font23 button-container padright40 COLOR38453F padtop20 padbottom25">
                    <img src="assets/images/noun_Future.svg" height="20" width="25"> {{'future services' | translate}} :
                </div>
                <section class="flex">
                    <div class="flex">
                        <img class="pull-left custom_slider_left_arrow" (click)="scrollLeft()" src="/assets/images/arrow_left.svg" alt="">
                    </div>
                    <div id="sliderDiv" class="flex scrollmenu">
                        <a class="flex3 serviceCard ">
                            <div class="pad20">
                                <div class="txtCenter">
                                    <img src="assets/images/startup-service.svg" height="80" width="80">
                                </div>
                                <div class="head-1 COLOR38453F font16 padtop10 padbottom15 normal-white-space">
                                    {{'startup service as a service' | translate}}
                                    {{'b2b marketplace' | translate }}
                                </div>
                                <div class="roboto-regular font14 color58605D padbtm10 normal-white-space">
                                    {{'an initiative to uplift the startup ecosystem where startups can be both suppliers or customers at different instances' | translate}}
                                    {{'connecting the puzzled startup pieces to build a community!' | translate}}
                                </div>
                            </div>
                        </a>
                        <a class="flex3 serviceCard">
                            <div class="pad20">
                                <div class="txtCenter">
                                    <img src="assets/images/funding-service.svg" height="80" width="80">
                                </div>
                                <div class="head-1 COLOR38453F font16 padtop10 padbottom15 normal-white-space">
                                    {{'funding services' | translate}} 
                                    {{'peripheral services' | translate}}
                                </div>
                                <div class="roboto-regular font14 color58605D padbtm10 normal-white-space">
                                    {{"we ll help startups to connect with relevant angel investors and vcs after considering various data points like subject matter expertise or stage of funding" | translate}} 
                                    {{'one place for all minute services like legal, pr, hr, etc.' | translate}}
                                </div>
                            </div>
                        </a>
                        <a class="flex3 serviceCard">
                            <div class="pad20">
                                <div class="txtCenter">
                                    <img src="assets/images/startup-service.svg" height="80" width="80">
                                </div>
                                <div class="head-1 COLOR38453F font16 padtop10 padbottom15 normal-white-space">
                                    {{'community builder' | translate}}
                                </div>
                                <div class="roboto-regular font14 color58605D padbtm10 normal-white-space">
                                    {{'your happy place with like-minded people!' | translate}}
                                </div>
                            </div>
                        </a>
                         <a class="serviceCard ">
                            <div class="pad35">
                                    <div class="txtCenter">
                                            <img src="assets/images/networking.svg">
                                    </div>
                                    <div class="head-1 COLOR38453F font20 padtop10 padbottom15 normal-white-space">
                                            {{'networking' | translate}}
                                            
                                    </div>
                                    <div class="roboto-regular font18 color58605D padbtm10 normal-white-space">
                                            {{'access to a group of professionals with similar interest for mentorship and guidance' | translate}}
                                    </div>
                            </div>
                        </a>
                        <a class="serviceCard ">
                            <div class="pad35">
                                    <div class="txtCenter">
                                            <img src="assets/images/startup-service.svg">
                                    </div>
                                    <div class="head-1 COLOR38453F font20 padtop10 padbottom15 normal-white-space">
                                            {{'plug n play' | translate}}
                                            
                                    </div>
                                    <div class="roboto-regular font18 color58605D padbtm10 normal-white-space">
                                            {{'developing a layer of 80+ open apis accessible by startups and corporates creating a possibility to build a full fledged bank in 48 hours' | translate}}
                                    </div>
                            </div>
                        </a> 
                    </div>
                    <div class="flex">
                        <img class="pull-right custom_slider_right_arrow rotate-180deg" (click)="scrollRight()" src="/assets/images/arrow_left.svg" alt=""> 
                    </div>
                </section>
            </div> -->

        <!------------------------------------------Future Services Ends-------------------------------->
        <section class="leading-fin-container mrgntop40">
            <div class="head-1 font16 fontWeight600 padbottom15">{{'some of the leading fintech and bfsi corporates at our platform' | translate}}
            </div>

            <div class="pos-rel">
                <img *ngIf="partnerScroll" class="arrow-left cursorPtr" src="assets/images/left-arrw.png" (click)="scrollLeft()" />
                <img *ngIf="partnerScroll" class="arrow-right cursorPtr" src="assets/images/right-arrw.png" (click)="scrollRight()" />
                <div #scrollBox class="scrollBox">
                    <ng-container *ngFor="let item of partner; let i = index">
                        <div class="spCard flexAlignItemsCenter pad40">
                            <div class="txtCenter" *ngIf="item.company_profile.organisation_type != 'Corporate'">
                                <img class="partner_logo cursorPtr" *ngIf="!item.company_profile?.logo" src="assets/images/zestmoney.png" routerLink="/startup/{{item?.company_profile?.id}}">
                                <img class="partner_logo cursorPtr" *ngIf="item.company_profile?.logo" src="{{item.company_profile?.logo}}" routerLink="/startup/{{item?.company_profile?.id}}">
                            </div>
                            <div class="txtCenter" *ngIf="item.company_profile.organisation_type == 'Corporate'">
                                <img class="partner_logo cursorPtr" *ngIf="!item.company_profile?.logo" src="assets/images/zestmoney.png" routerLink="/corporates/{{item?.company_profile?.id}}">
                                <img class="partner_logo cursorPtr" *ngIf="item.company_profile?.logo" src="{{item.company_profile?.logo}}" routerLink="/corporates/{{item?.company_profile?.id}}">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>


            <!-- <div class="flex flexResponsive pad1015 flexJustifyBetween">
                <ng-container *ngFor="let item of partner;">
                    <div class="flex3 txtCenter pad30" *ngIf="item.company_profile.organisation_type != 'Corporate'">
                        <img class="partner_logo cursorPtr" *ngIf="!item.company_profile?.logo" src="assets/images/zestmoney.png" routerLink="/startup/{{item?.company_profile?.id}}">
                        <img class="partner_logo cursorPtr" *ngIf="item.company_profile?.logo" src="{{item.company_profile?.logo}}" routerLink="/startup/{{item?.company_profile?.id}}">
                    </div>
                    <div class="flex3 txtCenter pad30" *ngIf="item.company_profile.organisation_type == 'Corporate'">
                        <img class="partner_logo cursorPtr" *ngIf="!item.company_profile?.logo" src="assets/images/zestmoney.png" routerLink="/corporates/{{item?.company_profile?.id}}">
                        <img class="partner_logo cursorPtr" *ngIf="item.company_profile?.logo" src="{{item.company_profile?.logo}}" routerLink="/corporates/{{item?.company_profile?.id}}">
                    </div>
                </ng-container>
            </div> -->

        </section>
    </div>
</div>