import { Component, OnInit, OnDestroy, Output, Input, EventEmitter, OnChanges } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { AppService } from '../../../../core/services/app.service';
import { AppStore } from '../../../../core/stores/app.store';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { isValidEmail, isValidMobile } from 'src/app/core/essentials/common.libs';
import { Router } from '@angular/router';

import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { config } from 'src/environments/environment';
@Component({
  selector: 'app-signin-popup',
  templateUrl: './signin-popup.component.html',
  styleUrls: ['./signin-popup.component.scss']
})
export class SigninPopupComponent implements OnChanges, OnDestroy {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  subscription: Subscription = new Subscription();
  passwordType: string = "password";
  signInForm: any;
  errorMsg:string='';
  isInvalidInput: boolean = false;
  user: SocialUser;
  loggedIn: boolean;
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    private appStore: AppStore,
    private router: Router,
    private authService: SocialAuthService
  ) {

    this.signInForm = formBuilder.group({
      "user":  formBuilder.group({
        "username": ['', Validators.required],
        "password": ['', Validators.required]
      })
    });

  }

  /* form controls returns */
	f(controlName?: any) {
		return this.signInForm.get('user').get(controlName)
	}

  /* check valid mobile no or email  */
	checkIsValidMobileOrEmail(event?: any) {
		this.isInvalidInput = true;
		let value = this.signInForm.value.user.username;

    if (isValidEmail(value) || isValidMobile(value)) {
      this.isInvalidInput = false;
    }
	}

  /* sign in form submit method */
  onSubmit() { 

    this.subscription.add(
      this.appService.signIn(this.signInForm.value).subscribe((response: any) => {
        this.appStore.signInUserInfoPopupMethod();
        this.appService.toggleToast({
            type: 'success',
            load: true,
            position: 'bottom-center',
            context: { description: `Hi,welcome ${this.appStore.userInformation.username}` }
        });
        this.close.emit('icon');
      },
      (errorMessage)=>{
        this.appService.toggleToast({
          type: 'error',
          load: true,
          position: 'top-right',
          context: { description: errorMessage }
        });
        // this.errorMsg = errorMessage;
      }
      )
    )
  }

  /* Close Popup Method */
  closePopup() {
    this.close.emit('icon');
  }

  /* toggle password eye method */
  toggleEye() {
    if (this.passwordType == 'password') {
      this.passwordType = "text";
    } else {
      this.passwordType = 'password';
    }
  }

  /* forgot password model toggle method */
  forgotPassword() {
    this.appService.togglePopup({
      type: 'forgotPassword',
      load: true,
      refreshParent: false,
      overlayClose: true,
      context: {}
    });
  }
  
  /* Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here. */
  ngOnChanges(): void {
    this.subscription.add(
      this.authService.initState.subscribe(() => {}, console.error, () => {
        this.authService.authState.subscribe((user) => {
          if (user) {
            this.loginUser(user)
            this.user = user;
            this.loggedIn = (user != null);
          }
        });   
      })
    )
  }

  loginUser(user: any) {
    const param = {
      "user": {
        "username": user.email,
        "provider": user.provider,
        "uid": user.id,
        "auth_token": user.authToken,
        "first_name": user.firstName,
        "last_name": user.lastName,
        "image_url": user.photoUrl,
        "email": user.email  
      }
    }
    this.subscription.add(
      this.appService.updateSocialSignIn(param).subscribe((response: any) => {
        this.appStore.signUpUserInformationMethod();
        if (this.appStore.userInformation.user_profile.profile_completeness  < 15) {
          this.router.navigate(['/user-profile/create-profile'])
        }
        this.appService.toggleToast({
            type: 'success',
            load: true,
            position: 'bottom-center',
            context: { description: `Hi,welcome ${response.user.username}` }
        });
        this.close.emit('icon');
      })
    )
    
  }
  /* Sign Up CTA Method */
  signUp() {
    this.appService.togglePopup({
      type: 'findOrPostSignUp',
      load: true,
      refreshParent: false,
      overlayClose: true,
      context: {}
    });
  }
	/* Social Sign IN Method */
	socialSignIn(type: string) {
		switch (type) {
			case 'facebook':
				this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
				break;
			case 'google':
				this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
				break;
			case 'linkedin':
				this.signInWithLinkedin();
				break;	
			default:
				break;
		}
  }
  signInWithLinkedin() {
		window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.socialCredentials.linkedinClientId}&redirect_uri=${config.linkedinRedirectURI}&state=fooobar&scope=r_liteprofile,r_emailaddress`;
  }
  
  /* ng destroy life cycle */
	ngOnDestroy(): void {
    
		if (this.subscription) {
      this.subscription.unsubscribe();
      this.authService.signOut();
    }
			
	}

}
