<!DOCTYPE html>
<html>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>

<div class="relative bg-white"> 
 <div class="terms flex flexJustifyCenter">
    <div class="width50 padtop50">
        <div class="headline-s01 cldgr padtop50 padbtm20">Connexdoor - Terms of Service</div>
        <div class="headline-02 clgy padtop20">1. Terms </div>
        <div class="body-copy clgy padtop10">By accessing the website at www.connexdoor.com, you are agreeing to be bound by these terms of service, all applicable laws, and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
        </div>
        <div class="headline-02 clgy padtop20">2. Disclaimer</div>
        <div class="body-copy clgy padtop10">
            a.The materials on the Connexdoor website are provided on an 'as is' basis. Connexdoor makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.<br>
b.Further, Connexdoor does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.

        </div>
        <div class="headline-02 clgy padtop20">3. Limitations</div>
        <div class="body-copy clgy padtop10">In no event shall Connexdoor or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Connexdoor website, even if Connexdoor authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
        </div>
        <div class="headline-02 clgy padtop20">4. Accuracy of materials</div>
        <div class="body-copy clgy padtop10">The materials appearing on Connexdoor website could include technical, typographical, or photographic errors. Connexdoor does not warrant that any of the materials on its website are accurate, complete, or current. Connexdoor may make changes to the materials contained on its website at any time without notice. However, Connexdoor does not make any commitment to update the materials.</div>
        <div class="headline-02 clgy padtop20">5. Links</div>
        <div class="body-copy clgy padtop10">Connexdoor has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Connexdoor of the site. Use of any such linked website is at the user's own risk.</div>
        <div class="headline-02 clgy padtop20">6. Modifications</div>
        <div class="body-copy clgy padtop10">Connexdoor may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.
        </div>
        <div class="headline-02 clgy padtop20">7. Governing Law</div>
        <div class="body-copy clgy padtop10 padbtm50">These terms and conditions are governed by and construed in accordance with the laws of Maharashtra and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
        </div>
        <hr  />
        <div class="headline-s01 cldgr padtop50 padbtm20">Privacy Policy </div>
        <div class="body-copy clgy padtop10 padbtm50">
            <p>Your privacy is important to us. It is Connexdoor’s policy to respect your privacy regarding any information we may collect from you across our website, www.connexdoor.com, and other sites we own and operate.</p>
            <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
            </p>
            <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
            <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
            <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
            <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
            </p>
            <p>Your continued use of our website will be regarded as an acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
            </p>
            <p>This policy is effective as of 19 April 2020.</p>

        </div>

        <!-- <hr  />

        <div class="headline-s01 cldgr padtop50 padbtm20">Future Services:</div>
        <div class="future clgy padtop10 padbtm50">
            <ol>
                <li class="body-copy clgy padbtm5"><span>Startup Service as a Service (SSaS) -</span> An initiative to uplift the startup ecosystem, where startups can be both suppliers or customers, at different instances. 
                </li>
                <li class="body-copy clgy padbtm5"><span>Funding Services -</span> We’ll help Startups to connect with relevant Angel Investors and VCs, after considering various data points like Subject Matter Expertise or stage of funding. 
                </li>
                <li class="body-copy clgy padbtm5"><span>Third Party Services -</span> Access to legal, web development and many such genuine third party services. 
                </li>
                <li class="body-copy clgy padbtm5"><span>Networking -</span> Access to a group of professionals with similar interest, for mentorship and guidance.</li>
                <li class="body-copy clgy padbtm5"><span>Plug N’ Play -</span> Developing a layer of 80+ open APIs accessible by startups and corporates. Creating a possibility to build a full fledged bank in 48 hours!
                </li>
            </ol>
            
        </div> -->

    </div>
 </div>

</div>



</body>
</html>