<div id="notification" class="width100 pad15 bdrRadius4px">
    <div class="width100 padbtm10 flex flexAlignItemsCenter bdrSet">
        <div class="notificationTitle  flex2 flex flexAlignItemsCenter">
        {{'notifications' | translate}} <span class="bubble">{{getNotificationCount()}}</span>

        </div>
        <div class="ripple cursorPtr pad5 bdrRadius50p" (click)="closeNotification()">
            <i class="icns icon-close d15"></i>
        </div>
    </div>
    <div *ngFor="let item of data">
        <app-notification-with-action [notification]='item' *ngIf="item.status == 'New'"></app-notification-with-action>
    </div>
    <div *ngIf="getNotificationCount() == 0" class="noDataAvailable flex flexDirectionCol flexAlignItemsCenter flexJustifyCenter">{{'no notifications available' | translate}}</div>

</div>