import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map,catchError } from 'rxjs/operators';
import { Observable, forkJoin, Subscription, throwError, Subject } from 'rxjs';
import { AppStore } from 'src/app/core/stores/app.store';
import { config } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupModel } from '../models/popup.model';
import { Location } from '@angular/common';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ToastModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public idleScreenFlag: boolean = false;
  public subscription: Subscription;
  public toastTimer: any;
  public logger = new Subject<boolean>();

  constructor(
    private location: Location,
    public http: HttpClient,
    public appStore: AppStore,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private idle: Idle
  ) { }

  /* api method for creating leads*/
  public getNotifications() {
    return this.http.get(`${config.apiURL}${config.notificationURI}/${config.myNotificationURI}.json`)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for creating leads*/
  public postNotifications(url?: string) {
    console.log(url)
    return this.http.post(`${config.apiURL}${url}`, {})
      .pipe(map(response => this.appStore.apiResp = response));
  }


  /* get Linked in User */
  getLoggedLinkedinUser(data) {
    return this.http.post(`${config.apiURL}${config.createUserURI}/${config.getLinkedInUserURI}.json`, data)
    .pipe(map((response) => this.appStore.apiResp = response ));
  }

  /* api method for creating leads*/
  public createLead(data: object) {
    return this.http.post(config.apiURL + config.createLeadURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for creating user profile*/
  public createUserProfile(data: object) {
    return this.http.post(config.apiURL + config.createUserProfileURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for resend otp profile*/
  public resendOtpProfile(data: object) {
    return this.http.post(config.apiURL + config.resendOtpProfileURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for resend otp profile*/
  public verifyOtpProfile(data: object) {
    return this.http.post(config.apiURL + config.verifyOtpProfileURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for forgot password*/
  public forgotPassword(data: object) {
    return this.http.post(config.apiURL + config.forgotPasswordURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for forgot password verify otp*/
  public verifyOtpForgetPassword(data: object) {
    return this.http.post(config.apiURL + config.forgotPasswordVerifyOtpURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for forgot reset password*/
  public resetPassword(data: object) {
    return this.http.post(config.apiURL + config.resetPasswordURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  public checkLogIn(): Observable<boolean> {
    return this.logger.asObservable();
  }

  /* api method for sign in*/
  public signIn(data: object) {
    return this.http.post(config.apiURL + config.signInURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response))
      .pipe(catchError((errorRes) => {
        let errorMessage = "an unknown error occured";
        if(!errorRes.error || !errorRes.error.error ){
          return throwError(errorMessage);
        }
        errorMessage = errorRes.error.error;
        return throwError(errorMessage);
      }))
  }
  /* api method for resend otp profile*/
  public createUser(data: object) {
    return this.http.post(config.apiURL + config.createUserURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for resend otp profile*/
  public userPermission() {
    return this.http.get(config.apiURL + config.userPermissionURI + ".json")
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for attachment create*/
  public attachmentCreate(data?: object) {
    return this.http.post(config.apiURL + config.attachmentURI + ".json", data)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* api method for deleting attachment*/
  public deleteAttachment(id: number) {
    return this.http.delete(`${config.apiURL}${config.attachmentURI}/${id}.json`)
      .pipe(map(response => this.appStore.apiResp = response));
  };

  // api update social information
  // public updateSocialinformation(data: object) {
  //   return this.http.patch(config.apiURL + 'user_profiles/53', data)
  //     .pipe(map(response => this.appStore.apiResp = response));
  // }

  /* To check if the user is logged in by getting jwt from localStorage */
  public isLoggedIn() {
    return localStorage.getItem("user") ? true : false;
  }

  // get recentjobs
  public getRecentJobs(data?: any) {
    return this.http.get(`${config.apiURL}${config.getRecentJobsURI}.json`)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  /* get partners api method */
  public getpartners(data?: any) {
    return this.http.get(`${config.apiURL}${config.companyURI}/${config.getpartnersUri}.json`)
      .pipe(map(response => this.appStore.apiResp = response));
  }

  public updateSocialSignIn(data ?: any) {
    return this.http.post(`${config.apiURL}${config.createUserURI}/${config.socialSignInURI}`, data)
      .pipe(map(response => this.appStore.apiResp = response));
  }


  /* Response handler function for handling all http response actions */
  public responseHandler(response) {
    if (response.body) {
      console.log("------------------------im in---------------------")
    }
  }

  /* Logout Service */
  public logoutSession() {
    localStorage.removeItem("user");
    this.appStore.userPermissions = undefined;
    this.router.navigate(['home'])
    this.logger.next(false);
  }

  /* check user information present or not */
  public isUserInfo() {
    this.appStore.userPermissions ? true : false;
  }

  /* user profile info present or not */
  public userProfileInfo() {
    this.appStore.userPermissions ? true : false;
  }


  /* Function to trigger the Popup */
  public togglePopup(popup: PopupModel) {
    this.appStore.popup.type = (((typeof popup.type !== 'undefined')) ? popup.type : this.appStore.popup.type);
    this.appStore.popup.context = ((typeof popup.context !== 'undefined') ? popup.context : this.appStore.popup.context);
    this.appStore.popup.load = ((typeof popup.load !== 'undefined') ? popup.load : this.appStore.popup.load);
    this.appStore.popup.refreshParent = popup.refreshParent ? popup.refreshParent : false;
    this.appStore.popup.overlayClose = ((typeof popup.overlayClose !== 'undefined') ? popup.overlayClose : this.appStore.popup.overlayClose);
    setTimeout(() => {
      document.getElementById('popupContainer').classList.add('active');
      document.body.classList.add('overflowHidden');
    }, 100);
  }

  /* Function to Trigger the Toast */
  public toggleToast(toast?: ToastModel) {
    clearTimeout(this.toastTimer);
    this.appStore.toast.type = (toast.type != null) ? toast.type.split(' ').join('') : this.appStore.toast.type.split(' ').join('');
    this.appStore.toast.context = (toast.context != null) ? toast.context : this.appStore.toast.context;
    this.appStore.toast.load = (toast.load != null) ? toast.load : this.appStore.toast.load;
    this.appStore.toast.position = (toast.type != null) ? toast.position : this.appStore.toast.position;

    this.toastTimer = setTimeout(() => {
      if (this.appStore.toast.load) { this.appStore.toast.load = false; }
    }, this.appStore.toast.pause * 1000);
  }
  

  /* Navigate to login if jwt doesnt exisit */
  public checkSession() {
    /* Check jwt is present or not */
  };

  /* Back Previous Method */
  backToPreviousPage() {
    this.location.back();
  }

  /* Function to Identifies and trigger the Popup */
  public errorIdentifier(errorObj) {
    let popupObj: PopupModel = {
      type: 'error',
      load: true,
      overlayClose: true,
      context: errorObj /* Passing the entire error object to the popup component */
    }
    this.togglePopup(popupObj);
  }

  /* Function will trigger when device is offline */
  public deviceNetworkStatus(type: string) {
    if (type == 'offline') {
      /* The Device is Offline */
      console.log("------------------im offline----------------")
      let popupObj: PopupModel = {
        type: 'noInternet',
        load: true,
        context: {
          title: 'NO INERNET',
          description: 'Not Connected to Network'
        },
        overlayClose: false,
      }
      this.togglePopup(popupObj);

    } else if (type == 'online') {
      /* The Device is Offline */
      console.log("------------------on line----------------")
      this.appStore.popup.load = false;
      document.body.classList.remove('overflowHidden');
    }
  }


  /* Function block to identify the screen inactivity */
  public sessionKeepAliveHandler() {
    // if (this.idleScreenFlag == false) {
    //   this.idleScreenFlag = true;
    //   this.idle.setIdle(config.idleSessionTimedOut);
    //   this.idle.setTimeout(config.idleSessionWarning);
    //   this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    //   /* Show popup when Idle time starts */
    //   this.idle.onIdleStart.subscribe(() => {
    //     this.togglePopup({
    //       type: 'inactivity',
    //       load: true,
    //       overlayClose: true,
    //       context: 'You\'ve gone idle!'
    //     });
    //   });

    //   /* Setting countdown number in the idle time popup  */
    //   this.idle.onTimeoutWarning.subscribe((countdown) => {
    //     this.appStore.idleCountdown = countdown;
    //   });

    //   /* Reset timer to initial value when a user does some action */
    //   this.idle.onIdleEnd.subscribe(() => {
    //     this.appStore.popup.load = false;
    //     this.resetIdleScreen();
    //   });

    //   /* Close idle time popup & remove jwt form localstorage then redirect to idle time logged out screen */
    //   this.idle.onTimeout.subscribe(() => {
    //     this.appStore.popup.load = false;
    //     localStorage.removeItem('user');
    //     this.router.navigate(['/']); /* student/error/inactivity */
    //   });

    //   /* On 1st time load of a page reset idle time countdown */
    //   this.resetIdleScreen();
    // }
  }

  public resetIdleScreen() {
    this.idle.watch();
  }

  public connection(data){
    return this.http.post(`${config.apiURL}${config.connectionURI}.json`, data)
    .pipe(map(response => this.appStore.apiResp = response));
  }
  public checkProfileCompletion(){
    let minExp = 35;
    if(this.appStore.userPermissions && this.appStore.userPermissions.user &&  this.appStore.userPermissions.user.user_profile && this.appStore.userPermissions.user.user_profile.total_experience_years == '0'){
       minExp = 15;
     }
     if(this.appStore.userPermissions && this.appStore.userPermissions.user &&  this.appStore.userPermissions.user.user_profile && this.appStore.userPermissions.user.user_profile.profile_completeness <= minExp ){
       this.toggleToast(
         {
           type: 'success',
           load: true,
           position: 'bottom-center',
           context: { description: `Please fill the Basic Informations to continue` }
         }
       );
       this.router.navigate(['/user-profile/edit'])
     }
  }
  public getConnentions(data?: any){
      return this.http.get(`${config.apiURL}${config.connectionURI}.json`, { params: data })
      .pipe(map(response => this.appStore.apiResp = response));
  }

  public getUserConnentions(data?: any){
    return this.http.get(`${config.apiURL}${config.connectionURI}/${config.myConnectionURI}.json`, { params: data })
    .pipe(map(response => this.appStore.apiResp = response));
}

}
