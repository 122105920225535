import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppService } from '../../core/services/app.service';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { JobsStore } from './jobs.store';
import { map } from 'rxjs/operators';
import { config } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(
    public http: HttpClient,
    public appService: AppService,
    public jobStore: JobsStore
  ) { }
  /* fork join api for Job list */
  getJobListDropdowns() {
    return forkJoin([
      this.http.get(`${config.apiURL}${config.locationURI}.json`),
      // this.http.get(`${config.apiURL}${config.companyURI}.json`),
      this.http.get(`${config.apiURL}${config.skillURI}.json`)
      // this.http.get(`${config.apiURL}${config.jobTitleURI}.json`),
    ]).pipe(map(
      response => this.jobStore.apiResp = response
    ));
  }
  //get all the available jobs.
  getAllJobListings(data) {
    return this.http.get(`${config.apiURL}${config.jobPostingURI}.json`, { params: data })
      .pipe(map(response => this.jobStore.apiResp = response));
  }

  getJobPostingDetail(id) {
    return this.http.get(`${config.apiURL}${config.jobPostingURI}/${id}.json`)
      .pipe(map(response => this.jobStore.apiResp = response));
  }
  /* api for get location list */
  getLocationList(data?: any) {
    return this.http.get(`${config.apiURL}${config.locationURI}.json`, { params: data })
      .pipe(map(
        response => this.jobStore.apiResp = response
      ));
  }
  getSkillsList(data?: any){
    return this.http.get(`${config.apiURL}${config.skillURI}.json`, { params: data })
    .pipe(map(
      response => this.jobStore.apiResp = response
    ));
  }
  getPicks() {
    return this.http.get(`${config.apiURL}${config.picksURI}.json`)
      .pipe(map(response => this.jobStore.apiResp = response));
  }



  /* api for getting data after clicking the filters */
  getFilteredData(data?: any) {
    let params = new HttpParams();
    for (let id of Object.keys(data)) {
      if (id == 'employment_type') {
        for (const item of data[id]) {
          params = params.append('employment_type[]', item);
        }
      } else {
        params = params.append(`${id}`, data[id]);
      }

    }
    return this.http.get(`${config.apiURL}${config.jobPostingURI}.json`, { params: params })
      .pipe(map(
        response => this.jobStore.apiResp = response
      ));
  }

  jobApplicationFormSend(data: object) {
    return this.http.post(`${config.apiURL}${config.jobApplicationsURI}.json`, data)
      .pipe(map(response => this.jobStore.apiResp = response));
  }
  
  bookmarkJob(data: object) {
    return this.http.post(`${config.apiURL}${config.bookmarkURI}.json`, data)
      .pipe(map(response => this.jobStore.apiResp = response));
  }
  removebookmarkJob(data: object) {
    return this.http.post(`${config.apiURL}${config.removeBookmarkURI}.json`, data)
      .pipe(map(response => this.jobStore.apiResp = response));
  }
  getRecruiterMsg(){
    return this.http.get(`${config.apiURL}${config.jobApplicationsURI}/${config.recruiterMessageURI}.json`)
    .pipe(map(
      response => this.jobStore.apiResp = response
    ));
  }  
  

}
