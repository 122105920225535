import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { finalize, tap, catchError } from "rxjs/operators";
import { Router } from '@angular/router';

import { AppService } from '../services/app.service';
import { LoaderService } from '../services/loader.service';
import { config } from 'src/environments/environment';

@Injectable()
export class CDInterceptor implements HttpInterceptor {
    constructor(
        private appService: AppService,
        private loader: LoaderService,
        private router: Router,
    ) { }

    /* Error handler function from `catchError()` */
    private handleError(err: HttpErrorResponse){
        this.httpStatusHandler(err)
        const error = err.error.message || err.statusText;
    }

    private httpStatusHandler(response){

        switch (response.status) {
            case 200:
                console.log("nothing to do");
                break;
            case 302:
                /* 302 Redirect to a location */
                console.log("nothing to do");
                break;
            case 401:
                console.log("nothing to do");
                this.appService.logoutSession();
                break;
            case 403:
                this.appService.togglePopup({
                    type: 'error',
                    load: true,
                    context: {
                        status: 'error',
                        title: response.error.status,
                        description:  response.error.message
                    },
                    overlayClose: false,
                });
                break;    
            case 404:
                break;
            case 422:
                let data = '';
                for (const item in response.error.errors) {
                    data = `${item}: ${response.error.errors[item].toString()}`
                }
                this.appService.togglePopup({
                    type: 'error',
                    load: true,
                    context: {
                        status: 'error',
                        title: response.statusText || 'Failure',
                        description: data || 'Please try again later'
                    },
                    overlayClose: false,
                });
                break;               
            case 426:
                this.appService.togglePopup({
                    type: 'error',
                    load: true,
                    context: {
                        status: 'error',
                        title: response.error.status,
                        description:  response.error.reason
                    },
                    overlayClose: false,
                });
                break;  
            case 999:
                console.log("====================invalid request====================")
                break;      
            case 500:
                /* 500 Internal API Server Error */
                var key = Object.keys(response.error)
                var val = Object.values(response.error)
                this.appService.togglePopup({
                    type: 'error',
                    load: true,
                    context: {
                        status: response.status,
                        title: response.statusText,
                        description: key[0] + ' ' + val[0]
                    },
                    overlayClose: false,
                  });
                  console.log(key[0] + ' ' + response.error.key[0]);
                  
                break;
            default:
                this.appService.errorIdentifier(response);
                break;
        }

        return response;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /* Checking the Application is online or not */
        if(navigator.onLine){
            /* Enabling the loader if it's not a myNotificationURI API  */
            if (!req.url.includes(config.myNotificationURI) && !req.urlWithParams.includes('search_for')) {
                this.loader.show();
            }
            
            /* Setting the jwt token to the header if the local storage has the jwt token */
            /* For the Config API doesn't need any header, so we checking weather the req has the Config API endpoint or not*/
            if (this.appService.isLoggedIn()) {
                req = req.clone({
                    setParams: JSON.parse(localStorage.getItem('user'))
                });
            }
            
            return next
                .handle(req)
                .pipe(tap(evt => {
                    if (evt instanceof HttpResponse) {
                        if (!this.appService.isLoggedIn()) {
                            this.setSession(evt.body);
                        } 
                        // else {
                        //     this.appService.sessionKeepAliveHandler();
                        // }
                        
                        // this.httpStatusHandler(evt);
                    }
                }, (error: HttpErrorResponse) => {
                    this.handleError(error)
                })).pipe(finalize(() => {
                    setTimeout(() => {
                        this.loader.hide();
                    }, 100);
                }));
        }
    }

    /* Setting the user session */
    private setSession(body: any) {
        /* If API response body has auth token  */
        if ((body.user && body.user.authentication_token)) {
            localStorage.setItem("user", JSON.stringify({"user_token": body.user.authentication_token, "username": body.user.username}));
            this.appService.logger.next(true);
        } else if (body.authentication_token) {
            localStorage.setItem("user", JSON.stringify({"user_token": body.authentication_token, "username": body.username}));
            this.appService.logger.next(true);
        };

        /* This function will listen if the screen is idle or not if it'll idle for some time then logged out the user */
       
    }
}
