import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Routing Components */
import { HomeComponent } from './connexdoor/components/home/home.component';
import { PageNotFoundComponent } from './connexdoor/components/page-not-found/page-not-found.component';
import { MagicProfileComponent } from './connexdoor/components/magic-profile/magic-profile.component';
import { LinkedinloginComponent } from './shared/components/linkedinlogin/linkedinlogin.component';
import { JobsAppliedComponent } from './connexdoor/dashboard/jobsapplied/components/jobs-applied/jobs-applied.component';
import { IsloggedinGaurd } from './core/services/authguard/isloggedin-guard.service';
import { AboutusComponent } from './connexdoor/components/aboutus/aboutus.component';
import { ContactusComponent } from './connexdoor/components/contactus/contactus.component';
import { PrivacypolicyComponent } from './connexdoor/components/privacypolicy/privacypolicy.component';
import { ConfirmAccountComponent } from './connexdoor/components/confirm-account/confirm-account.component';
import { FintecasticWomenComponent } from './connexdoor/components/fintecastic-women/fintecastic-women.component';
import { FintechCafeComponent } from './connexdoor/components/fintech-cafe/fintech-cafe.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: 'home',
    pathMatch: 'full'
  }, {
    path: "home",
    component: HomeComponent,
    pathMatch: 'full'
  },{
    path: "fintecastic-women",
    component: FintecasticWomenComponent,
    pathMatch: 'full'
  },{
    path: "fintech-cafe",
    component: FintechCafeComponent,
    pathMatch: 'full'
  },{
    path: "home?:type",
    component: HomeComponent,
  }, {
    path: "magic-profile",
    component: MagicProfileComponent,
    canActivate: [ IsloggedinGaurd ]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./connexdoor/profile/user-profile/user-profile.module').then(m => m.UserProfileModule),
    canActivate: [ IsloggedinGaurd ]
  }, {
    path: 'startup',
    loadChildren: () => import('./connexdoor/profile/startup-profile/startup-profile.module').then(m => m.StartupProfileModule),
    canActivate: [ IsloggedinGaurd ]
  },{
    path: 'jobs',
    loadChildren: () => import('./connexdoor/jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [ IsloggedinGaurd ]
  }, {
    path: 'dashboard',
    loadChildren: () => import('./connexdoor/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [ IsloggedinGaurd ]
  },{
    path: "jobs-applied",
    component: JobsAppliedComponent,
    canActivate: [ IsloggedinGaurd ]
  },{
    path: 'linkedinlogin',
    component:LinkedinloginComponent
  },{
    path :'about-us',
    component: AboutusComponent
  },{
    path :'contact-us',
    component: ContactusComponent
  },{
    path :'privacy-policy',
    component: PrivacypolicyComponent    
  },{
    path :'confirm-account',
    component: ConfirmAccountComponent  
  },{
    path :':username/confirm-account',
    component: ConfirmAccountComponent  
  },{
    path: 'investors',
    loadChildren: () => import('./connexdoor/profile/vc-profile/vc-profile.module').then(m => m.VcProfileModule),
    canActivate: [ IsloggedinGaurd ]
  },
  {
    path: 'corporates',
    loadChildren: () => import('./connexdoor/profile/corporate-profile/corporate-profile.module').then(m => m.CorporateProfileModule),
    canActivate: [ IsloggedinGaurd ]
  },
  {
    path: 'people',
    loadChildren: () => import('./connexdoor/people/people.module').then(m => m.PeopleModule),
    canActivate: [ IsloggedinGaurd ]
  },
  {
    path: 'finsip',
    loadChildren: () => import('./connexdoor/finship/finship.module').then(m => m.FinshipModule)
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  }, {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled'})],
exports: [RouterModule]
})
export class AppRoutingModule { }
