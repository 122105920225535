import { CompanyDetailModel } from './company.model';
export class JobModel {
    id ?: number = 0;
    user_profile_id ?:number = 0;
    company_id ?: number = 0;
    job_title_id ?: number = 0;
    experience ?: number = 0;
    start_date ?: string = '';
    end_date ?:  string = '';
    created_at ?: string = '';
    updated_at ?:  string = '';
    experience_months ?: number = 0;
    job_titles ?: any;
    company ?: CompanyDetailModel = new CompanyDetailModel();
}


export class JobTitleModel {
    id ?: number = 0;   
    title ?: string = '';
    created_at ?: string = '';
    updated_at ?:  string = '';
}