import { LocationModel } from './location.model';
import { JobTitleModel } from './job.model';
import { CompanyProfileModel, CompanyDetailModel } from './company.model';
import { ListingModel } from './other.model';

export class JobProfileModel {
    id ?: number = 0;
    user_profile_id ?: number = 0;
    company_id ?: number = 0;
    job_titles ?: ListingModel[] = [];
    experience ?: any = 0;
    experience_years ?: number = 0;
    start_date ?: string = "";
    end_date ?: string = "";
    created_at ?: string = "";
    updated_at ?: string = "";
    experience_months ?: number = 0;
    currently_working ?: boolean = false;
    company ?: CompanyDetailModel = new CompanyDetailModel();
}

export class JobPostingDetailModel {
    application_status ?: string = '';
    job_posting ?: JobDescriptionModel = new JobDescriptionModel();
    is_book_marked ?:boolean;
    
}

export class JobDescriptionModel{
    id ?: number = 0;
    title ?: string = '';
    employment_type ?: string = '';
    remote_work ?: string = '';
    deadline ?: string = '';
    description ?: string = '';
    job_title_id ?: number;
    role_description ?: string = '';
    no_of_positions ?: string = '';
    max_experience ?: any;
    min_experience ?: any;
    salary ?: string = '';
    skills ?: string = '';
    equity ?: string = '';
    job_url ?: string = '';
    posted_on ?:string = '';
    created_at ?: string = '';
    updated_at ?: string = '';
    status ?: string = '';
    company_profile = new CompanyProfileModel();
    job_title:JobTitleModel = new JobTitleModel();
    location:LocationModel = new LocationModel();
}