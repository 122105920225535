import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, ViewEncapsulation } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { AppService } from '../../../../core/services/app.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AppStore } from '../../../../core/stores/app.store';
import { isValidEmail, isValidMobile } from '../../../../core/essentials/common.libs';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { config } from 'src/environments/environment';
@Component({
	selector: 'app-signup-popup',
	templateUrl: './signup-popup.component.html',
	styleUrls: ['./signup-popup.component.scss'],
	encapsulation: ViewEncapsulation.None,

})
export class SignupPopupComponent implements OnInit, OnDestroy {
	@Input('popup') popup: PopupModel = new PopupModel();
	@Output('close') close = new EventEmitter();
	subscription: Subscription = new Subscription();
	interval: any;
	timer: number = 30;
	passwordType: string = "password";
	signUpForm: any;
	isOtpShow: boolean = false;
	isInvalidInput: boolean = false;
	resendOtpCount: number = 0;
	isInvalidOtp: boolean = false;
	otpLoader: boolean = false;
	otpMessageString = "an otp is sent to the number or email"
	constructor(
		private appService: AppService,
		private formBuilder: FormBuilder,
		public appStore: AppStore,
		private router: Router,
		private authService: SocialAuthService
	) {
		this.signUpForm = formBuilder.group({
			"user_profile": formBuilder.group({
				"first_name": ['', Validators.required],
				"last_name": ['', Validators.required],
				"email_or_phone_no": ['', Validators.required],
				"email": [''],
				"phone_number": [''],
				"user_type": [appStore.popup.context.job_profile_type, [Validators.required]]
			})
		});
	}

	/* form controls returns */
	f(controlName?: any) {
		return this.signUpForm.get('user_profile').get(controlName)
	}

	/* Close Popup Method */
	closePopup() {
		this.close.emit('icon');
	}

	/* toggle password eye method */
	toggleEye() {
		if (this.passwordType == 'password') {
			this.passwordType = "text";
		} else {
			this.passwordType = 'password';
		}
	}

	/* Sign In CTA Method */
	signIn() {
		this.appService.togglePopup({
			type: 'signIn',
			load: true,
			refreshParent: false,
			overlayClose: true,
			context: {}
		});
	}

	/* OTP Timer Expiry Method*/
	startCountdown(seconds = 30) {
		let counter: any = 30;
		this.interval = setInterval(() => {
			counter--;
			this.timer = counter;
			if (counter == 0) {
				clearInterval(this.interval);
			};
		}, 1000);
	}

	/* submit form method */
	submitForm() {
		if (!this.isOtpShow) {
			this.subscription.add(
				this.appService.createUserProfile(this.signUpForm.value).subscribe(response => {
					this.appStore.signUpPopupCreateOtpMethod();
					this.signUpForm.controls["user_profile"].addControl('otp', new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]))
					this.signUpForm.controls["user_profile"].addControl('password', new FormControl('', [Validators.required]))
					this.startCountdown(30)
					this.isOtpShow = true;
				})
			);
		} else {
			const params = {
				"user": {
					"username": this.signUpForm.value.user_profile.email_or_phone_no,
					"password": this.signUpForm.value.user_profile.password,
					"user_profile_id": this.appStore.userProfile.id
				}
			}
			this.subscription.add(
				this.appService.createUser(params).subscribe(response => {
					this.appStore.signUpUserInformationMethod();
					this.appService.toggleToast({
						type: 'success',
						load: true,
						position: 'bottom-center',
						context: { description: `Hi,welcome ${this.appStore.userInformation.username}` }
					});
					this.router.navigate(["/magic-profile"]);
					this.closePopup();
				})
			)
		}
	}

	verifyOTP(event?: any) {
		console.log(1);
		this.isInvalidOtp = false;
		if (this.signUpForm.value["user_profile"]["otp"].length == 6) {
			console.log(2);
			this.subscription.add(
				this.appService.verifyOtpProfile(this.signUpForm.value).subscribe(response => {
					this.isInvalidOtp = false;
					this.otpMessageString = 'otp successfully verified';
				}, err => {
					this.isInvalidOtp = true;
				})
			)
		}
	}

	/* resend otp method */
	resendOtp() {
		let resendOTPparam = {
			user_profile: {}
		}
		if (this.signUpForm.value["user_profile"]["email"]) {
			resendOTPparam["user_profile"]["email"] = this.signUpForm.value["user_profile"]["email"]
		} else {
			resendOTPparam["user_profile"]["phone_number"] = this.signUpForm.value["user_profile"]["phone_number"]
		}
		this.subscription.add(
			this.appService.resendOtpProfile(resendOTPparam).subscribe(response => {
				this.otpMessageString = 'otp successfully resent';
			})
		)

	}


	/* check valid mobile no or email  */
	checkIsValidMobileOrEmail(event?: any) {
		this.isInvalidInput = true;
		let value = this.signUpForm.value.user_profile.email_or_phone_no;
		this.signUpForm.patchValue({
			"user_profile": {
				"email": "",
				"phone_number": ""
			}
		})
		if (value.includes("@")) {
			if (isValidEmail(value)) {
				this.signUpForm.patchValue({
					"user_profile": {
						"email": value,
						"phone_number": ""
					}
				});
				this.isInvalidInput = false;
			}
		} else {
			if (isValidMobile(value)) {
				this.signUpForm.patchValue({
					"user_profile": {
						"email": "",
						"phone_number": value
					}
				});
				this.isInvalidInput = false;
			}
		}
	}

	/* Social Sign IN Method */
	socialSignIn(type: string) {
		switch (type) {
			case 'facebook':
				this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
				break;
			case 'google':
				this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
				break;
			case 'linkedin':
				this.signInWithLinkedin();
				break;	
			default:
				break;
		}
	}

	cancel(){
		this.isOtpShow = !this.isOtpShow;
		this.signUpForm.controls["user_profile"].removeControl('otp');
		this.signUpForm.controls["user_profile"].removeControl('password');
		this.otpMessageString = "an otp is sent to the number or email";
		clearInterval(this.interval);
	}


	ngOnInit() {
		this.authService.initState.subscribe(() => {}, console.error, () => {
		  this.authService.authState.subscribe((user) => {
			this.loginUser(user)
		  });   
		});
	  }
	
	  /* loginUser api */
	  loginUser(user: any) {
		const param = {
		  "user": {
			"username": user.email,
			"provider": user.provider,
			"uid": user.id,
			"auth_token": user.authToken,
			"first_name": user.firstName,
			"last_name": user.lastName,
			"image_url": user.photoUrl,
			"email": user.email  
		  }
		}
		this.appService.updateSocialSignIn(param).subscribe((response: any) => {
			this.appStore.signUpUserInformationMethod();
			this.appService.toggleToast({
				type: 'success',
				load: true,
				position: 'bottom-center',
				context: { description: `Hi,welcome ${response.user.username}` }
			});
			this.router.navigate(["/home"]);
			this.close.emit('icon');
		});
	  }

	
	  signInWithLinkedin() {
		window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.socialCredentials.linkedinClientId}&redirect_uri=${config.linkedinRedirectURI}&state=fooobar&scope=r_liteprofile,r_emailaddress`;
	  }
	/* ng destroy life cycle */
	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.authService.signOut();
		}
			
	}

}
