import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AppService } from '../../../../core/services/app.service';
import { isValidEmail, isValidMobile } from '../../../../core/essentials/common.libs';
import { Subscription } from 'rxjs';
import { error } from '@angular/compiler/src/util';


@Component({
  selector: 'app-forgot-password-popup',
  templateUrl: './forgot-password-popup.component.html',
  styleUrls: ['./forgot-password-popup.component.scss']
})
export class ForgotPasswordPopupComponent implements OnInit, OnDestroy {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  currentStep: number = 1;
  forgotPasswordForm: any;
  subscription: Subscription = new Subscription();
  isInvalidInput: boolean = false;
  isOtpShow: boolean = false;
  isInvalidOtp: boolean = false;
  submittedOtp: boolean = false;
  errorMessage = null
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
  ) {

    this.forgotPasswordForm = formBuilder.group({
      "user": formBuilder.group({
        "username": ['', Validators.required],
      })
    });
  }

  /* form controls returns */
  f(controlName?: any) {
    return this.forgotPasswordForm.get('user').get(controlName)
  }



  checkIsValidMobileOrEmail(event?: any) {
    this.isInvalidInput = true;
    let value = this.forgotPasswordForm.value.user.username;
    if (value.includes("@")) {
      if (isValidEmail(value)) {
        this.isInvalidInput = false;
      }
    } else {
      if (isValidMobile(value)) {
        this.isInvalidInput = false;
      }
    }
  }

  mustMatchValid(event?: any) {
    let control = this.forgotPasswordForm.controls["user"].controls["password"]
    let matchingControl = this.forgotPasswordForm.controls["user"].controls["password_confirmation"]
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }

  }

  /* Close Popup Method */
  closePopup() {
    this.close.emit('icon');
  }


  submitForm() {
    if (!this.isOtpShow) {
      this.subscription.add(
        this.appService.forgotPassword(this.forgotPasswordForm.value).subscribe(response => {
          this.errorMessage = null
          this.isOtpShow = true;
          this.forgotPasswordForm.controls["user"].addControl('otp', new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]))
          this.forgotPasswordForm.controls["user"].addControl('password', new FormControl('', [Validators.required]))
          this.forgotPasswordForm.controls["user"].addControl('password_confirmation', new FormControl('', [Validators.required]))

          // this.startCountdown(30)  MustMatch('password', 'password_confirmation')
        },
        error => {
          console.log(error);
          
          this.errorMessage = error.error.message
        })
      );
    } else {
      let data = {
        "user": {
          "username": this.forgotPasswordForm.value.user.username,
          "password": this.forgotPasswordForm.value.user.password,
          "password_confirmation": this.forgotPasswordForm.value.user.password_confirmation,
        }
      }
      this.subscription.add(
        this.appService.resetPassword(data).subscribe(response => {
          this.errorMessage = null
          this.appService.toggleToast(
            {
              type: 'success',
              load: true,
              position: 'bottom-center',
              context: { description: `password reset successfully` }
            }
          );
          this.close.emit('icon');
        },
        error => {
          this.errorMessage = error.message
        })
      );

    }
  }


  verifyOTP(event?: any) {
    this.isInvalidOtp = false;
    if (this.forgotPasswordForm.value["user"]["otp"].length == 6) {
      this.subscription.add(
        this.appService.verifyOtpForgetPassword(this.forgotPasswordForm.value).subscribe(response => {
          this.isInvalidOtp = false;
          this.submittedOtp = true;
        }, err => {
          this.isInvalidOtp = true;
        })
      )


    }
  }


  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }




  ngOnInit() {
  }

}
