import { UserProfileModel } from '../models';

export const essentials = {
  title: "Umrah Customer",
  themes: [
    "default",
  ],
  //live
  socialCredentials: {
    googleWebApiKey: "528751631565-bcgosbp9t3ser4k988ri20mpfslc3efs.apps.googleusercontent.com",
    fbAppId: "392396485062988",
    linkedinClientId: "77zdd6z6h9rmrx",
    linkedinClientSecret: "0Eu2zmSmtWGLFzT6"
  },
  compatibleBrowsers: { CHROME: 70, FIREFOX: 55, EDGE: 13, IE: 10, SAFARI: 10 },
  idleSessionTimedOut: 60, /* 9 Mins */
  idleSessionWarning: 60, /* 1 Min */
  dataListLimit :20,/** for infinite loader */
  createLeadURI: "leads",
  partnerInviteURI:"partner_investors",
  getLinkedInUserURI: "verify_linkedin",
  createUserProfileURI: "user_profiles",
  resendOtpProfileURI: "user_profiles/resend_otp",
  verifyOtpProfileURI: "user_profiles/verify_otp",
  forgotPasswordURI: "users/forgot_password",
  forgotPasswordVerifyOtpURI: "users/verify_otp",
  resetPasswordURI: "users/reset_password",
  createUserURI: "users",
  signInURI: "users/sign_in",
  locationURI: "locations",
  companyURI: "companies",
  domainExpertiseURI: "domain_expertises",
  userPermissionURI: "users/user_permissions",
  jobTitleURI: "job_titles",
  universitiesURI: "universities",
  educationURI: "educations",
  experienceURI: "job_profiles",
  attachmentURI: "attachments",
  skillURI: "skills",
  mediaURI: "media_mentions",
  fundingsURI: "fundings",
  teamURI: "team",
  partnerURI: "partners",
  jobPostingURI: "job_postings",
  companyProfilesURI: "company_profiles",
  industryURI: "industries",
  segmentURI: "segments",
  investorsURI: "investors",
  investorProfileURI:"investor_profiles",
  companyProfileURI: "company_profiles",
  jobApplicationsURI: "job_applications",
  recruiterMessageURI :"recruiter_message",
  myJobsURI: "my_jobs",
  getRecentJobsURI: "job_postings/recent_jobs",
  picksURI: "job_postings/connexdoor_picks",
  getpartnersUri: "leading_fintechs",
  myAppliedJobs: "my_applied_jobs",
  companyAdminURI: "company_admins",
  myNotificationURI: "my_notifications",
  notificationURI: "notifications",
  viewProfileURI: "view_profile",
  uploadJobsURI: "upload_jobs",
  statusUpdateMultipleURI: "status_update_multiple",
  socialSignInURI: "social_signin",
  listURI: "list",
  ArticlesURI: "articles",
  articleCategories:"article_categories",
  connectionURI : "connections",
  myConnectionURI : "my_connections",
  corporateProblemStatementURI: "corporate_problem_statements",
  bookmarkURI:"bookmarks",
  removeBookmarkURI:"/bookmarks/unbookmark",
  feedbacksURI: "feedbacks"
}
