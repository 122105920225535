export class EducationModel {
    id ?: number = 0;
    user_profile_id ?:  number = 0;
    university_id ?:  number = 0;
    course ?: string = '';
    start_date ?: string = '';
    end_date ?:string = '';
    created_at ?: string = '';
    updated_at ?: string = '';
    description ?: string = '';
    university_name ?: string = ''; 
    university_logo ?: string = '';
}