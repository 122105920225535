<div id="inviteAdmin" class="padtop50 padbtm50 padleft50 padright50">
    <div class="mrgnauto">
        <div class="flex flexJustifyEnd padtop10 padright20">
            <div class="iconClose ripple cursorPtr" (click)="closePopup()">
                <i class="icns icon-close d15"></i>
            </div>
        </div>
        <div class="adminBox">
            <div class="flex circular-bold font24 flexAlignItems Center padbtm40 posClass">
                <img src="assets/images/invite_icon.svg" class="padright5" />
                <span class="COLOR38453F txtCenter" *ngIf="profileType == 'invitation'">Invite team</span>
                <span class="COLOR38453F txtCenter" *ngIf="profileType == 'partnerinvitation'">{{'invite partner'| translate}}</span>
            </div>
            <ng-container>
                <ng-container>
                    <form class="inviteAdminForm customForm width100" [formGroup]="inviteAdminForm">
                        <div class="flex flexResponsive customForm basicInfoForm padtop20">
                            <div class="width100 posRelative">
                                <label class="COLOR38453F">Name</label>
                                <input type="text" formControlName="name" placeholder="Type name" name="name" />
                            </div>
                        </div>
                        <div class="helpBlock" *ngIf="this.inviteAdminForm.invalid && submitted">
                            <span *ngIf="inviteAdminForm.get('name').errors?.required">Name is required</span>
                        </div>
                        <div class="flex flexResponsive customForm basicInfoForm padtop20">
                            <div class="width100">
                                <label class="COLOR38453F">Email</label>
                                <input type="text" placeholder="Type email id" formControlName="email" name="email" />
                            </div>
                        </div>
                        <div class="helpBlock" *ngIf="this.inviteAdminForm.invalid && submitted">
                            <span *ngIf="inviteAdminForm.get('email').errors?.required">Email is required</span>
                        </div>
                        <div class="flex flexJustifyEnd flexAlignItemsCenter padtop20">
                            <button class="btn btn-signup ripple circular-bold" type="button" [ngClass]="{'disabled': inviteAdminForm.invalid}" (click)="submitForm()">Send
                            Invite
                        </button>
                        </div>
                    </form>
                </ng-container>


            </ng-container>


        </div>
    </div>
</div>