import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppService } from './../../../../core/services/app.service';
import { AppStore } from './../../../../core/stores/app.store';
import { PopupModel } from 'src/app/core/models';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators,FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { JobsStore } from 'src/app/connexdoor/jobs/jobs.store';
import { JobsService } from './../../../../connexdoor/jobs/jobs.service';

@Component({
  selector: 'app-apply-job',
  templateUrl: './apply-job.component.html',
  styleUrls: ['./apply-job.component.scss']
})
export class ApplyJobComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  jobApplicationForm: FormGroup;
  jobPostingId :any;

  hasCV:boolean = false;
  hasCL:boolean = false;
  canUploadCV:boolean = false;
  canUploadCL:boolean = false;

  submitted:boolean= false;
  selectedFile: any = '';
  selectedCLFile: any = '';
  subscription: Subscription = new Subscription();
  constructor(
    private appService: AppService,
    private router:Router,
    public appStore: AppStore,
    private formBuilder: FormBuilder,
    public jobService:JobsService,
    public jobsStore:JobsStore,    
    ) { 
    this.jobApplicationForm = this.formBuilder.group({
      "job_posting_id": ['', Validators.required],
      "email": ['', Validators.required],
      "description": ['', Validators.required], 
      "cv_url":['',Validators.required] ,
      "skill_ids": ['',Validators.required], 
      "use_saved_cv":[''],
      "use_saved_cl":['']
  });
  }

  /** On form submit */
  OnSubmit(){
    this.submitted = true;
    if (this.jobApplicationForm.invalid) {
      return;
    }
    this.subscription.add( 
      this.jobService.jobApplicationFormSend(this.setParam()).subscribe(response => {
        this.jobsStore.getAppliedJob();
        this.openSuccessPopup();
      })
    )
  }
  getRecruiterMsg(){
    this.jobService.getRecruiterMsg().subscribe((response:any) => {
      this.jobApplicationForm.controls['description'].patchValue(response.message);
    })    
  }
  /** Search skills */
  searchItems(event ?: any) {
    let params = {
      "search_for": event.value
    };
    switch (event.formControlName) {
      case 'skill_ids':
        this.jobService.getSkillsList(params).subscribe(respnse => {
          this.jobsStore.getSkillsList();
          this.jobsStore.createProfileFormData["skills"]["data"] = this.jobsStore.skillList;
        });
        break;
      default:
        break;
    }
  }

//load dropdown values  
initialForkJoinRequest() {
  this.jobService.getJobListDropdowns().subscribe(response => {
    this.jobsStore.createJobListPage();
    this.jobsStore.createProfileFormData["skills"] = {
        "data": this.jobsStore.skillList,
        "label": 'what are you good at?',
        "placeholder": 'Add skills(Please search and choose options from the dropdown)',
        "customClass": '',
        "uiSearch": false,
        "formControlName": 'skill_ids'
      };
    });
  }


  /** When uploaded a file */
  uploadCvOrCl(event ?: any,val?:string) {
    event.stopPropagation();
    switch(val){
      case 'cv':
        this.selectedFile = event.target.files[0];
        this.jobApplicationForm.controls['cv_url'].patchValue("uploaded");
        break;
      case 'cl':
        this.selectedCLFile = event.target.files[0];
        break;  
    }
  }

  /** delete file */
  unSelect(val?:any){
    switch(val){
      case 'cv':
        this.selectedFile = '';
        this.jobApplicationForm.controls['cv_url'].reset();
        break;
      case 'cl':
        this.selectedCLFile = '';
        break;  
    }   
  }
  //setting form params
  setParam() {
    let formData = new FormData();
    let param = this.jobApplicationForm.value;
    for (const key in param) {
      if(key == 'skill_ids'){
        for (const item of param[key]) {
        formData.append(`job_application[${key}][]`,item )
        }
      }else{
        formData.append(`job_application[${key}]`, param[key]);
      }
    }
    if (this.selectedFile) {
      formData.append("job_application[attachments_attributes][][file]", this.selectedFile)
      formData.append("job_application[attachments_attributes][][attachable_ref]", 'CV')
     }
    if(this.selectedCLFile) {
      formData.append("job_application[attachments_attributes][][file]", this.selectedCLFile)
      formData.append("job_application[attachments_attributes][][attachable_ref]", 'CoverLetter') 
    }  
    return formData;
  }

  /** Open success popup */
  openSuccessPopup(){
    this.appService.togglePopup({
      type: 'apply-job-success',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {}
    });
  }

  /** Open Apply Job popup */
  openPopup(){
    this.appService.togglePopup({
      type: 'apply-job',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {}
    });
  }

  /** Close the popup */
  closePopup() {
   this.close.emit('icon');
  }
  onCheckboxSelect(event,val?:any){
    switch(val){
      case 'cv':
        this.canUploadCV = !this.canUploadCV;
        if(this.canUploadCV){
          //set validation for upload
          this.jobApplicationForm.controls['cv_url'].reset();
        }else{
          this.jobApplicationForm.controls['cv_url'].patchValue(this.appStore.userPermissions.user.user_profile.cv_url);
        }
        break;
      case 'cl':
        this.canUploadCL = !this.canUploadCL;
        break;
      default:
        break;    
    }

  }
  ngOnInit() {
    this.initialForkJoinRequest();
    this.getRecruiterMsg();
    this.jobPostingId = this.popup.context.id;
    this.jobApplicationForm.controls['job_posting_id'].setValue(this.jobPostingId);
    this.jobApplicationForm.controls['email'].patchValue(this.appStore.userPermissions.user.user_profile.email);
    this.jobApplicationForm.controls['cv_url'].patchValue(this.appStore.userPermissions.user.user_profile.cv_url);
    if(this.appStore.userPermissions.user.user_profile.cv_url){
      this.jobApplicationForm.controls['use_saved_cv'].patchValue('true');
      this.hasCV = true;
    }else{
      this.canUploadCV = true;
    }
    if(this.appStore.userPermissions.user.user_profile.cover_letter_url){
      this.jobApplicationForm.controls['use_saved_cl'].patchValue('true');
      this.hasCL = true; 
    }else{
      this.canUploadCL = true;
    }
  }  

}
