import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { PopupModel } from 'src/app/core/models';
import { Subscription } from 'rxjs';
import { AppStore } from 'src/app/core/stores/app.store';
import { FormGroup,Validators, FormBuilder } from '@angular/forms';
import { MyStartupsService } from 'src/app/connexdoor/dashboard/mystartups/services/mystartups.service';

@Component({
  selector: 'app-inviteadmin',
  templateUrl: './inviteadmin.component.html',
  styleUrls: ['./inviteadmin.component.scss']
})
export class InviteadminComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  subscription: Subscription = new Subscription();
  jsonData:any;
  submitted:boolean = false;
  inviteAdminForm : FormGroup;
  profileType:string = 'invitation';
  @Output('close') close = new EventEmitter();
  constructor(
    private appService: AppService,
    public appStore: AppStore,
    private formBuilder: FormBuilder,
    private myStartupsService: MyStartupsService,
  ) {
    this.inviteAdminForm = this.formBuilder.group({
      "name": ['', Validators.required],
      "email": ['', Validators.required],
      "profile_type": [''],  
      "invited_by_id": [], 
  });

  }

  /* form params set */
  setFormData() {
    let data = {
      "lead": {
        "name": this.inviteAdminForm.value.name,
        "email": this.inviteAdminForm.value.email,
        "profile_type": this.profileType,
        "invited_by_id":   this.appStore.userPermissions.user.user_profile.id
      }
    }
    return data;
  }
  /** Submit form  */
  submitForm() {  
    this.submitted = true;
    this.subscription.add(
      this.myStartupsService.sendAdminInvite(this.setFormData()).subscribe(response => {
      this.closePopup()
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-center',
          context: { description: `Invitation sent successfully` }
        });
      })
    );
  }  

  ngOnInit(): void {  
  }

  /** Close the popup */
  closePopup() {
    this.close.emit('icon');
   }  

}
