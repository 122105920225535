import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-rich-text-editor",
  templateUrl: "./rich-text-editor.component.html",
  styleUrls: ["./rich-text-editor.component.scss"],
})
export class RichTextEditorComponent implements OnInit, OnChanges {
  @ViewChild("rte") richTextField: ElementRef;
  count = 0;
  @Input() rteContent: string = "";
  @Output() public refreshRTEContent = new EventEmitter();
  isInEditMode: boolean = false;
  showingSourceCode: boolean = false;
  fontName: string = "Arial";
  fontSize: string = "1";
  constructor() {}

  /* Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here. */
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes.rteContent.currentValue)
    setTimeout((x) => {
      if (
        changes.rteContent.currentValue &&
        this.richTextField &&
        this.count == 0
      ) {
        this.richTextField.nativeElement.innerHTML = this.rteContent;
        this.count = this.count + 1;
      }
    }, 1000);
  }

  public execCmd(command?: any, event?: any) {
    this.richTextField.nativeElement.focus();
    switch (command) {
      case "hyphen":
        document.execCommand("insertText", false, "-");
        break;
      case "createLink":
        let link = prompt("Enter a URL", "http://");
        if (link) {
          document.execCommand(command, false, link);
        }
        break;
      case "insertImage":
        let file = event.target.files[0];
        var reader = new FileReader();

        let dataURI;
        let img = document.createElement("img");
        reader.addEventListener(
          "load",
          function () {
            dataURI = reader.result;
            img.src = dataURI;
          },
          false
        );

        this.richTextField.nativeElement.appendChild(img);
        if (file) {
          reader.readAsDataURL(file);
        }
        break;
      default:
        let fieldID = document.getElementById(command) as any;
        if (fieldID) {
          document.execCommand(command, false, fieldID.value);
        } else {
          document.execCommand(command, false, null);
        }
        break;
    }
    this.richTextField.nativeElement.focus();
  }

  updateRTEContent() {
    setTimeout((x) => {
      this.rteContent = this.richTextField.nativeElement.innerText;
      this.refreshRTEContent.emit({
        dataType: "update-html",
        data: this.rteContent,
      });
    }, 100);
  }

  public toggleEdit() {
    if (this.isInEditMode) {
      this.richTextField.nativeElement.contentEditable = "false";
      this.isInEditMode = false;
    } else {
      this.richTextField.nativeElement.contentEditable = "true";
      this.isInEditMode = true;
    }
  }

  public toggleSource() {
    if (this.showingSourceCode) {
      this.richTextField.nativeElement.innerHTML = this.richTextField.nativeElement.textContent;
      this.showingSourceCode = false;
    } else {
      this.richTextField.nativeElement.textContent = this.richTextField.nativeElement.innerHTML;
      this.showingSourceCode = true;
    }
  }
  ngOnInit(): void {
    // this.richTextField = document.getElementById('rte') as HTMLDivElement;
    // // this.enableEditMode();
    if (this.richTextField) {
      this.richTextField.nativeElement.innerHTML = this.rteContent;
    }
  }
}
