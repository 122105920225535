import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import { JobDescriptionModel, JobPostingDetailModel } from '../../core/models/job-profile.model';
import { JobApplicantsModel } from '../../core/models/job-applicants-model';
import { ListingModel, JobDescriptionFormModel } from '../../core/models';
@Injectable({
    providedIn: 'root'
})

export class JobsStore {
    @observable apiResp: any;
    @observable jobListing: JobPostingDetailModel[] = [];
    @observable jobDetail: JobPostingDetailModel = new JobPostingDetailModel();
    @observable createProfileFormData: JobDescriptionFormModel = new JobDescriptionFormModel();
    @observable jobApplicants = new JobApplicantsModel();
    @observable locationList: ListingModel[] = [];
    @observable companyList: ListingModel[] = [];
    @observable skillList: ListingModel[] = [];
    @observable domainExpertList: ListingModel[] = [];
    @observable recruiterMsg: any;
    @observable jobTitleList: ListingModel[] = [];
    constructor() { }
    /* store action for create profile page */
    @action createJobListPage() {
        this.locationList = this.apiResp[0];
        // this.companyList = this.apiResp[1];
        this.skillList = this.apiResp[1];
        // let list = []
        // for (const item of this.apiResp[3]) {
        //     list.push({ id: item.id, name: item.title })
        // }
        // this.jobTitleList = list;
    }
    /* get all the available jobs */
    @action getAlljobListings() {
        this.jobListing = this.apiResp.job_postings;

    }
    /* get all the available jobs */
    @action getJobDetail() {
        this.jobDetail = this.apiResp;

    }    
    /* store action for job listing page */
    @action getLocationList() {
        this.locationList = this.apiResp;
    }
    @action getSkillsList() {
        this.skillList = this.apiResp;
    }
    @action getAppliedJob(){
        let response: JobApplicantsModel = this.apiResp
        this.jobApplicants = response;        
    }
    
}


