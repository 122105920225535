import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { Router } from '@angular/router';
import { MyStartupsService } from 'src/app/connexdoor/dashboard/mystartups/services/mystartups.service';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-delete-startup-popup',
  templateUrl: './delete-startup-popup.component.html',
  styleUrls: ['./delete-startup-popup.component.scss']
})
export class DeleteStartupPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  companyProfileId:number;
  constructor(private router:Router,
    public myStartupService: MyStartupsService,
    public appService:AppService ) { }

  ngOnInit(): void {
    this.companyProfileId = this.popup.context.companyProfileId;
  }
  closePopup() {
    this.close.emit('icon');
  }
  /** Delete the startup */
  deleteStartup(){
    this.myStartupService.deleteStartup(this.companyProfileId).subscribe(response => {
      this.appService.toggleToast({
        type: 'success',
        load: true,
        position: 'bottom-center',
        context: {
          description: `Organisation deleted successfully`
        }
      });
      location.reload(); 
    });  
  }
}
