<div class="flex jobCard flexResponsive posRelative">
    <div class="width45 padtop20 padbtm20 flex flexResponsive flexJustifyBetween mobiTextCenter">
        <div class="width20  jobLogo cursorPtr"
        routerLink="/startup/{{cardData?.company_profile?.id}}">
            <img class="d100" [src]="cardData?.company_profile?.logo">
        </div>
        <div class="pad6 desktopVisible"></div>
        <div class="width80">
            <div class="flex flexResponsive flexAlignItemsCenter flexJustifyBetween">
                <div class="circular-bold font18 colorBlack width40">
                    Job code: #{{cardData?.id}}
                </div>
                <div class="txtCenter font14 roboto-regular color707070 width60 mrgnbtm5">
                    <img src="assets/images/loc.svg" class="padright5">
                    {{cardData?.location?.name}}
                </div>
            </div>
            <div class="color707070 roboto-regular font14 padtop5 elipsis lineHeight18px" [innerHTML]="cardData?.role_description">
            </div>
            <div class="respadding">
                <span class="pills roboto-medium colorBlack mrgnright5">{{cardData?.job_title?.title}}</span>
            </div>
        </div>
    </div>
    <div class="pad6 desktopVisible"></div>
    <div class="width55 flex flexResponsive">
        <div class="flex flex3" [ngClass]="{'flex3': cardType== 'jobPosted', 'flex2': cardType== 'myJobs'}">
            <div class="flex2 bg-EFEFEF txtCenter padbtm20 padtop20 padleft10 padright10">
                <div class="txtCenter padbtm10">
                    <div class="roboto-regular colorBlack pills txtTransformUp mobPills">POSTED ON</div>
                </div>
                <div>
                    <div class="txtCenter roboto-bold font16">{{cardData?.posted_on | date: 'dd-MM-yyyy'}}</div>
                </div>
            </div>
            <div class="flex2 txtCenter padbtm20 padtop20 padleft10 padright10">
                <div class="txtCenter padbtm10">
                    <div class="roboto-regular colorBlack pills txtTransformUp mobPills">VIEWS</div>
                </div>
                <div>
                    <div class="txtCenter roboto-bold font16">{{cardData?.views}}</div>
                </div>
            </div>
            <div class="flex2 bg-EFEFEF txtCenter padbtm20 padtop20 padleft10 padright10 " *ngIf="cardType== 'jobPosted'">
                <div class="txtCenter padbtm10">
                    <div class="roboto-regular colorBlack pills txtTransformUp mobPills">APPLICATIONS</div>
                </div>
                <div>
                    <div class="" *ngIf="cardData?.status == 'New'">
                        <div class="txtCenter roboto-bold font18">
                            <img src="assets/images/padlock.svg" class="lock" />
                        </div>
                        <div class="circular-book font12 color707070 txtCenter">Approval<br />Pending</div>
                    </div>
                    <div class="" *ngIf="cardData?.status == 'Completed'">
                        <div class="txtCenter roboto-bold font18">
                            <img src="assets/images/padlock.svg" class="lock" />
                        </div>
                        <div class="circular-book font12 color707070 txtCenter">Closed</div>
                    </div>
                    <div class="" *ngIf="cardData?.status != 'New' && cardData?.status != 'Completed'">
                        <div class="txtCenter roboto-bold font18">{{cardData?.applications}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex1 flexJustifyCenter flexAlignItemsCenter flexWrap" style="display: flex">
            <div class="flex1 flex flexAlignItemsCenter flexJustifyCenter padbtm20 padtop20 padleft10">
                <a class="btn btn-signup ripple"  (click)="onViewjobz(cardData?.id)" *ngIf=" cardType== 'jobPosted'">View</a>
                <a class="btn btn-signup ripple"  (click)="onViewjobSome(cardData?.id)" 
                *ngIf=" cardType == 'myJobs'">View</a>
                <div class="width25 padleft10 flex flexJustifyCenter cursorPtr posRelative dropdownHover" *ngIf=" cardType== 'jobPosted'">
                    <img src="assets/images/dots.svg" class="cursorPtr">
                    <div class="dropdownBar">
                        <div
                            class="roboto-medium font14 colorBlack items flex flexAlignItemsCenter ripple cursorPtr padleft15 padright15">
                            <a (click)="downloadAttachment()">
                               
                                <img src="assets/images/settings_icn.svg" class="mrgnright10 d20" />
                                Export applications
                            </a>

                        </div>
                        <div class="roboto-medium font14 colorBlack items flex flexAlignItemsCenter ripple  cursorPtr padleft15 padright15"
                            
                            (click)="onViewJD(cardData?.id)">
                            <img src="assets/images/eye.svg" class="d20 mrgnright10" />
                            View JD
                        </div>
                        <div class="roboto-medium font14 colorBlack items flex flexAlignItemsCenter ripple cursorPtr padleft15 padright15"
                            
                            (click)="onViewJDEdit(cardData?.id)"
                            >
                            <img src="assets/images/edit_icon.svg" class="mrgnright10 d20" />
                            Edit JD
                        </div>
                        <div class="roboto-medium font14 colorBlack items flex flexAlignItemsCenter ripple cursorPtr padleft15 padright15"
                            (click)="closePosition(cardData.id)">
                            <img src="assets/images/delete_icn.svg" class="mrgnright10 d20" />
                            Close this position
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>