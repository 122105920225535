import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* Core libs start here */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
/* Core libs end here */

/* Modules Start Here */
import { SharedModule } from './shared/shared.module';
/* Modules End Here */

/* Interceptor import  */
import { cdHttpInterceptorsAndHandler } from './core/interceptor/index';


/* Third party library start here */
export function TranslatorFactory(httpClient: HttpClient) { return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json'); }
import { MobxAngularModule } from 'mobx-angular';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppStore } from './core/stores/app.store';

/* Third party library end here */

/* Components Start Here */
import { HomeComponent } from './connexdoor/components/home/home.component';
import { MagicProfileComponent } from './connexdoor/components/magic-profile/magic-profile.component';
import { config } from 'src/environments/environment';
import { ContactusComponent } from './connexdoor/components/contactus/contactus.component';
import { AboutusComponent } from './connexdoor/components/aboutus/aboutus.component';
import { PrivacypolicyComponent } from './connexdoor/components/privacypolicy/privacypolicy.component';
import { ConfirmAccountComponent } from './connexdoor/components/confirm-account/confirm-account.component';
import { NgChatModule } from 'ng-chat';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { FintecasticWomenComponent } from './connexdoor/components/fintecastic-women/fintecastic-women.component';
import { FintechCafeComponent } from './connexdoor/components/fintech-cafe/fintech-cafe.component';
// import {AngularSocialPlugins} from 'angular-social-plugins';
// import { NgxSocialShareModule } from 'ngx-social-share';
// import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
// import { ShareButtonModule } from 'ngx-sharebuttons/button';
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';
const socketConfig: SocketIoConfig = { url: `${config.apiURL}`, options: { path: '/chat/socket.io/'} };
/* Components End Here */
declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MagicProfileComponent,
    ContactusComponent,
    AboutusComponent,
    PrivacypolicyComponent,
    ConfirmAccountComponent,
    FintecasticWomenComponent,
    FintechCafeComponent
  ],
  imports: [
    BrowserModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MobxAngularModule,
    SharedModule,
    NgChatModule,
    ShareButtonsModule,
    // AngularSocialPlugins,

    // ShareButtonsModule.withConfig({
    //   debug: true
    // }),
    // ShareIconsModule,
    // NgxSocialShareModule,
    // ShareButtonModule,
    // JwSocialButtonsModule,
    SocketIoModule.forRoot(socketConfig),
    DeviceDetectorModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    /* Setting up the Translate Module with factory and loader as Root  */
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslatorFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  providers: [
    
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              config.socialCredentials.googleWebApiKey
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(config.socialCredentials.fbAppId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    cdHttpInterceptorsAndHandler,
    AppStore,
    Meta
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public translateService: TranslateService) {
    translateService.addLangs(["en", "hi"]);
    translateService.setDefaultLang('en'); /* Setting up the Translate Json to English - `en` */
  }
}