import { JobProfileModel } from './job-profile.model';
import { DomainExpertiseModel } from './domain-expertise.model';
import { LocationModel } from './location.model';
import { JobModel } from './job.model';
import { EducationModel } from './education.model';
import { ListingModel } from './other.model';

export class UserProfileModel {
    dob ?: any = '';
    email ?: string = "";
    user_type?:string="";
    first_name ?: string = '';
    id ?: number = 0;
    last_name ?: string = ""
    phone_number ?: string = "";
    job_profile_type ?: any= {};
    company_profile_type ?: any = {};
    linkedin_url ?: string = '';
    twitter_url ?: string = '';
    website_url ?:string = '';
    job_profiles ?: JobProfileModel[] = [];
    domain_expertises ?: DomainExpertiseModel[] = [];
    location ?: LocationModel = new LocationModel();
    current_job ?: JobModel = new JobModel();
    educations ?: EducationModel[] = [];
    background_image_url ?: string = '';
    cover_letter_url ?: string = '';
    roles ?: ListingModel[] = [];
    cv_url ?: string = '';
    skills ?: any[] = [];
    total_experience_months ?: number = 0;
    total_experience_years ?:any = 0;
    description: string = '';
    profile_completeness ?:number = 0;
    profile_image_id ?:number;
    background_image_id?:number;
    profile_image_url?:string;
    connect_status?:string;
}

