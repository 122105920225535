import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import { ListingModel, JobDescriptionFormModel } from '../../../../core/models';
import { JobsListedCompanyProfileModel } from '../../../../core/models/company.model';
import { JobDescriptionModel } from '../../../../core/models/job-profile.model';


@Injectable({
    providedIn: 'root'
})

export class MyStartupsStore {
    @observable apiResp: any;
    @observable companyListDetails: any;
    @observable jobDescriptionFormModel: JobDescriptionFormModel = new JobDescriptionFormModel();
    @observable locationList: ListingModel[] = [];
    @observable companyList: ListingModel[] = [];
    @observable domainExpertList: ListingModel[] = [];
    @observable skillList: ListingModel[] = [];
    @observable jobTitleList: ListingModel[] = [];
    @observable jobDescriptionModel = new JobDescriptionModel();
    @observable teamList: ListingModel[] = [];
    @observable myStartupList: JobsListedCompanyProfileModel[] = []
    constructor() { }


    /* store action for admin setting page */
    @action adminSettingPage() {
        this.teamList = [];
        for (const item of this.apiResp.founders) {
            this.teamList.push({ id: item.id, name: `${item.first_name} ${item.last_name}`, email: item.email })
        }
        for (const item of this.apiResp.team) {
            this.teamList.push({ id: item.id, name: `${item.first_name} ${item.last_name}`, email: item.email })
        }

    }

    /* store action for create profile page */
    @action createJobDescriptionPage() {
        let list = []
        for (const item of this.apiResp[0]) {
            list.push({ id: item.id, name: item.title })
        }
        this.jobTitleList = list;
        this.locationList = this.apiResp[1];
        this.skillList = this.apiResp[2];
    }

    /* store action for create profile page */
    @action getLocationList() {
        this.locationList = this.apiResp;
    }

    /* store action for create profile page */
    @action getSkillList() {
        this.skillList = this.apiResp;
    }


    @action getJobTitleList() {
        let list: any = []
        for (const item of this.apiResp) {
            list.push({ id: item.id, name: item.title })
        }
        this.jobTitleList = list;
    }

    /* get Company List */
    @action companyProfileInformation() {
        this.myStartupList = this.apiResp.company_profiles;

    }

    @action jobDescriptionInformation() {
        this.jobDescriptionModel = this.apiResp.job_posting ?  this.apiResp.job_posting : this.apiResp;
    }
}