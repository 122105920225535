export class ProfileDropDownModel {
    data ?: any[] = [];
    label : string = '';
    placeholder ?: string = '';
    customClass ?: string = '';
    uiSearch ?: boolean = false;
    formControlName ?: any ;
    formGroup ?: any;
    icon ?: boolean = false;
    selectedData ?: any=[];
    isnotShowCustomValue ?: boolean = true;
}

export class DropdownDataModel {
    id ?: any = '';
    name ?: string = '';
    key ?: any = '';
}

export class FilterDropdownModel {
    data ?: DropdownDataModel[] = [];
    label ?: string = '';
    isSearch ?: boolean = false;
    placeholder ?: string = '';
    selectedData ?: DropdownDataModel[] = [];
}