<script
  src="https://kit.fontawesome.com/d26f09468b.js"
  crossorigin="anonymous"
></script>
<div
  id="rteContanier"
  class="posRelative flex flexDirectionCol flexJustifyBetween"
>
  <div
    id="rteToolbar"
    class="boxShadowDown posSticky posTop flex flexAlignItemsCenter flexWrap bg-white"
  >
    <div class="pad5">
      <button type="button" (click)="execCmd('bold')">
        <i class="fa fa-bold"></i>
      </button>
    </div>
    <div class="pad5">
      <button type="button" (click)="execCmd('italic')">
        <i class="fa fa-italic"></i>
      </button>
    </div>
    <div class="pad5">
      <button type="button" (click)="execCmd('underline')">
        <i class="fa fa-underline"></i>
      </button>
    </div>
    <div class="pad5">
      <button type="button" (click)="execCmd('strikeThrough')">
        <i class="fa fa-strikethrough"></i>
      </button>
    </div>
    <div class="pad5">
      <button type="button" (click)="execCmd('justifyLeft')">
        <i class="fa fa-align-left"></i>
      </button>
    </div>
    <div class="pad5">
      <button type="button" (click)="execCmd('justifyCenter')">
        <i class="fa fa-align-center"></i>
      </button>
    </div>
    <div class="pad5">
      <button type="button" (click)="execCmd('justifyRight')">
        <i class="fa fa-align-right"></i>
      </button>
    </div>
    <div class="pad5">
      <button type="button" (click)="execCmd('justifyFull')">
        <i class="fa fa-align-justify"></i>
      </button>
    </div>
    <!-- <div class="pad5">
            <button type="button" (click)="execCmd('cut');"><i class="fa fa-cut"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('copy');"><i class="fa fa-copy"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('paste');"><i class="fa fa-paste"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('indent');"><i class="fa fa-indent"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('outdent');"><i class="fa fa-outdent"></i></button>
        </div>
        <button type="button" for="file" class="posRelative">
            <input class="fa fa-file-image-o posAbsolute posTop posBtm posLeft posRight" type="file" accept="image/*"
                style="opacity: 0" id="file" (change)="execCmd('insertImage', $event)">
            <i class="fa fa-image"></i>
        </button>
        <div class="pad5">
            <button type="button" (click)="execCmd('undo');"><i class="fa fa-undo"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('redo');"><i class="fa fa-redo"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('insertUnorderedList');"><i class="fa fa-list-ul"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('insertOrderedList');"><i class="fa fa-list-ol"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('insertParagraph');"><i class="fa fa-paragraph"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('insertHorizontalRule');">HR</button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('createLink');"><i class="fa fa-link"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="execCmd('unlink');"><i class="fa fa-unlink"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="toggleSource();"><i class="fa fa-code"></i></button>
        </div>
        <div class="pad5">
            <button type="button" (click)="toggleEdit();">Toggle Edit</button>
        </div>
        <div class="pad5">
            <select (change)="execCmd('formatBlock');" id="formatBlock">
                <option value="H1">H1</option>
                <option value="H2">H2</option>
                <option value="H3">H3</option>
                <option value="H4">H4</option>
                <option value="H5">H5</option>
                <option value="H6">H6</option>
            </select>
        </div> -->
    <!-- <div class="pad5">
            <select [(ngModel)]="fontName" (change)="execCmd('fontName');" id="fontName">
                <option value="Arial">Arial</option>
                <option value="Comic Sans MS">Comic Sans MS</option>
                <option value="Courier">Courier</option>
                <option value="Georgia">Georgia</option>
                <option value="Tahoma">Tahoma</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Verdana">Verdana</option>
            </select>
        </div> -->
    <!-- <div class="pad5">
            <select [(ngModel)]="fontSize" (change)="execCmd('fontSize');" id="fontSize">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
            </select>
        </div> -->
    <!-- <div class="pad5">
            <label>Font Color: </label>
            <input type="color" (change)="execCmd('foreColor');" id="foreColor">
        </div>
        <div class="pad5">
            <label>Background Color: </label>
            <input type="color" (change)="execCmd('hiliteColor');" id="hiliteColor">
        </div> -->

    <div
      id="rte"
      contenteditable="true"
      unselectable="off"
      (keypress)="updateRTEContent()"
      (keydown)="updateRTEContent()"
      (paste)="execCmd($event)"
      #rte
    ></div>
  </div>
</div>
