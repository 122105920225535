export const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for(var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
      if(name == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
      }
  }

  // Return null if not found
  return null;
}

/* difference in months between two Dates */
export const diffMonths = (dt2, dt1)  => {
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60 * 24 * 7 * 4);
  return Math.abs(Math.round(diff));
}

/* difference in months between two Dates */
export const diffYears = (dt2, dt1)  => {
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60 * 24);
  return Math.abs(Math.round(diff/365.25));
}

export const isValidEmail = (email:string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isValidMobile = (mobileNo:string) => {
  const regx = /^[6-9]\d{9}$/ ;
    return regx.test(String(mobileNo).toLowerCase());
}
export const generate_token = (length:number) => {
  var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(""); 
  var b = []; 
  for (var i=0; i<length; i++) { 
    var j = (Math.random() * (a.length-1)).toFixed(0); 
    b[i] = a[j]; 
  } 
  return b.join(""); 
}
export const getYearList = () => {
  let max = new Date().getFullYear();
  let min =( new Date().getFullYear()) - 20;
  let yearList = [];
  for (var i = min; i<=max; i++){
    yearList.push(i);
  }
  return yearList;
}

export const monthList = [{
    id: 1,
    name: "january"
  },{
    id: 2,
    name: "february"
  },{
    id: 3,
    name: "march"
  },{
    id: 4,
    name: "april"
  },{
    id: 5,
    name: "may"
  },{
    id: 6,
    name: "june"
  },{
    id: 7,
    name: "july"
  },{
    id: 8,
    name: "august"
  },{
    id: 9,
    name: "september"
  },{
    id: 10,
    name: "october"
  },{
    id: 11,
    name: "november"
  },{
    id: 12,
    name: "december"
  }]