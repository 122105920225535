<main *mobxAutorun class="appWrapper height100p flex flexDirectionCol flexJustifyBetween ">
  <section class="flex flexDirectionCol flexJustifyBetween BGF5F5F5 height100p">
    <div>
        <app-loader></app-loader>
        <app-toaster></app-toaster>
        <app-header></app-header>
        <router-outlet></router-outlet>
    </div>
   
    <app-footer></app-footer>
    <ng-chat [isViewportOnMobileEnabled]="true" [isCollapsed]="true" [adapter]="adapter" [title]="title" [userId]="userId" *ngIf="appService.isLoggedIn() && adapter" customTheme="../../assets/css/chat.css" (onParticipantChatOpened)="chatOpened($event)"></ng-chat>
  </section>
  
  
</main>