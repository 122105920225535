import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppStore } from 'src/app/core/stores/app.store';
import { AppService } from './core/services/app.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from 'src/environments/environment';
import { ChatAdapter } from 'ng-chat';
import { SocketIOAdapter } from './socketio-adapter'
import { Socket } from 'ngx-socket-io';
import {HttpClient, HttpResponse} from '@angular/common/http'
import { filter } from 'rxjs/operators';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  userId: string;
  userName: string;
  userInfo: any;
  title: string = "Connections";
  public adapter: ChatAdapter;
  
   
  constructor(
    public sanitizer: DomSanitizer,
    public appStore: AppStore,
    public router: Router,
    public appService: AppService,
    public route: ActivatedRoute,
    public deviceDetectService: DeviceDetectorService,
    public socket: Socket,
    public http:HttpClient
  ) {
    const navEndevents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd ),
      );
      navEndevents.subscribe((event: NavigationEnd) => {
        gtag('config', 'UA-163892223-1', {
          'page_path': event.urlAfterRedirects
        });
      });

    this.checkBrowserCompatibility();
    this.InitializeSocketListerners();
    if (this.appService.isLoggedIn()) {
      this.appService.userPermission().subscribe(response => {
        this.userInfo = response;
        this.userName = String(this.userInfo.user.user_profile.phone_number);
        this.socket.emit("join", this.userInfo.user.user_profile.id);
      });
    }
    else {
      this.appService.checkLogIn().subscribe(status => {
        if(status){
          this.appService.userPermission().subscribe(response => {
            this.userInfo = response;
            this.userName = String(this.userInfo.user.user_profile.phone_number);
            this.socket.emit("join", this.userInfo.user.user_profile.id);
          });
        }
      })
    }
  }

  public InitializeSocketListerners(): void
  {
    this.socket.on("generatedUserId", (userId) => {
      this.adapter = new SocketIOAdapter(userId, this.socket, this.http);
      this.userId = userId;
    });
  }

  chatOpened(user){
    console.log(user);
  }

  /* Listener Events to monitor the Device network status*/
  @HostListener('window:offline', ['$event'])
  isOffline(event ?: any){
    this.appService.deviceNetworkStatus(event.type)
  }

  @HostListener('window:online', ['$event'])
  isOnline(event ?: any){
    this.appService.deviceNetworkStatus(event.type)
  }


  /* Function to check the browser compatibility */
  checkBrowserCompatibility(){
    const deviceDetails = this.deviceDetectService.getDeviceInfo();
    if(config.compatibleBrowsers[deviceDetails.browser.toUpperCase()] > parseInt(deviceDetails.browser_version.split('.')[0])){
      let popupObj = { type: 'incompatible', load: true, overlayClose: false, context: {title: 'browser not compatible'} }
      this.appService.togglePopup(popupObj);
    }
  }

  ngOnInit(): void {
    this.appService.checkSession();
  }


}


// 2205895896