import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { AppStore } from 'src/app/core/stores/app.store';

@Component({
  selector: 'app-inactivity',
  templateUrl: './inactivity.component.html',
  styleUrls: ['./inactivity.component.scss']
})
export class InactivityComponent {

  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor(public appStore: AppStore) { }

  /* Close Popup Icon Method */
  public closePopup(close_type ?: string){
    this.close.emit(close_type);
  }

}
