import { ChatAdapter, User, Message, ParticipantResponse } from 'ng-chat';
import { Observable, of } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { HttpClient } from '@angular/common/http'
import 'rxjs/add/observable/throw';
import { config } from 'src/environments/environment';

export class SocketIOAdapter extends ChatAdapter
{

    connections: any = [];

    constructor(private userId: string, private socket: Socket, private http: HttpClient) {
        super();
        this.userId = userId;

        this.InitializeSocketListerners();  
    }

    listFriends(): Observable<ParticipantResponse[]> {
        // List connected users to show in the friends list
        // Sending the userId from the request body as this is just a demo 
        return this.http
            .post(`${config.apiURL}chat/listFriends`, { userId: this.userId })
            .pipe(
                map(function(res:any){
                    this.connections = res;
                    console.log(this.connections);
                    return res;
                }),
                catchError((error:any) => Observable.throw(error || 'Server error'))
            );
    }

    getMessageHistory(userId: any): Observable<Message[]> {
        // This could be an API call to your NodeJS application that would go to the database
        // and retrieve a N amount of history messages between the users.
        return this.http
            .post(`${config.apiURL}chat/getMessages`, { userId: userId })
            .pipe(
                map((res:any) => res),
                catchError((error:any) => Observable.throw(error || 'Server error'))
            );
    }
    
    sendMessage(message: Message): void {
        this.socket.emit("sendMessage", message);
    }

    public InitializeSocketListerners(): void
    {
      var t = this;
      this.socket.on("messageReceived", (messageWrapper) => {
        // Handle the received message to ng-chat
        console.log(messageWrapper);
        this.onMessageReceived(messageWrapper.user, messageWrapper.message);
      });

      this.socket.on("friendsListChanged", async function(usersCollection: Array<ParticipantResponse>) {
        // Handle the received message to ng-chat
        var friendsList = await t.http.get(`${config.apiURL}connections`).subscribe(function(data: any){
          var connectionIds = data.connections.map(connection => connection.connect_id);
          t.onFriendsListChanged(usersCollection.filter(x =>  (connectionIds.includes(x.participant.id)) && (x.participant.id != t.userId)));
        });
      });
    }
}
