<div class="bg-white">
    <div class="pad10 header flex flexAlignItemsCenter flexJustifyCenter poRelative">
        <img src="assets/images/connex_door_logo.svg">
        <div class="iconClose ripple cursorPtr" (click)="closePopup()">
            <i class="icns icon-close d15"></i>
        </div>
    </div>
    <div class="body flex bg-white pad15">
        <div class="width50 flex flexDirectionCol">
            <b class="COLOR319966 txCenter mrgnbtm5">Uploaded Image</b>
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
                format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded( $event)"
                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
        <div class="pad5"></div>
        <div class="width50 flex flexDirectionCol">
            <b class="COLOR319966 mrgnbtm10">Preview</b>
            <img [src]="croppedImage" class="previewImage" />
        </div>
    </div>
    <div class="pad10 txtRight">
    <button class="btn btn-signup ripple mrgnauto" (click)="updatedImage();">{{'UPDATE'}}</button>
    </div>
</div>