import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import { PopupModel, ToastModel, UserProfileModel, UserModel, UserPermissionModel,NotificationModel } from 'src/app/core/models';


@Injectable({
  providedIn: 'root'
})
export class AppStore {
  @observable apiResp: any;
  @observable idleCountdown: number;
  @observable popup: PopupModel = new PopupModel();
  @observable toast: ToastModel = new ToastModel();
  @observable userProfile: UserProfileModel = new UserProfileModel();
  @observable userInformation: UserModel = new UserModel();
  @observable userPermissions: any;
  @observable notfcationList: NotificationModel[] = [];

  constructor() { }

  /* method for sign up popup otp creation */
  @action signUpPopupCreateOtpMethod() {
    this.userProfile = this.apiResp;
  }

  /* store action for getnotification */
  @observable getNotificationList() {
    this.notfcationList = this.apiResp;
  }

  /* method for sign up user creation */
  @action signUpUserInformationMethod() {
    this.userInformation = this.apiResp.user;
  }

  /* store action for sign in user info method */
  @action signInUserInfoPopupMethod() {
    this.userInformation = this.apiResp;
  }

  /* store action for set user profile details and permissions*/
  @action userProfileInformation() {
    let response : UserPermissionModel = this.apiResp
    this.userPermissions = response;
  }

}
