<div id="bulkUpload" class="bg-white width100">
    <div class="pad10 header flex flexAlignItemsCenter flexJustifyCenter posRelative">
        <img src="assets/images/bulk-upload.svg">
        <div class="circular colorBlack font11 txtTransformCaps padtop15 padleft15">Hiring bulk for
            <p class="circular-bold mrgn0 colorBlack font24 txtTransformCaps">{{popup?.context?.name}}</p>
        </div>

        <!-- <div class="iconClose ripple cursorPtr" (click)="closePopup()">
            <i class="icns icon-close d15"></i>
        </div> -->
    </div>
    <div class="body">
        <div class="height100p">
            <div class="flex flexResponsive padbtm15">
                <div class="width55  flex flexJustifyCenter">
                    <div class="width56 padbtop5 customForm">
                        <a class="uploadBttn posRelative width100">
                            <img src="assets/images/cloud.svg" /> {{'Upload Excel or CSV' | translate}}
                            <input type="file" [(ngModel)]="upload_id" (change)="uploadpProfile($event,popup?.context?.id)" class="attachmentFile width100">
                        </a>
                        <div class="flex flexResponsive padbtm15">
                            <div class="width50 font10">Excel, CSV</div>
                            <div class="width50 txtRight font10">File size: 25 mb </div>
                        </div>

                    </div>
                </div>
                <div class="width45  flex flexDirectionCol flexAlignItemsCenter">
                    <p class="coloraaaaaa">Download bulk job template</p>
                    <a href="assets/images/format.csv">
                        <div class="flex flexResponsive padbtm15">
                            <div class="width50 font10">
                                <img src="assets/images/svg_icon.svg" class="width65 cursorPtr">
                            </div>
                            <div class="width50 txtRight font10">
                                <div class=" font12 padleft5 padtop5">
                                    <u>CSV</u>
                                </div>
                            </div>
                        </div>


                    </a>

                </div>

            </div>
        </div>
    </div>
</div>