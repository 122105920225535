<div id="applyJobSuccess">
    <div class="top-header">
        <div class="flex flexJustifyEnd padtop10 padright20">
            <a (click)="closePopup()">
                 <img src="assets/images/close.svg" />
            </a>
        </div>
        <div class="flex padtop15 padbtm30 centrepostion">
            <div class="centrealign"><img src="assets/images/application_success.svg" class="padright15 successImg"></div>
            <div class="centrepostion">
                <div class="color707070 font16 circular-book">Application sent successfully!!</div>

            </div>
        </div>
    </div>
 

 </div>