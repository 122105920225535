<div id="popupContainer" [ngClass]="appStore.popup.type+'PopUp'" class="flex flexJustifyCenter flexAlignItemsCenter" *ngIf="appStore.popup.load">
  <div id="popupOverlay" (click)="closePopup('overlay')"></div>
  <div id="popupContentBox" class="bdrRadius6px">
    <div [ngSwitch]="appStore.popup.type">

      <div *ngSwitchCase="'bulkUpload'">
        <!-- Popup for errors starts-->
        <app-bulk-upload-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-bulk-upload-popup>>
        <!-- Popup for errors ends-->
      </div>
      <div *ngSwitchCase="'problemStatement'">
          <!-- Popup for errors starts-->
          <app-problem-statement-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-problem-statement-popup>
          <!-- Popup for errors ends-->
        </div>
      <div *ngSwitchCase="'builkUploadError'">
        <!-- Popup for errors starts-->
        <app-bulk-upload-error-popup (close)="closePopup($event)" [popup]="appStore.popup"></app-bulk-upload-error-popup>
        <!-- Popup for errors ends-->
      </div>
      <div *ngSwitchCase="'error'">
        <!-- Popup for errors starts-->
        <app-error (close)="closePopup($event)" [popup]="appStore.popup"></app-error>
        <!-- Popup for errors ends-->
      </div>
      <div *ngSwitchCase="'incompatible'">
        <!-- Popup for incompatible starts -->
        <app-incompatible (close)="closePopup($event)" [popup]="appStore.popup"></app-incompatible>
        <!-- Popup for incompatible Ends -->
      </div>
      <div *ngSwitchCase="'noInternet'">
        <!-- Popup for no-internet starts -->
        <app-no-internet (close)="closePopup($event)" [popup]="appStore.popup"></app-no-internet>
        <!-- Popup for no-internet Ends -->
      </div>
      <div *ngSwitchCase="'inactivity'">
        <!-- Popup for inactivity starts -->
        <app-inactivity (close)="closePopup($event)" [popup]="appStore.popup"></app-inactivity>
        <!-- Popup for inactivity ends -->
      </div>
      <div *ngSwitchCase="'menu'">
        <!-- Popup for menu starts -->
        <app-menu (close)="closePopup($event)" [popup]="appStore"></app-menu>
        <!-- Popup for menu ends -->
      </div>

      <div *ngSwitchCase="'findOrPostSignUp'">
        <app-find-or-post-job-popup [popup]="appStore.popup" (close)="closePopup($event)"></app-find-or-post-job-popup>
      </div>

      <div *ngSwitchCase="'signUp'">
        <app-signup-popup [popup]="appStore.popup" (close)="closePopup($event)"></app-signup-popup>
      </div>
      <div *ngSwitchCase="'forgotPassword'">
        <app-forgot-password-popup [popup]="appStore.popup" (close)="closePopup($event)"></app-forgot-password-popup>
      </div>
      <div *ngSwitchCase="'signIn'">
        <app-signin-popup [popup]="appStore.popup" (close)="closePopup($event)"></app-signin-popup>
      </div>
      <div *ngSwitchCase="'job-description-success'">
        <app-job-description-success-popup [popup]="appStore.popup" (close)="closePopup($event)">
        </app-job-description-success-popup>
      </div>
      <div *ngSwitchCase="'apply-job'">
        <app-apply-job [popup]="appStore.popup" (close)="closePopup($event)"></app-apply-job>
      </div>
      <div *ngSwitchCase="'apply-job-success'">
        <app-apply-job-success [popup]="appStore.popup" (close)="closePopup($event)"></app-apply-job-success>
      </div>
      <div *ngSwitchCase="'delete-startup'">
        <app-delete-startup-popup [popup]="appStore.popup" (close)="closePopup($event)"></app-delete-startup-popup>
      </div>
      <div *ngSwitchCase="'inviteadmin'">
        <app-inviteadmin [popup]="appStore.popup" (close)="closePopup($event)"></app-inviteadmin>
      </div>
      <div *ngSwitchCase="'jdPreview'">
        <app-preview-jd-popup [popup]="appStore.popup" (close)="closePopup($event)"></app-preview-jd-popup>
      </div>
      <div *ngSwitchCase="'imageCrop'">
        <app-image-crop [popup]="appStore.popup" (close)="closePopup($event)"></app-image-crop>
      </div>       
      <div *ngSwitchDefault></div>
    </div>
  </div>
</div>