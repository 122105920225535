<div id="startupDeletePopup" class="bg-white width100 height60p">
    <div class="iconClose d25 ripple cursorPtr" (click)="closePopup()">
        <i class="icns icon-close d10"></i>
    </div>
    <div class="body">
        <div class="height30p width100 txtCenter padtop20 padleft20 padright20">
            <!-- <div class="flex pad20 width100 font18 jd_thankyou_txt" > -->
                <p>{{'do you want to delete this startup' | translate}}</p>
            <!-- </div> -->
        </div>

        <div class="height30p width100" class="button_div">
            <div class="flex flexJustifyEnd">
                <a (click)="closePopup()" class="btn btn-back ripple mrgnright15 minWidth150px txtTransformCaps">{{ 'cancel' | translate }}</a>
                <button type="submit" 
                (click)="deleteStartup()"
                class="btn btn-continue ripple minWidth150px"
                >{{ 'delete' | translate }}</button>
            </div> 
        </div>       
    </div>
</div>