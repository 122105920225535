<div id="jobsByMe" class="pad15">
    <div class="width80 mrgnauto">
        <div class="flex head circular-bold colorBlack flexResponsive padbtm35 txtCenter">Jobs applied by me </div>
        <app-dashboard-job-card [cardData]="item?.job_posting" *ngFor="let item of  jobAppliedStore.jobAppliedList" [cardType]="'myJobs'"></app-dashboard-job-card>
         <!-- <div class="loadMoreCls">
            <a (click)="loadMore()" class="btn btn-continue ripple circular-bold" *ngIf="loadMoreVisible">Load more</a>
        </div> -->
         <div *ngIf="jobAppliedStore?.jobAppliedList?.length == 0"
            class="noDataAvailable flex flexDirectionCol flexAlignItemsCenter flexJustifyCenter">
            {{'no data available' | translate}}
        </div>
     </div>
</div>