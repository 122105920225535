import { Component, OnInit } from '@angular/core';
import { JobsAppliedService } from '../../jobs-applied.service';
import { JobAppliedStore } from '../../store/jobsapplied.store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jobs-applied',
  templateUrl: './jobs-applied.component.html',
  styleUrls: ['./jobs-applied.component.scss']
})
export class JobsAppliedComponent implements OnInit {
  page_num:number = 1;
  loadMoreVisible:boolean= true;
  subscription: Subscription = new Subscription();
  constructor(
    public jobsAppliedService : JobsAppliedService,
    public jobAppliedStore :JobAppliedStore,
    private router: Router
  ) { }
  /* get job posted list */
  getMyJobs() {
    this.jobsAppliedService.getMyJobs().subscribe(response => {
      this.jobAppliedStore.getJobAppliedList();
    });
  }
  /* navigate to details page*/
  navigate(id:number){
    this.router.navigate(['/dashboard/myjobs/view/'+id]);
    window.scroll(0,0);
  }
  ngOnInit(): void {
    this.getMyJobs();
    if(this.jobAppliedStore.jobAppliedList.length < 10)
      this.loadMoreVisible = false;
  }
  loadMore(){
    this.page_num = this.page_num+ 1;
    const page_num_filter  = {'page_no':this.page_num };

    this.subscription.add( 
      this.jobsAppliedService.getMyJobs(page_num_filter).subscribe((response:any) => {
        if(response.length < 10){
          this.loadMoreVisible = false;
        }
        for (const item of response) {
          this.jobAppliedStore.jobAppliedList.push(item);
        }
      })
    );
  }
}
