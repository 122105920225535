<!-- Popup for menu starts -->
<div id="menuPopUp" class="flex flexDirectionCol flexJustifyBetween verticalScrollContainer">
    <div class="width100">
      <div class="width100 pad10 flex flexAlignItemsCenter bdrBtm-dddddd bg-white posSticky posTop zIndex1 boxShadowDown">
        <div class="flexNoShrink d70 mrgn5 bdrRadius50p overflowHidden zIndex10"
        (click)="closePopup('icon')"
        [routerLink]="appStore.userPermissions?.user?.user_profile?.profile_image_url ? ['/user-profile',appStore?.userPermissions?.user?.user_profile?.id] : []"
        [ngStyle]="{ 'background-image': appStore.userPermissions?.user?.user_profile?.profile_image_url ?
        'url(' + appStore.userPermissions?.user?.user_profile?.profile_image_url + ')':'url(assets/images/connexdoor_mobile_logo.svg)', 'background-size': 'cover' }"></div>
        <div class="pad5">
          <div id="profileName"  class="txtTransformCaps">{{appStore.userPermissions?.user?.user_profile?.first_name}} {{appStore.userPermissions?.user?.user_profile?.last_name}}</div>
          <!-- <div id="profileName"  class="txtTransformCaps" *ngIf="!appStore.userPermissions?.user?.user_profile?.first_name">user</div> -->
          <div class="description">
            <!-- <div class="padtop3" *ngIf="popup.user?.schoolName">
              {{popup.user?.schoolName}}
            </div> -->
            <div class="padtop3" *ngIf="popup.userPermissions?.user?.user_profile?.phone_number ">
              {{popup.userPermissions?.user?.user_profile?.phone_number}}
            </div>
            <div class="padtop3 wrdBreakAll" *ngIf="popup.userPermissions?.user?.user_profile?.email">
              {{popup.userPermissions?.user?.user_profile?.phone_number}}
            </div>
          </div>
        </div>
      </div>
      <nav id="menuList">
        <div class="menuItem  flex flexAlignItemsCenter elementToFadeInAndOut" (click)="closePopup('icon')" [routerLink]="['/fintecastic-women']">
            <img src="assets/images/mike.svg" alt="">
            <img src="assets/images/Fintech program.svg" alt="">
        </div>
        <div class="menuItem flex flexAlignItemsCenter" (click)="closePopup('icon')" [routerLink]="['/jobs']" routerLinkActive="active">
          <div class="pad10 width100">{{'Jobs' | translate}}</div>
        </div>
        <div class="menuItem flex flexAlignItemsCenter" (click)="closePopup('icon')" [routerLink]="['/startup']" routerLinkActive="active">
          <div class="pad10 width100">{{'Startups' | translate}}</div>
        </div>
        <div class="menuItem  flex flexAlignItemsCenter" (click)="closePopup('icon')" [routerLink]="['/investors']" routerLinkActive="active">
          <div class="pad10 width100">{{'Investors' | translate}}</div>
        </div>
        <div class="menuItem  flex flexAlignItemsCenter" (click)="closePopup('icon')" [routerLink]="['/corporates']" routerLinkActive="active">
          <div class="pad10 width100">{{'Corporates' | translate}}</div>
        </div>
        <div class="menuItem  flex flexAlignItemsCenter" (click)="closePopup('icon')" [routerLink]="['/people/list']" routerLinkActive="active">
          <div class="pad10 width100">{{'People' | translate}}</div>
        </div>
        <div class="menuItem  flex flexAlignItemsCenter" (click)="closePopup('icon')" [routerLink]="['/dashboard']" routerLinkActive="active">
          <div class="pad10 width100">{{'Dashboard' | translate}}</div>
        </div>


        <div class="menuItem  flex flexAlignItemsCenter" (click)="logout('icon')" *ngIf="appStore.userPermissions?.user?.user_profile">
          <div class="pad10 width100">{{'logout' | translate}}</div>
        </div>
        <div class="menuItem  flex flexAlignItemsCenter" (click)="signIn()" *ngIf="!appStore.userPermissions?.user?.user_profile">
          <div class="pad10 width100">{{'login' | translate}}</div>
        </div>
      </nav>
    </div>
    <!-- <div class="width100 pad10 flex flexDirectionCol flexAlignItemsCenter">
      <span class="description">{{'from' | translate}}</span>
      <i class="icns icon-ei-mindspark-color mrgn5"></i>
    </div> -->
  </div>
  <!-- Popup for menu ends -->
