export class NotificationModel {
  accept_url ?: string = '';
  confirm_url ?:string = '';
  url ?:string = '';
  created_at?: string = ''
  description ?: string = ''
  id ?: number = 0;
  notification_id ?: number = 0;
  notification_type ?: string = ''
  reject_url?: string = ''
  status ?: string = ''
  title ?: string = ''
  updated_at ?: string = ''
  user_profile_id ?: string = ''
}
