import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input('data') data = [];
  @Output() closeEvent = new EventEmitter();
  constructor() { }

  /* Close notification Icon Method */
  closeNotification() {
    this.closeEvent.emit(false);
  }

  getNotificationCount() {
    return this.data.filter(x => x.status == 'New').length
  }

  ngOnInit(): void {
  }

}
