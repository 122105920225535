import { LocationModel } from './location.model';
import { CompanyProfileModel } from './company.model';
export class JobPostedListModel {
    deadline ?: string = '';
    description ?: string = '';
    employment_type ?: string = '';
    equity ?: string = '';
    id ?: number = 0;
    job_title ?: any;
    job_url ?: string = '';
    location ?: LocationModel = new LocationModel();
    logo ?: any;
    role_description ?: string = '';
    salary ?: string = '';
    status ?: string= '';
    title ?: string = '';
    views ?: number = 0;
    applications ?: number = 0;
    company_profile ?: CompanyProfileModel = new CompanyProfileModel();
    remote_work ?: boolean = false;
    posted_on?: string = ''
    skills: any;
}