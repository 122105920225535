import { ListingModel } from './other.model';
export class ExperienceModel {
    id?: number = 0;
    user_profile_id?: number = 0;
    company?: any = {};
    experience_months: number = 0;
    experience_years: number = 0;
    job_titles?:ListingModel[] = [];
    start_date?: string = '';
    end_date?: string = '';
    created_at?: string = '';
    updated_at?: string = '';
    description?: string = '';
    
}