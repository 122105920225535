<div id="bulkUploadError">
    <div class="top-header">

        <div class="flex padtop15 padbtm30 centrepostion">

            <div class="centrepostion">
                <div class="coloref0101 font23 circular-book head">Upload Errors</div>


                <ng-container *ngIf="popup?.context?.result?.creation_errors?.length > 0">
                    <p class="error_head">Creation Errors</p>
                    <ng-container *ngFor="let item of popup.context.result.creation_errors; let i= index">
                        <ul *ngFor="let error of item; let j= index">
                            <li *ngFor="let data of error; let k= index">{{data}}</li>
                        </ul>
                    </ng-container>

                </ng-container>

                <ng-container *ngIf="popup?.context?.result?.validation_errors?.length>0">
                    <p class="error_head">Validation Errors</p>
                    <ng-container *ngFor="let item of popup.context.result.validation_errors; let i= index">
                        <ul *ngFor="let error of item; let j= index">
                            <li *ngFor="let data of error; let k= index">{{data}}</li>
                        </ul>
                    </ng-container>

                </ng-container>

            </div>
        </div>
    </div>


</div>