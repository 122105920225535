import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AppService } from '../../../core/services/app.service';
import { isValidEmail, isValidMobile } from '../../../core/essentials/common.libs';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit {
  forgotPasswordForm: any;
  subscription: Subscription = new Subscription();
  isInvalidInput: boolean = false;
  interval: any;
  timer: number = 30;
  username:any;
  pwdEnterVisible:boolean =  false;
  otpMessageString = "an otp is sent to the number or email"
  isOtpShow: boolean = false;
  isInvalidOtp: boolean = false;
  submittedOtp: boolean = false;
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
    this.forgotPasswordForm = formBuilder.group({
      "user": formBuilder.group({
        "username": ['', Validators.required],
      })
    });
  }
/* form controls returns */
f(controlName?: any) {
  return this.forgotPasswordForm.get('user').get(controlName)
}



checkIsValidMobileOrEmail(event?: any) {
  this.isInvalidInput = true;
  let value = this.forgotPasswordForm.value.user.username;
  if (value.includes("@")) {
    if (isValidEmail(value)) {
      this.isInvalidInput = false;
    }
  } else {
    if (isValidMobile(value)) {
      this.isInvalidInput = false;
    }
  }
}

mustMatchValid(event?: any) {
  let control = this.forgotPasswordForm.controls["user"].controls["password"]
  let matchingControl = this.forgotPasswordForm.controls["user"].controls["password_confirmation"]
  if (control.value !== matchingControl.value) {
    matchingControl.setErrors({ mustMatch: true });
  } else {
    matchingControl.setErrors(null);
  }

}


submitForm() {
  if (!this.isOtpShow) {
    this.isOtpShow = true;
    this.startCountdown(30);
    this.forgotPasswordForm.controls["user"].addControl('otp', new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]))
    this.forgotPasswordForm.controls["user"].addControl('password', new FormControl('', [Validators.required]))
    this.forgotPasswordForm.controls["user"].addControl('password_confirmation', new FormControl('', [Validators.required]));

     this.subscription.add(
       this.appService.forgotPassword(this.forgotPasswordForm.value).subscribe(response => {
         this.isOtpShow = true;
         this.forgotPasswordForm.controls["user"].addControl('otp', new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]))
         this.forgotPasswordForm.controls["user"].addControl('password', new FormControl('', [Validators.required]))
         this.forgotPasswordForm.controls["user"].addControl('password_confirmation', new FormControl('', [Validators.required]))

         // this.startCountdown(30)  MustMatch('password', 'password_confirmation')
       })
     );
  } else {
    let data = {
      "user": {
        "username": this.forgotPasswordForm.value.user.username,
        "password": this.forgotPasswordForm.value.user.password,
        "password_confirmation": this.forgotPasswordForm.value.user.password_confirmation,
      }
    }
     this.subscription.add(
       this.appService.resetPassword(data).subscribe(response => {
         this.appService.toggleToast(
           {
             type: 'success',
             load: true,
             position: 'bottom-center',
             context: { description: `Account reset successfully` }
           }
         );
       })
     );
     this.router.navigate(['user-profile/edit']);
  }
}
/* OTP Timer Expiry Method*/
startCountdown(seconds = 30) {
  let counter: any = 30;
  this.interval = setInterval(() => {
    counter--;
    this.timer = counter;
    if (counter == 0) {
      clearInterval(this.interval);
    };
  }, 1000);
}

verifyOTP(event?: any) {
  this.isInvalidOtp = false;
  if (this.forgotPasswordForm.value["user"]["otp"].length == 6) {
    this.subscription.add(
      this.appService.verifyOtpForgetPassword(this.forgotPasswordForm.value).subscribe(response => {
        this.isInvalidOtp = false;
        this.submittedOtp = true;
        this.otpMessageString = 'otp successfully verified';
        this.pwdEnterVisible =  true;
      }, err => {
        this.isInvalidOtp = true;
      })
    )


  }
}
/* resend otp method */
resendOtp() {
  let resendOTPparam = {
    user_profile: {}
  }
    resendOTPparam["user_profile"]["phone_number"] = this.forgotPasswordForm.value.user.username;
  this.subscription.add(
    this.appService.resendOtpProfile(resendOTPparam).subscribe(response => {
      this.otpMessageString = 'otp successfully resent';
    })
  )

}

ngOnDestroy(): void {
  if (this.subscription)
    this.subscription.unsubscribe();
}




ngOnInit() {
  this.subscription.add(
    this.route.params.subscribe(param => {
      this.username = param['username'];
    })
  )
  this.forgotPasswordForm.get('user').patchValue({
    "username": this.username
  });
}

}
