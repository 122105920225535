import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { config } from 'src/environments/environment';
import { AppService } from 'src/app/core/services/app.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-linkedinlogin',
  templateUrl: './linkedinlogin.component.html',
  styleUrls: ['./linkedinlogin.component.scss']
})
export class LinkedinloginComponent implements OnInit {
  linkedInToken = "";
  subscription: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
    public appService: AppService,
    public appStore: AppStore,
    private router: Router,
    private loader: LoaderService,
    ) { 


  }

  ngOnInit(): void {
    this.linkedInToken = this.route.snapshot.queryParams["code"];
    this.getAccessToken();
  }

  getAccessToken(){
    const jsonData = {
      code : this.linkedInToken,
      redirect_uri : config.linkedinRedirectURI,
    };

    this.subscription.add(
      this.appService.getLoggedLinkedinUser(jsonData).subscribe((response:any)=>{
        this.appStore.signUpUserInformationMethod();
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-center',
          context: { description: `Hi,welcome ${this.appStore.userInformation.username}` }
        });
        this.router.navigate(["/home"]);
      }, er => {
        this.router.navigate(["/home"]);
      }) 
    )
    
  }

  /* Called once, before the instance is destroyed. */
  ngOnDestroy(): void {
    if ( this.subscription) {
      this.subscription.unsubscribe();
    }
    this.loader.hide();
  }


}
