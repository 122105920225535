export const platformConfig = {
  envType: "staging", /* `local` or `beta` or `develop` or `staging` or `prod` */
  local: {
    apiURL: "https://api.connexdoor.fortelogic.in/",
    webAppURL: "http://localhost:4200/",
    linkedinRedirectURI: "http://localhost:4200/linkedinlogin"
  },
  beta: {
  },
  develop: {
  },
  staging: {
    apiURL: "https://api.connexdoor.fortelogic.in/",
    webAppURL: "https://connexdoor.fortelogic.in/",
    linkedinRedirectURI: "https://connexdoor.fortelogic.in/linkedinlogin"
  },
  prod: {
    apiURL: "https://api.connexdoor.com/",
    webAppURL: "https://connexdoor.com/",
    linkedinRedirectURI: "https://connexdoor.com/linkedinlogin"
  }
};
