<div class=" bg-white">

    <div class="flex pad15 heading flexJustifyBetween flexAlignItemsCenter">
        <div class="flex flexAlignItemsCenter">
            <img src="assets/images/problem_statement.svg" class="problemIcon" />
            <div class="heading-text">{{'problem statement' | translate}}</div>
        </div>

    </div>
   
    <div class="pad15">
        <div class="main-title">
            <input type="text" class="title" [(ngModel)]="popup.context.data.title" name="title" (change)="updateTitle($event)"
             placeholder="Title" maxlength="50"required>
             <div class="helpBlock" *ngIf="showError">
                {{"title field is required" | translate}}
            </div>
        </div>
        <app-rich-text-editor [rteContent]="popup?.context?.data?.description" (refreshRTEContent)="onChange($event)">
        </app-rich-text-editor>
        <div class="helpBlock" *ngIf="showError">
            {{"description field is required" | translate}}
        </div>
        <div class="padtop10 flex flexJustifyEnd">
            <button class="btn btn-continue ripple" [ngClass]="{ 'disabled': showError}" (click)="submit()">
                {{(popup?.context?.data?.id ? 'update' : 'add') | translate}}
            </button>
        </div>
    </div>

</div>