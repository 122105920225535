<section id="magicProfilePage" class="width100 padtop70">
    <div class="width100 flex flexJustifyCenter magicProfileMainDiv">
        <div class="width80">
            <div class="magiProfileLinkedinContainer posRelative">
                <div
                    class="imageBackground flex posRelative flexResponsive flexJustifyStart flexAlignItemsCenter width100">
                    <div class="width40 flex flexJustifyCenter">
                        <div class="iconContainer desktopVisible">
                            <img src="assets/images/magicprofileIcn.svg" class="flex1">
                            <img src="assets/images/magicprofileStr.svg" class="flex1 posAbsolute">
                        </div>
                    </div>
                    <div class="width70 magicProfileInfoContainer padleft60">
                        <div class="magicProfileTitle font22 lineHeight36px width80">
                            {{'let me assemble myself' | translate}}</div>
                        <!-- <div class="magicProfileDescription lineHeight17px font14 txtLeft width80 mrgntop10">
                            {{'if you already have a linkedin profile you can set up your basic profile in blink we have took all the steps for your data security' | translate}}
                        </div> -->
                        <!-- <div class=" width100 mrgntop15">
                            <img src="assets/images/connect_linkedin.svg" class="connectLinkedin">
                        </div> -->
                        <div class="magicProfileInfo lineHeight16px font13 txtLeft mrgntop15 width80">
                            {{'create a basic profile by your own later you can add or edit' | translate}}</div>
                    </div>
                </div>
                <div class="flex flexJustifyEnd padbtm20 posCentre">
                    <button class="btn btn-continue ripple minWidth150px" [routerLink]="['/user-profile/create-profile']">{{ 'continue' | translate }}</button>
                </div>
                <!-- <div class="width100 orMainDiv flex flexJustifyCenter">
                    <div class="orContainer font14 lineHeight16px flex flexJustifyCenter flexAlignItemsCenter">
                        {{'or' | translate}}</div>
                </div> -->
            </div>

            <!-- <div class="width70 mrgnauto assembleMyselfContainer">
                <div class="flex posRelative flexResponsive flexJustifyBetween txtCenter">
                    <img src="assets/images/ice_statues.svg" class="flex1">
                    <div class="flex2 padleft20 txtLeft mrgntop15 mrgnbtm15 letmeAssembleLabel">
                        <a [routerLink]="['/user-profile/create-profile']"
                            class="font22 lineHeight22px txtLeft txtDecorationUnderline cursorPtr">{{'let me assemble me myself' | translate}}</a>
                        <div class="createBasicProfile font14 lineHeight16px mrgntop10">
                            {{'create a basic profile by your own later you can add or edit' | translate}}
                        </div>
                    </div>
                    <img src="assets/images/single_ice_statue.svg" class="flex1">
                </div>
            </div> -->
        </div>



    </div>
</section>