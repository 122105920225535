import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { AppService } from './../../../../core/services/app.service';

@Component({
  selector: 'app-bulk-upload-error-popup',
  templateUrl: './bulk-upload-error-popup.component.html',
  styleUrls: ['./bulk-upload-error-popup.component.scss']
})
export class BulkUploadErrorPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor(private appService: AppService, ) {

  }

  ngOnInit() {
    console.log(this.popup)
  }



}
