export class LocationListModel {
    id ?: number = 0;
    name ?: string = '';
    icon ?: string = '';
}

export class LocationModel {
    id ?: number = 0;
    name ?: string = '';
    created_at ?: string = '';
    updated_at ?: string = '';
    url ?: string = '';
    latitude ?: any = 0;
    longitude ?: any = 0;
    icon ?: string = '';
}