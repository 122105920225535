<div class="relative bg-white"> 
    <div class="terms flex flexJustifyCenter">
        <div class="width40 padtop100 txtCenter">
            <div class="headline-s01 cldgr padtop50 padbtm20 txtLeft mobiTxt">
                Contact Connexdoor
            </div>
            <div class="pad10 txtCenter">
                <div style="display: inline-flex;" class="width100">
                    <div class="flex width30 padleft50 mobiWidth">  
                        <img src="assets/images/addresscontact.svg" />
                    </div>
                    <div class=" width70"> 

                        <div class="headline-02 clgy padtop20 mainHead txtLeft">
                            Address 
                        </div>
                        <div class="padtop10 txtLeft">
                            Connexdoor, 91 Springboard, 1st Floor & 2nd Floor,<br>
                            Kagalwala House, Kalina, Bandra Kurla Complex,<br>
                            Santacruz East, Mumbai, Maharashtra 400098<br>
                        </div>
                    </div>
                </div>
                <div>
                    <div style="display: inline-flex;" class="width100 padbtm100">
                        <div class="flex width30 padleft50 mobiWidth">  
                            <img src="assets/images/emailcontact.svg" />
                        </div>
                        <div class=" width70"> 

                            <div class="headline-02 clgy padtop20 mainHead txtLeft">
                                Email 
                            </div>
                            <div class="padtop10 txtLeft">
                                info@connexdoor.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    </div>

</div>
