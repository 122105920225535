<div id="applyJob">
    <form class="jobApplicationForm customForm width100" [formGroup]="jobApplicationForm">
   <div class="top-header">
       <div class="flex flexJustifyEnd padtop10 padright20">
            <div class="iconClose ripple cursorPtr" (click)="closePopup()">
                    <i class="icns icon-close d15"></i>
                </div>
       </div>
       <input type="hidden" formControlName="cv_url" name="cv_url" value="{{appStore.userPermissions.user.user_profile.cv_url}}">
       <div class="flex padtop15 px-85 padbtm15">
           <div><img src="{{popup?.context?.jobDetail?.job_posting?.company_profile?.logo}}" class="padright15 imgFixedWidth"></div>
           <div>
               <div class="color707070 font12 circular-book">Send application for</div>
               <div class="circular-bold font24 COLOR38453F">{{popup.context?.jobDetail?.job_posting?.title}}</div>
               <div class="olor191919 font16 circular-book">{{popup.context?.jobDetail?.job_posting?.company_profile?.name}}</div>
           </div>
       </div>
   </div>
   <div class="flex padtop40 px-85 flexJustifyBetween padbtm20 flexResponsive">
        <div>
            <div class="roboto-regular font14 COLOR38453F flex flexAlignItemsCenter" [class.text-disable]="!hasCV">
                 <input type="checkbox" formControlName="use_saved_cv"
                 (change)="onCheckboxSelect($event,'cv')"
                 name="use_saved_cv" [value]="'true'" checked [class.disabled]="!hasCV"/> Use my uploaded CV</div>
            <div class="roboto-regular font14 COLOR38453F flex flexAlignItemsCenter padtop30" [class.text-disable]="!hasCL">
                 <input type="checkbox" formControlName="use_saved_cl" [class.disabled]="!hasCL"
                 (change)="onCheckboxSelect($event,'cl')"
                 name="use_saved_cl" [value]="'true'" checked /> Use my uploaded Cover letter</div>
        </div>
        <div class="roboto-regular font14 COLOR38453F padright10 flex flexAlignItemsCenter or">OR</div>
        <div>
            <ng-container *ngIf="!selectedFile">
            <a class="upload-bttn COLOR38453F posRelative" [class.button-disable]="!canUploadCV">
                <img class="cloudClass" src="assets/images/cloud.svg" />
                Upload CV
                <input type="file"  accept=".xlsx,.xls,.doc, .docx,.pdf" (change)="uploadCvOrCl($event,'cv')" class="attachmentFileCV width100">
            </a>
   
            <div class="flex padtop5 padleft5 padright5 padbtm5">
                <div class="helvetica-regular font12 coloraaaaaa">PDF, DOCX </div>
                <div class="ml-auto helvetica-regular font12 coloraaaaaa">File size: 25 mb </div>
            </div>
            </ng-container>
            <ng-container *ngIf="selectedFile">
                <div class="BGF5F5F5 bdrRadius4px mrgnbtm1 width100">
                    <div class="flex pad1020">
                        <img src="assets/images/file.svg" class="padright10" />
                        <span
                            class="roboto-regular font14 COLOR10894E maxWidth70p txtNoWrap txtOverflowEllipsis">{{selectedFile.name}}</span>
                        <span class="fileIcons">
                            <img src="assets/images/del.svg" class="cursorPtr"
                                (click)="unSelect('cv')" />
                        </span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!selectedCLFile">
            <a class="upload-bttn COLOR38453F posRelative" [class.button-disable]="!canUploadCL">
                <img class="cloudClass" src="assets/images/cloud.svg" />
                Upload Cover letter
                <input type="file" accept=".xlsx,.xls,.doc, .docx,.pdf"  (change)="uploadCvOrCl($event,'cl')" class="attachmentFileCV width100">
            </a>
   
            <div class="flex padtop5 padleft5 padright5">
                <div class="helvetica-regular font12 coloraaaaaa">PDF, DOCX </div>
                <div class="ml-auto helvetica-regular font12 coloraaaaaa">File size: 25 mb </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedCLFile">
            <div class="BGF5F5F5 bdrRadius4px mrgnbtm1 width100">
                <div class="flex pad1020">
                    <img src="assets/images/file.svg" class="padright10" />
                    <span
                        class="roboto-regular font14 COLOR10894E maxWidth70p txtNoWrap txtOverflowEllipsis">{{selectedCLFile.name}}</span>
                    <span class="fileIcons">
                        <img src="assets/images/del.svg" class="cursorPtr"
                            (click)="unSelect('cl')" />
                    </span>
                </div>
            </div>
        </ng-container>
        </div>
   </div>
   <div class="helpBlock px-85" *ngIf="this.jobApplicationForm.invalid && submitted">    
    <span *ngIf="jobApplicationForm.get('cv_url').errors?.required">You dont have an uploaded CV. Please upload a CV</span>                                
    </div>  
   <div class="px-85">
        <div class="width100 mrgntop15 padbtm20">
            <input class="basicFormInput width100"  formControlName="email" type="email" placeholder="Email" name="email" value="">
                 <div class="helpBlock" *ngIf="this.jobApplicationForm.invalid && submitted">    
                    <span *ngIf="jobApplicationForm.get('email').errors?.required">email is required</span>                                
                </div> 
        </div>  
        <app-dropdown-multiselect [form]="jobApplicationForm"
        [configData]="jobsStore.createProfileFormData?.skills"
        (apiSearch)="searchItems($event)"></app-dropdown-multiselect>
        <div class="helpBlock" *ngIf="this.jobApplicationForm.invalid && submitted">    
            <span *ngIf="jobApplicationForm.get('skill_ids').errors?.required">Please choose the skills from the dropdown</span>                                
        </div>  
        <div class="width100 mrgntop15">
            <div class="formLabel font14 lineHeight36px">{{ 'add a message to recruiter' | translate }}</div>            
            <input class="basicFormInput width100"  formControlName="description" 
            placeholder="its difficult,but,describe yourself in 40 words.this will help us know your core values." name="email" value="">
                 <div class="helpBlock" *ngIf="this.jobApplicationForm.invalid && submitted">    
                    <span *ngIf="jobApplicationForm.get('description').errors?.required">description is required</span>                                
                </div> 
        </div>
   <div class="px-100 padtop50 flex flexJustifyEnd flexAlignItemsCenter">
       <button class="btn btn-back ripple circular-bold font14 coloraaaaaa" (click)="closePopup()">Back</button>
       <button (click)="OnSubmit()" class="btn btn-continue ripple circular-bold">Apply</button>
   </div>



</div>
</form>
</div>