import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-description-success-popup',
  templateUrl: './job-description-success-popup.component.html',
  styleUrls: ['./job-description-success-popup.component.scss']
})
export class JobDescriptionSuccessPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  closePopup() {
    this.router.navigate(['dashboard/jobsposted']);
    this.close.emit('icon');
  }
}
