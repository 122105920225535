
export class JobApplicantsModel {
    id?: number = 0;
    email?: any = "";
    applied_on: any = "";
    status: any;
    description?: any = '';
    user_profile?: any = [];
    skills?: any = [];
    job_posting?: any = [];
    cv = new ResumeModel();
    cover_letter = new ResumeModel();

}


export class ResumeModel {
    id ?: number = 0;   
    filename ?: string = '';
    url ?: string = '';
}


