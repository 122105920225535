import { Component, OnInit, ViewChild, ElementRef, Input, HostListener, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { ProfileDropDownModel } from '../../../../core/models/profile-form.model';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-dropdown-multiselect',
  templateUrl: './dropdown-multiselect.component.html',
  styleUrls: ['./dropdown-multiselect.component.scss']
})
export class DropdownMultiselectComponent implements OnInit, OnChanges {
  @ViewChild('formInput') editableDiv: ElementRef;
  @Input('configData') configData;
  @Input() form: FormGroup;
  @Output() selectedData = new EventEmitter();
  @Output() apiSearch = new EventEmitter();
  @ViewChild('list') list: ElementRef;
  @ViewChild('icon') icon: ElementRef;
  @ViewChild('main') main: ElementRef;
  @ViewChild('inputBox') inputBox: ElementRef;
  @ViewChild('selectOpt') selectOpt: ElementRef;
  selectedItems: any[] = []
  selectedItem: any = '';
  isDropdownShow: boolean = false;
  isInValidField: boolean = false;
  inputValue: string = '';
  constructor() { }

  /* check Invalid or not */
  isInValid() { 
    if (this.form) {
      return this.form.controls[this.configData.formControlName].invalid; 
    }
  }

  /* Check field valid or not */
  checkFieldValid() {
    
    this.isInValidField = this.isInValid();
  }
  

  /* remove chip method */
  removeChip(item ?: any) {
    this.selectedItems = this.selectedItems.filter(x => x.id != item.id);
    this.checkChipsSelected('remove');
    this.checkFieldValid();
    this.isDropdownShow = false;

  }

  /* check chip selected */
  checkChipsSelected(action ?: string) {
    this.inputValue = '';
    for (const item of this.configData.data) {
      if (action == 'remove') {
        item["isSelected"] = false;
      };
      for (const chip of this.selectedItems) {
        if (chip.id == item.id) {
          item["isSelected"] = true;
        };
      };
    };
    if ( this.selectedItems.length == 0) {
      this.form.controls[this.configData.formControlName].patchValue([]);
    }
    this.form.controls[this.configData.formControlName].patchValue((this.selectedItems).map(x => x.id))
  }


  /* Host listner for out side click close */
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if ((this.list && !this.list.nativeElement.contains(event.target))) {
      if (((this.icon && !this.icon.nativeElement.contains(event.target)) || !this.icon) && ((this.main && !this.main.nativeElement.contains(event.target)) || !this.main) ) {
        this.isDropdownShow = false;
      }
    }
  }

  /* toggle drop down method */
  toggleDropdown() {
    if (this.icon && !this.icon.nativeElement.contains(event.target) && this.inputValue.length != 0) {
      console.log(this.inputValue.length, this.inputValue, 1);
      this.isDropdownShow = !this.isDropdownShow;
    } else if (!this.icon && this.inputValue.length != 0) {
      this.isDropdownShow = !this.isDropdownShow;
      console.log(this.inputValue.length, this.inputValue, 2);
    }
    this.checkFieldValid();
    if (this.isDropdownShow) {
      this.inputBox.nativeElement.focus();
      console.log(this.inputValue.length, this.inputValue, 3);
    }
  } 

  /* select Item from dropdown */
  selectItem(event ?: any, index ?: number) {
    this.inputValue = '';
    this.selectedItems.push(event);
    this.checkFieldValid();
    this.checkChipsSelected();
  }

  /* search event emitter */
  search(event ?: any) {
    this.checkFieldValid();
    if(this.inputValue.length != 0){
      this.isDropdownShow = true;
      this.apiSearch.emit({value: this.inputValue, formControlName: this.configData.formControlName})  
    }
    else{
      this.isDropdownShow = false;
    }
  }

  /* Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here. */
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedItems = [];
    if (this.configData.data && this.configData.data.length != 0) {
      setTimeout(x => {
        if (this.configData.selectedData && this.configData.selectedData.length != 0) {
          this.updateData();
        } else if (this.form.controls[this.configData.formControlName] && this.form.controls[this.configData.formControlName].value && this.form.controls[this.configData.formControlName].value.length != 0) {
          this.selectedItems = [];
          for (const item of this.configData.data) {
            for (const id of this.form.controls[this.configData.formControlName].value) {
              if (parseInt(id) == item.id) {
                this.selectedItems.push(item)
              }
            }
          }
          if (this.selectedItems.length == 0 && this.form.controls[this.configData.formControlName].value.length != 0) {
            for (const id of this.form.controls[this.configData.formControlName].value) { 
              this.selectedItems.push({id: id, name: id})
            }
          }
          this.checkChipsSelected();
        }
      },100)
    }
  }

  updateData() {
    this.selectedItems = JSON.parse(JSON.stringify(this.configData.selectedData));
    let arr1 = this.configData.data;
    let arr2 = this.selectedItems
    let result = _.map(arr1, function(item) {
      return _.merge(item, _.find(arr2, { 'id' : item.id }));
    });
    this.checkChipsSelected();
  }
  

  ngOnInit() {
    setTimeout(x => {
      if (this.configData.selectedData && this.configData.selectedData.length != 0) {
        this.updateData();
      }
    },100)
  }

}
