import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppStore } from 'src/app/core/stores/app.store';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input('popup') popup: any;
  @Output('close') close = new EventEmitter();
  constructor(
    public appStore: AppStore,
    public appService: AppService
  ) { }

  /* Close Popup Icon Method */
  public closePopup(close_type ?: string){
    this.close.emit(close_type);
  }
  /* logout method */
  logout(close_type ?: string) {
    this.appService.logoutSession();
    this.close.emit(close_type);
  }
  /** login method */
  signIn() {
    this.appService.togglePopup({
      type: 'signIn',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {}
    });
  }
}
