import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppStore } from 'src/app/core/stores/app.store';
import {trigger, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [
    trigger('fadeIn', [ 
      transition('void => *', [
        style({ opacity: 0 }), 
        animate(1000, style({opacity: 1}))
      ]),
      transition('* => void', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class ToasterComponent implements OnInit {

  constructor(
    public appStore: AppStore
  ) { }

  ngOnInit() {
  }

}
