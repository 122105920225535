import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from '../../../../core/models/popup.model';
import { AppService } from './../../../../core/services/app.service';
import { MyStartupsService } from '../../../../connexdoor/dashboard/mystartups/services/mystartups.service';
@Component({
  selector: 'app-bulk-upload-popup',
  templateUrl: './bulk-upload-popup.component.html',
  styleUrls: ['./bulk-upload-popup.component.scss']
})
export class BulkUploadPopupComponent implements OnInit {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  selectedFile: any;
  upload_id: any;
  constructor(private appService: AppService, private myStartupsService: MyStartupsService, ) { }

  ngOnInit(): void {
  }

  setParam() {
    let formData = new FormData();
    if (this.selectedFile) {
      formData.append("jobs_file", this.selectedFile);
    }
    return formData;
  }


  uploadpProfile(event?: any, id?: any) {
    this.selectedFile = event.target.files[0];
    this.myStartupsService.updateBulkUpload(this.setParam(), id).subscribe((response: any) => {
      this.upload_id = "";
      if (response.result.status == "Success") {
        this.appService.toggleToast({
          type: 'success',
          load: true,
          position: 'bottom-center',
          context: { description: `Updated successfully` }
        });
      } else if (response.result.status == "Failed") {
        this.appService.togglePopup({
          type: 'builkUploadError',
          load: true,
          refreshParent: true,
          overlayClose: true,
          context: response
        });

      }
    })
  }

}
