import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from 'src/app/core/models';
import { AppService } from '../../../../core/services/app.service';
import { AppStore } from '../../../../core/stores/app.store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-with-action',
  templateUrl: './notification-with-action.component.html',
  styleUrls: ['./notification-with-action.component.scss']
})
export class NotificationWithActionComponent implements OnInit {
  @Input('notification') notification = new NotificationModel();
  constructor(
    private appService: AppService,
    private appStore: AppStore,
    private router: Router,
  ) { }

  /* Notification CTA Action */
  notificationCTA(url?: string) {
    this.appService.postNotifications(url).subscribe((response: any) => {
      for (const noti of this.appStore.notfcationList) {
        if (noti.id == this.notification.id) {
          noti.status = response.status;
          if(response.url){
            this.router.navigate([response.url]);
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

}
