import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/core/services/app.service';
import { config } from 'src/environments/environment';
import { JobAppliedStore } from './store/jobsapplied.store';
@Injectable({
  providedIn: 'root'
})
export class JobsAppliedService {

  constructor(public http: HttpClient,
    public jobAppliedStore: JobAppliedStore) { }

    /* get job applied lis */
    getMyJobs(data ?: any) {
      return this.http.get(`${config.apiURL}${config.jobApplicationsURI}/${config.myAppliedJobs}.json`, { params: data })
      .pipe(map(
        response => this.jobAppliedStore.apiResp = response
      ));
    };

    // get job detail
    getJobAppliedDetail(id:number){
      return this.http.get(`${config.apiURL}${config.jobPostingURI}/${id}.json`)
      .pipe(map(response => this.jobAppliedStore.apiResp = response));
    }
}
