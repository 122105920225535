<div class="posRelative width100 multiDropDown">
    <div class="formLabel font14 lineHeight36px">{{(configData?.label || '') | translate}}</div>
    <div class="select2-selection--multiple posRelative" (click)="toggleDropdown() " #main>
        <ng-container *ngIf="selectedItems.length > 0">
            <ul class="select2-selection__rendered" #icon>
                <li class="select2-selection__choice ripple" *ngFor="let item of selectedItems">
                    <span class="select2-selection__choice__display">{{item.name}}</span>
                    <button type="button" class="select2-selection__choice__remove" (click)="removeChip(item)">
                                <span>×</span>
                            </button>
                </li>
            </ul>
        </ng-container>
        <span class="select2-search">
            <input class="select2-search__field" [(ngModel)]="inputValue" appDebounce
            [debounceTime]="100" (debounceClick)="search($event)" [placeholder]="((configData?.placeholder && configData?.placeholder != undefined) ? configData?.placeholder :  '') | translate" #inputBox>
        </span>
        <div class="listContainer" *ngIf="isDropdownShow" #list>
            <ul class="listingUl">
                <li class="ripple" [ngClass]="{'selectedItem': item.isSelected}" (click)="selectItem(item, i)" *ngFor="let item of configData.data | LockFilter: inputValue ; let i = index;">
                    <div class="width100 flex flexAlignItemsCenter flexJustifyBetween">
                        <div class="itemName">{{item.name}}</div>
                    </div>
                </li>
                <li class="ripple" [ngClass]="{'selectedItem': selectedItem?.id == inputValue}" (click)="selectItem({name: inputValue, id: inputValue})" *ngIf="inputValue != ''">
                    <div class="itemName">{{inputValue}}</div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="helpBlock" *ngIf="isInValidField">{{'this field is required' | translate }}</div>
<div class="displayNone" [formGroup]="form" *ngIf="configData.formControlName">
    <select name="{{configData.formControlName}}" formControlName="{{configData.formControlName}}" #selectOpt>
        <option *ngFor="let item of configData.data" [value]="item.id">{{item.name}}</option>
    </select>
</div>