

<div class="relative padtop25 bg-white"> 
    <div class="about-us padtop60">
        <div class="px-20">
            <div class="padtop50 padbtm30 circular-bold font22 colorYellow abtUsTxt">ABOUT US:</div>
            <div class="padbtm10 circular-bold font24 colorWhite">Our Story</div>
            <div class="roboto-regular font18 colorWhite lineHeight22" style="opacity: 0.65">After conversing with a few hundred of fintech startup founders, we mapped their common pain points and decided to make the founder’s journey a little more comfortable. While we were exploring our opportunities, we happened to see a pool of opportunities for all players in the fintech ecosystem. That’s how the idea of ConnexDoor was born - to branch out endless possibilities, not only for fintech startups but every other player in this ecosystem.</div>
        </div>
    </div>
    <div class="padtop40 padbtm50 txtCenter circular-bold colorGreen font35">Connect. Collaborate. Grow</div>
    <div class="px-20">
        <div class="circular-bold ab-hd-txt padbtm10 font24">Our Utopia</div>
        <div class="circular-medium padbtm10 font22">Why spend money when you can have all that you need for building your startup, right at your fingertips?</div>
        <div class="ab-nr-txt roboto-regular padbtm20">We are encouraging a secular shift towards more flexible entrepreneurial, innovative and collaborative work styles.Stay connected through our proprietary technology and extract the greatest value!
        </div>
        <div class="ab-brdr txtCenter padbtm50">
            <img src="assets/images/about_us_vision.svg" />
        </div>
        <div class="flex padbtm70 padtop25 flexResponsive">
            <div>
                <div class="circular-bold ab-hd-txt padbtm10">Vision</div>
                <div class="ab-nr-txt roboto-regular padbtm20">To redefine the “Global Startup Ecosystem” and build a smarter & efficient entrepreneurial ecosystem, by operating a digital platform aimed at making it easier for anyone, anywhere to start and scale a business.</div>
            </div>
            <div class="width10">&nbsp;</div>
            <!-- <div class="width40">
                <div class="circular-bold ab-hd-txt padbtm10">Mission</div>
                <div class="ab-nr-txt roboto-regular padbtm20">
                    To create a sustainable, all inclusive startup environment by helping companies in adding valuable connections to fast-track their growth.
                </div>
            </div> -->
        </div>
    </div>

    <div class="team-bg">
        <div class="team-hd padbtm40">MEET OUR TEAM</div>
        <div class="flex flexResponsive">
            <div class="team-box">
                <div class="txtCenter">
                    <img src="assets/images/abhishant-new.jpeg" class="profileImg"/>
                </div>
                <div class="padtop40 circular-bold team-name">Abhishant Pant:</div>
                <div class="padbtm15 circular-medium font18">(Mentor & Strategic Investor)</div>
                <div class="padbtm30 roboto-regular team-content">Founder – The Fintech Meetup & Ex-Sr. Director, VISA.</div>
                <div class="flex">
                    <a href="https://www.linkedin.com/in/abhishantpant/" target="_blank"><img src="assets/images/t_linked.svg" class="padright5 cursorPtr" /></a>
                    <a href="https://twitter.com/AbhishantPant" target="_blank"><img src="assets/images/t_twitter.svg" class="cursorPtr"/></a>
                </div>
            </div>
            <div class="team-box">
                <div class="txtCenter">
                    <img src="assets/images/pankaj-new.jpeg" class="profileImg"/>
                </div>
                <div class="padtop40 circular-bold team-name">Pankaj Singh</div>
                <div class="padbtm15 circular-medium font18">(Co-Founder)</div>
                <div class="padbtm30 roboto-regular team-content">Ex- Bank of America Merrill Lynch and IIM Shillong and Fudan University, China.</div>
                <div class="flex">
                    <a href="https://www.linkedin.com/in/pankaj-singh-%E6%BD%98%E5%87%AF%E8%83%9C-6546ab2b/" target="_blank"><img src="assets/images/t_linked.svg" class="padright5 cursorPtr" /></a>
                    <a href="https://twitter.com/pankajsinghmodi" target="_blank"><img src="assets/images/t_twitter.svg" class="cursorPtr"/></a>
                </div>
            </div>
            <div class="team-box">
                <div class="txtCenter">
                    <img src="assets/images/SagrikaShah.jpeg" class="profileImg" />
                </div>
                <div class="padtop40  circular-bold team-name">Sagrika Shah</div>
                <div class="padbtm15 circular-medium font18">(Co-Founder)</div>
                <div class="padbtm30 roboto-regular team-content">Masters in FinTech from BSE Institute Limited with a knack for research</div>
                <div class="flex">
                    <a href="https://www.linkedin.com/in/sagrikashah" target="_blank"><img src="assets/images/t_linked.svg" class="padright5 cursorPtr" /></a>
                    <a href="https://twitter.com/sagrika_25?s=09" target="_blank"><img src="assets/images/t_twitter.svg" class="cursorPtr"/></a>
                </div>
            </div>
        </div>
        <div class="flex flexResponsive">
            <div class="team-box">
                <div class="txtCenter">
                    <img src="assets/images/sangeetha-new.jpeg" class="profileImg"/>
                </div>
                <div class="padtop40  circular-bold team-name">Sangeetha Madhavan</div>
                <div class="padbtm15 circular-medium font18">(CTO)</div>
                <div class="padbtm30 roboto-regular team-content">Founder of Fortelogic, Ex- HP & HCL Tech and NIT Calicut</div>
                <div class="flex">
                    <a href="https://www.linkedin.com/in/sangeetha-madhavan-8370b054/" target="_blank"><img src="assets/images/t_linked.svg" class="padright5 cursorPtr" /></a>
                    <!-- <img src="assets/images/t_twitter.svg" /> -->
                </div>
            </div>

        </div>
    </div>

</div>