<div id="forgotPopup" class="bg-white width100">
    <div class="pad10 header flex flexAlignItemsCenter flexJustifyCenter posRelative">
        <img src="assets/images/connex_door_logo.svg">
        <div class="iconClose ripple cursorPtr" (click)="closePopup()">
            <i class="icns icon-close d15"></i>
        </div>
    </div>
    <div class="body">
        <div class="height100p">
            <!-- step 1 start here -->
            <!-- *ngIf="currentStep == 1" -->
            <div class="width100  minHeight195px flex flexAlignItemsCenter flexDirectionCol flexJustifyStart">
                <div class="imHereTo lineHeight36px font22 mrgntop15">{{'Forgot your ConnexDoor account password' | translate}}</div>
                <div class=" width65 mrgntop15 minHeight120px padleft10 padright10">
                    <div class="flex ">
                        <form class="width100 pad30" [formGroup]="forgotPasswordForm">
                            <ng-container formGroupName="user">
                                <ng-container>
                                    <div class="width100 mrgnbtm15">
                                        <div class="materialTextfield width100 ">
                                            <input formControlName="username" [ngClass]="{'borderRed': isInvalidInput || (f('username')?.invalid && (f('username')?.dirty || f('username')?.touched)), 'disabled': isOtpShow}" class="width100" placeholder=" " type="text" appDebounce [debounceTime]="100"
                                                (debounceClick)="checkIsValidMobileOrEmail($event)">
                                            <label [ngClass]="{'redClr': isInvalidInput || (f('username')?.invalid && (f('username')?.dirty || f('username')?.touched))}">{{'e mail or mobile number' | translate}}</label>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="isOtpShow">

                                    <div class="materialInfo font13 mrgnbtm15">{{'OTP Timed Out. Resend for new OTP' | translate}}</div>
                                    <div class="width100 mrgnbtm15">
                                        <div class="materialTextfield width100 ">
                                            <input class="width100" placeholder=" " [ngClass]="{'borderRed': (f('otp')?.invalid && (f('otp')?.dirty || (f('otp')?.touched)) || !f('otp').errors?.required && isInvalidOtp),'disabled': submittedOtp}" formControlName="otp" type="text" appDebounce [debounceTime]="100"
                                                (debounceClick)="verifyOTP($event)" minlength="6" maxlength="6">
                                            <label [ngClass]="{'redClr': (f('otp')?.invalid && (f('otp')?.dirty || f('otp')?.touched)) || !f('otp').errors?.required && isInvalidOtp}">{{'enter otp recieved' | translate}}</label>
                                        </div>
                                        <ng-container *ngIf="(f('otp').invalid && (f('otp').dirty || f('otp').touched) ) || isInvalidOtp">
                                            <div class="helpBlock" *ngIf="f('otp').errors?.required">
                                                {{"this field is required" | translate}}
                                            </div>
                                            <div class="helpBlock" *ngIf="isInvalidOtp && !f('otp').errors?.required">
                                                {{"invalid otp" | translate}}
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="width100 mrgnbtm15">
                                        <div class="materialTextfield width100  passwordEyeContainer">
                                            <input autocomplete="new-password" [ngClass]="{'borderRed':(f('password')?.invalid && (f('password')?.dirty || (f('password')?.touched))),'disabled': f('otp')?.invalid || isInvalidOtp}" formControlName="password" id="password" class="width100" placeholder=" "
                                                type="{{passwordType}}">
                                            <label [ngClass]="{'redClr':(f('password')?.invalid && (f('password')?.dirty || (f('password')?.touched)))}">{{'New Password' | translate}}</label>
                                            <div class="iconRound passwordEye ripple">
                                                <i class="icns d20 icon-visibiliy" (click)="toggleEye()" [ngClass]="{'icon-visibiliy-off': passwordType == 'password', 'icon-visibiliy': passwordType == 'text'}"></i>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="(f('password').invalid && (f('password').dirty || f('password').touched) )">
                                            <div class="helpBlock" *ngIf="f('password').errors?.required">
                                                {{"this field is required" | translate}}
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="width100 mrgnbtm15">
                                        <div class="materialTextfield width100  passwordEyeContainer">
                                            <input autocomplete="new-password" [ngClass]="{'borderRed':(f('password_confirmation')?.invalid && (f('password_confirmation')?.dirty || (f('password_confirmation')?.touched))),'disabled': f('otp')?.invalid || isInvalidOtp}" formControlName="password_confirmation"
                                                id="password" class="width100" placeholder=" " appDebounce [debounceTime]="100" (debounceClick)="mustMatchValid($event)" type="{{passwordType}}">
                                            <label [ngClass]="{'redClr':(f('password_confirmation')?.invalid && (f('password_confirmation')?.dirty || (f('password_confirmation')?.touched)))}">{{'Confirm Password' | translate}}</label>
                                            <div class="iconRound passwordEye ripple">
                                                <i class="icns d20 icon-visibiliy" (click)="toggleEye()" [ngClass]="{'icon-visibiliy-off': passwordType == 'password', 'icon-visibiliy': passwordType == 'text'}"></i>
                                            </div>

                                        </div>
                                        <ng-container *ngIf="(f('password_confirmation').invalid && (f('password_confirmation').dirty || f('password_confirmation').touched) ) ">
                                            <div class="helpBlock" *ngIf="f('password_confirmation').errors?.required">
                                                {{"this field is required" | translate}}
                                            </div>
                                            <div class="helpBlock" *ngIf="f('password_confirmation').errors?.mustMatch">Passwords must match</div>
                                        </ng-container>

                                    </div>


                                </ng-container>
                                <p class="helpBlock error" *ngIf="errorMessage != null">{{errorMessage}}</p>
                                <div class="flex flexJustifyCenter">
                                    <button type="button" class="btn btn-continue ripple signUp" [ngClass]="{'disabled': forgotPasswordForm.invalid || isInvalidInput}" (click)="submitForm()">
                                        {{ (isOtpShow ? 'Reset Password' : 'next') | translate}}
                                    </button>

                                </div>
                            </ng-container>



                        </form>

                    </div>

                </div>
            </div>
            <!-- step 1 end here -->


            <!-- step 2 start here -->
            <!-- <div class="width100  minHeight195px flex flexAlignItemsCenter flexDirectionCol flexJustifyStart" *ngIf="currentStep == 2">
                <div class="imHereTo lineHeight36px font22 mrgntop15">{{'Forgot your ConnexDoor account password' | translate}}</div>
                <div class=" width65 mrgntop15 minHeight120px padleft10 padright10">
                    <div class="flex ">
                        <form class="width100 pad30">
                            <div class="width100">
                                <div class="materialTextfield width100 mrgnbtm15">
                                    <input class="width100" placeholder=" " type="text">
                                    <label>{{'e mail or mobile number' | translate}}</label>
                                </div>
                            </div>
                            <div class="materialInfo font13 mrgnbtm15">{{'OTP Timed Out. Resend for new OTP' | translate}}</div>
                            <div class="width100">
                                <div class="materialTextfield width100 mrgnbtm15">
                                    <input class="width100" placeholder=" " type="text">
                                    <label>{{'Verify OTP' | translate}}</label>
                                </div>
                            </div>
                            <div class="width100">
                                <div class="materialTextfield width100 mrgnbtm15">
                                    <input class="width100" placeholder=" " type="text">
                                    <label>{{'New Password' | translate}}</label>
                                </div>
                            </div>
                            <div class="width100">
                                <div class="materialTextfield width100 mrgnbtm15">
                                    <input class="width100" placeholder=" " type="text">
                                    <label>{{'Confirm Password' | translate}}</label>
                                </div>
                            </div>
                            <div class="flex flexJustifyCenter">
                                <button class="btn btn-continue ripple signUp">
                                    {{'Reset Password' | translate}}
                                </button>
                            </div>
                        </form>

                    </div>

                </div>
            </div> -->
            <!-- step 2 start here -->
        </div>

    </div>
</div>