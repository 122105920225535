import { Component, OnInit, Input } from '@angular/core';
import { JobPostedListModel } from 'src/app/core/models/jobposted.model';
import { JobspostedService } from '../../../../connexdoor/dashboard/jobsposted/services/jobsposted.service';
import { JobPostedStore } from '../../../../connexdoor/dashboard/jobsposted/store/jobsposted.store';
import { AppService } from '../../../../core/services/app.service';
import { config } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-job-card',
  templateUrl: './dashboard-job-card.component.html',
  styleUrls: ['./dashboard-job-card.component.scss']
})
export class DashboardJobCardComponent implements OnInit {
  @Input('cardData') public cardData: JobPostedListModel = new JobPostedListModel();
  @Input('cardType') public cardType  = 'jobPosted';
  token: any;
  constructor(
    private jobspostedService: JobspostedService,
    private jobPostedStore: JobPostedStore,
    private appService: AppService,
    private router: Router
  ) { 
    this.token = localStorage.getItem('user');
  }
  // /dashboard/myjobs/view/{{cardData?.id}}
  onViewJD(id: any) {
    this.router.navigateByUrl('/dashboard/myjobs/view/'+ btoa(id));
  }
  onViewJDEdit(id: any) {
    this.router.navigateByUrl('/dashboard/mystartups/job-description/edit/'+ btoa(id));
  }
 
  onViewjobz(id: any) {
    this.router.navigateByUrl('/dashboard/jobsposted/applicants/'+ btoa(id));
  } 
  
  onViewjobSome(id: any) {
    this.router.navigateByUrl('/dashboard/myjobs/view/'+ btoa(id));
  } 
  closePosition(id: number) {
    let data = {
      "job_posting": {
        "status": "Completed"
      }
    };
    this.jobspostedService.updateJobPostingStatus(id, data).subscribe(response => {
       this.jobPostedStore.getJobPostedDetail();
      //  for (let i = 0; i < this.jobPostedStore.jobPostedList.length; i++) {
      //    if (this.jobPostedStore.jobPostedList[i].id == id) {
      //      this.jobPostedStore.jobPostedList[i] =this.jobPostedStore.jobPostedDetail;
      //     break;
      //   };
      //  };
      location.reload();
      this.appService.toggleToast({
        type: 'success',
        load: true,
        position: 'top-center',
        context: { description: `This position is closed` }
      });
    })
  }
  /** Download attachment */
  downloadAttachment(){
    window.location.href = `${config.apiURL}${config.jobApplicationsURI}.csv?job=${this.cardData.id}&user_token=${JSON.parse(this.token).user_token}&username=${JSON.parse(this.token).username}`;
  }

  ngOnInit(): void {
  }

}
