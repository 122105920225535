import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { AppService } from '../../../../core/services/app.service';
import { AppStore } from '../../../../core/stores/app.store';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  interval: any;
  isNotificationShow: boolean = false;
  isShowActive: boolean  = false;
  constructor(
    public appService: AppService,
    public appStore: AppStore,
    public router: Router,
  ) {
    /* Router chnaged or not checking */
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getNotification();
        this.getUserPermissions();
        this.isShowActive = router.url.includes('fintech') || router.url.includes('fintecastic') 
      }
    });
  }


  /* Sign Up CTA Method */
  signUp() {
    this.appService.togglePopup({
      type: 'findOrPostSignUp',
      load: true,
      refreshParent: false,
      overlayClose: true,
      context: {}
    });
  }

  /* getNotification api method */
  getNotification() {
    if (this.appService.isLoggedIn()) {
      this.appService.getNotifications().subscribe(response => {
        this.appStore.getNotificationList();
      });
    }
  }

  /* toggle hamburger menu popup */
  toggleMenu() {
    this.appService.togglePopup({
      type: 'menu',
      load: true,
      refreshParent: false,
      overlayClose: true,
      context: {}
    });
  }

  /* get User permissions */
  getUserPermissions() {
    if ( this.appService.isLoggedIn()) {
      this.appService.userPermission().subscribe(reponse => {
        this.appStore.userProfileInformation();
        if (this.appStore.userPermissions.profile_completion  < 5) {
          this.router.navigate(['/user-profile/create-profile'])
        }
      });
    }
  }

  /* Close Event method */
  closeEvent(event?: boolean) {
    this.isNotificationShow = event;
  }

  /* logout method */
  logout() {
    this.appService.logoutSession();
  }

  /* Initialize Page Method */
  initPage(event: any) {
    this.getUserPermissions();
  }


  /* Sign In CTA Method */
  signIn() {
    this.appService.togglePopup({
      type: 'signIn',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {}
    });
  }

  /* menu Selection CTA */
  menuSelection() {
    this.router.navigate(['startup'])
  }

  /* Ng On initialize Component */
  ngOnInit() {
    if (this.appService.isLoggedIn()) {
      this.getUserPermissions();
      this.getNotification();
    }

  }

  /* Ng on changes life cycle */
  ngOnChanges(changes: SimpleChanges): void {
    this.getUserPermissions();
  }


}
