import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { config } from '../../../../../../src/environments/environment';
import { CorporateProfileStore } from '../store/corporate-profile.store';

@Injectable({
  providedIn: 'root'
})
export class CorporateProfileService {

  constructor(
    public http: HttpClient,
    private CorporateProfileStore: CorporateProfileStore
  ) { }


  /* fork join api for create profile */
  getCreateProfileForJoinAPI() {
    return forkJoin([
      this.http.get(`${config.apiURL}${config.locationURI}.json`),
      this.http.get(`${config.apiURL}${config.companyURI}.json`),
      this.http.get(`${config.apiURL}${config.industryURI}.json`),
      this.http.get(`${config.apiURL}${config.segmentURI}.json`)
    ]).pipe(map(
      response => this.CorporateProfileStore.apiResp = response
    ));
  }

  /* api for get location list */
  getCompanyList(data?: any) {
    return this.http.get(`${config.apiURL}${config.companyURI}.json`, { params: data })
      .pipe(map(
        response => this.CorporateProfileStore.apiResp = response
      ));
  }

  /* api for add lproblem statement */
  addCorporateProblemStatement(data?: any) {
    return this.http.post(`${config.apiURL}${config.corporateProblemStatementURI}.json`,data )
    .pipe(map(
      response => this.CorporateProfileStore.apiResp = response
    ));
  }

  /* api for delete problem statement */
  deleteCorporateProblemStatement(id?: any) {
    return this.http.delete(`${config.apiURL}${config.corporateProblemStatementURI}/${id}.json`)
    .pipe(map(
      response => this.CorporateProfileStore.apiResp = response
    ));
  }

  /* api for edit problem statement */
  updateCorporateProblemStatement(id?: any, data ?: any) {
    return this.http.patch(`${config.apiURL}${config.corporateProblemStatementURI}/${id}.json`,data )
    .pipe(map(
      response => this.CorporateProfileStore.apiResp = response
    ));
  }
  

  /* api for get comapny Details */
  getCompanyDetails(id: number) {
    return this.http.get(`${config.apiURL}${config.companyProfileURI}/${id}.json`)
      .pipe(map(
        response => this.CorporateProfileStore.apiResp = response
      ));
  }

  /* create company  */
  createCompany(data?: any) {
    return this.http.post(`${config.apiURL}${config.companyURI}.json`, data)
      .pipe(map(
        response => this.CorporateProfileStore.apiResp = response
      ));
  }

  /* update company  */
  updateCompany(id: number, data?: any) {
    return this.http.patch(`${config.apiURL}${config.companyURI}/${id}.json`, data)
      .pipe(map(
        response => this.CorporateProfileStore.apiResp = response
      ));
  }

  /* api for get location list */
  getLocationList(data?: any) {
    return this.http.get(`${config.apiURL}${config.locationURI}.json`, { params: data })
      .pipe(map(
        response => this.CorporateProfileStore.apiResp = response
      ));
  }

  /* api for get location list */
  getIndustryList(data?: any) {
    return this.http.get(`${config.apiURL}${config.industryURI}.json`, { params: data })
      .pipe(map(
        response => this.CorporateProfileStore.apiResp = response
      ));
  }

  /* api for get location list */
  getSegmentList(data?: any) {
    return this.http.get(`${config.apiURL}${config.segmentURI}.json`, { params: data })
      .pipe(map(
        response => this.CorporateProfileStore.apiResp = response
      ));
  }

  public updateStartupProfile(data: object, id: number) {
    return this.http.patch(`${config.apiURL}${config.companyURI}/${id}.json`, data)
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }

  public updateCompanyProfileUrl(data: object, id: number) {
    return this.http.patch(`${config.apiURL}${config.companyProfileURI}/${id}.json`, data)
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }

  public getMedia(id: number) {
    return this.http.get(`${config.apiURL}${config.companyURI}/${id}/${config.mediaURI}.json`, )
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }

  public createMedia(data: object) {
    return this.http.post(`${config.apiURL}${config.mediaURI}.json`, data)
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }

  public editMedia(data: object, id: number) {
    return this.http.patch(`${config.apiURL}${config.mediaURI}/${id}.json`, data)
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }


  public deleteMedia(id: number) {
    return this.http.delete(`${config.apiURL}${config.mediaURI}/${id}.json`)
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }


  public getFunding(id: number) {
    return this.http.get(`${config.apiURL}${config.companyURI}/${id}/${config.fundingsURI}.json`, )
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }

  public getTeam(id: number) {
    return this.http.get(`${config.apiURL}${config.companyURI}/${id}/${config.teamURI}.json`, )
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }



  /* api method for creating user profile*/
  public getInvestorList(data?: any) {
    return this.http.get(`${config.apiURL}${config.investorsURI}.json`, { params: data })
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }


  /* api method for creating user profile*/
  public createFunding(data?: any) {
    return this.http.post(`${config.apiURL}${config.fundingsURI}.json`, data)
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }

  public deleteFunding(data: object, id: number) {
    return this.http.delete(`${config.apiURL}${config.fundingsURI}/${id}.json`, data)
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }

  public editFunding(data: object, id: number) {
    return this.http.patch(`${config.apiURL}${config.fundingsURI}/${id}.json`, data)
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }

  public getMetaDataFromURL(url?: string) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'text/html; charset=utf-8')
    return this.http.get(`https://cors-anywhere.herokuapp.com/${url}`, { headers: headers, responseType: 'text' })
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));

  }



  /* api method for creating user profile*/
  public getOrganisation(data?: any) {
    return this.http.get(`${config.apiURL}${config.companyProfilesURI}/${config.listURI}.json`, { params: data })
      .pipe(map(response => this.CorporateProfileStore.apiResp = response));
  }







}
