import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { PopupModel } from 'src/app/core/models';
import { AppService } from '../../../../core/services/app.service';
import { base64ToFile } from 'src/app/core/essentials/blob.utils';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor(
    private appService: AppService
  ) { }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;

  }

   /* set param for api call */
   setParam(imageType?: string, imageData?: any) {
    let formData = new FormData();
    const imageFile: File = imageData;
    formData.append('attachment[attachable_type]', this.popup.context.attachable_type);
    formData.append('attachment[attachable_id]', this.popup.context.attachable_id);
    formData.append('attachment[attachable_ref]', this.popup.context.attachable_ref);
    formData.append('attachment[file]', imageFile);
    return formData;
  }
  imageLoaded(image?: HTMLImageElement) {
    console.log(image)
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  updatedImage() {
    let data = this.setParam(this.popup.context.attachable_ref,base64ToFile(this.croppedImage));

    this.appService.attachmentCreate(data).subscribe(response => {
      location.reload();
    });
  }

  closePopup() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.imageChangedEvent = this.popup.context.file;
  }  

  ngOnInit(): void {
    this.imageChangedEvent = this.popup.context.file;
  }

}
