<div id="findOrPostPopup" class="bg-white width100">
    <div class="pad10 header flex flexAlignItemsCenter flexJustifyCenter poRelative">
        <img src="assets/images/connex_door_logo.svg">
        <div class="iconClose ripple cursorPtr" (click)="closePopup()">
            <i class="icns icon-close d15"></i>
        </div>
    </div>
    <div class="body">
        <div class="height100p pad25 flex posRelative flexAlignItemsCenter flexDirectionCol flexJustifyStart">

            <!-- step 1 start here -->
            <div class="width100 minHeight250px flex flexAlignItemsCenter flexDirectionCol flexJustifyStart"
                *ngIf="currentStep == 1">
                <div class="imHereTo lineHeight36px font22 ">{{'who are you?' | translate}}</div>
                <div class=" width100 mrgntop15 minHeight120px padleft10 padright10 mobContainer">
                    <div class="flex flexCls">

                        <label [for]="item.id" class="flex2 flex flexAlignItemsCenter flexDirectionCol itemBox cursorPtr" *ngFor="let item of signUpTypes">
                            <div class="formRadioContainer">
                                <input class=" radioCustom selectRadio" [(ngModel)]="formRequestData.profileType"
                                    [id]="item.id" type="radio" name="job" [value]="item.value">
                                <label class="radioCustomLabel" [for]="item.id"></label>
                            </div>
                            <div class="mrgntop15">
                                <div class="formRadioContainer">
                                    <i class="icns {{item.icon}} d50" [ngClass]="{'active': formRequestData.profileType == item.value}"></i>
                                    <label class="radioCustomLabel" [for]="item.id" [ngClass]="{'active': formRequestData.profileType == item.value}">
                                        {{ item.name| translate}}
                                    </label>
                                </div>
                            </div>
                        </label>

                        <!-- <div class="flex2 flex flexAlignItemsCenter flexDirectionCol itemBox">
                            <div class="formRadioContainer">
                                <input class="radioCustom" [(ngModel)]="formRequestData.profileType" id="typeInvestor"
                                    type="radio" name="job" value="investor" checked>
                                <label class="radioCustomLabel" for="typeInvestor"></label>
                            </div>
                            <div class="mrgntop15">
                                <div class="formRadioContainer">
                                    <i class="icns icon-type-inverstor findJobIcon"></i>
                                    <label class="radioCustomLabel" for="typeInvestor">
                                        {{'investor' | translate}}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="flex2 flex flexAlignItemsCenter flexDirectionCol itemBox">
                            <div class="formRadioContainer">
                                <input class="radioCustom" [(ngModel)]="formRequestData.profileType"
                                    id="typeBfsiCorporate" type="radio" name="job" value="bfsi corporate" checked>
                                <label class="radioCustomLabel" for="typeBfsiCorporate"></label>
                            </div>
                            <div class="mrgntop15">
                                <div class="formRadioContainer">
                                    <i class="icns icon-post-job postJobIcon"></i>
                                    <label class="radioCustomLabel" for="typeBfsiCorporate">
                                        {{'bfsi corporate' | translate}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="flex2 flex flexAlignItemsCenter flexDirectionCol itemBox">
                            <div class="formRadioContainer">
                                <input class="radioCustom" id="typeIndividual" [(ngModel)]="formRequestData.profileType"
                                    type="radio" name="job" value="individual">
                                <label class="radioCustomLabel" for="typeIndividual"></label>
                            </div>
                            <div class="mrgntop15">
                                <div class="formRadioContainer">
                                    <i class="icns icon-find-job findJobIcon"></i>
                                    <label class="radioCustomLabel" for="typeIndividual">
                                        {{'individual' | translate}}
                                    </label>
                                </div>
                            </div>
                        </div> -->
                    </div>

                </div>
            </div>
            <!-- step 1 end here -->

            <!-- step 2 start here -->
            <!-- <div class="width100 posRelative minHeight195px flex flexAlignItemsCenter flexDirectionCol flexJustifyStart"
                *ngIf="currentStep == 2">
                <div class="imHereTo lineHeight36px font22 ">{{'which defines you the best?' | translate}}</div>
                <div class=" width70 mrgntop15 minHeight120px padleft15 padright15">
                    <div class="flex width100">
                        <div class="flex1">
                            <i class="icns findJobIcon"
                                [ngClass]="{'icon-find-job': formRequestData.profileType == 'job', 'icon-post-job': formRequestData.profileType =='company'}"></i>
                        </div>
                        <div class="flex4 padleft15">
                            <div class="formRadioContainer">
                                <input class="radioCustom" [(ngModel)]="formRequestData.jobProfileType"
                                    id="startUpemployee" type="radio" name="whichDefinesYouBest"
                                    [value]="radioBtnValues[formRequestData.profileType].Fintech" checked>
                                <label class="radioCustomLabel" for="startUpemployee">
                                    {{ (formRequestData.profileType=='job' ? 'a fintech startup employee': 'fin tech startups')  | translate}}
                                </label>
                            </div>
                            <div class="formRadioContainer mrgntop20">
                                <input class="radioCustom" [(ngModel)]="formRequestData.jobProfileType" id="founder"
                                    type="radio" name="whichDefinesYouBest"
                                    [value]="radioBtnValues[formRequestData.profileType].Founder">
                                <label class="radioCustomLabel" for="founder">
                                    {{(formRequestData.profileType=='job' ? 'founder or co founder of a fintech startup': 'bfsi corporate') | translate}}
                                </label>
                            </div>
                            <div class="formRadioContainer mrgntop20">
                                <input class="radioCustom" [(ngModel)]="formRequestData.jobProfileType" id="none"
                                    type="radio" name="whichDefinesYouBest" value="None">
                                <label class="radioCustomLabel" for="none">
                                    {{'none of the above' | translate}}
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div> -->
            <!-- step 2 end here -->

            <!-- step 3 start here -->
            <!-- <div class="width100 minHeight195px padleft15 padright15 posRelative flex flexAlignItemsCenter flexJustifyStart"
                *ngIf="currentStep == 3">
                <div class="flex2 flex flexAlignItemsCenter flexJustifyCenter">
                    <i class="icns icon-message-sent messageSent "></i>
                </div>
                <div class="flex4">
                    <div class="messageHeader font24">{{'message header' | translate}}</div>
                    <div class="messageInfo lineHeight17px font14 mrgntop5">
                        {{"this platform is only serving fintech startup co founders and employees currently. however leave us with your email ID to keep you updated with our new services in the near future" | translate}}
                    </div>
                    <div class="mrgntop10 mrgnbtm10">
                        <form [formGroup]="notifyForm">
                            <div class="materialTextfield">
                                <input class="emailInput" placeholder=" " type="text" formControlName="email"
                                    [ngClass]="{'borderRed': f('email').invalid && (f('email').dirty || f('email').touched)}">
                                <label
                                    [ngClass]="{'redClr': f('email').invalid && (f('email').dirty || f('email').touched)}">{{'email' | translate}}</label>
                            </div>
                            <ng-container *ngIf="f('email').invalid && (f('email')?.dirty || f('email')?.touched)">
                                <div class="helpBlock" *ngIf="f('email').errors?.required">
                                    {{"email is required" | translate}}
                                </div>
                                <div class="helpBlock"
                                    *ngIf="f('email')?.errors?.email || f('email')?.errors.pattern?.requiredPattern">
                                    {{"invalid email" | translate}}
                                </div>
                            </ng-container>
                        </form>
                    </div>
                </div>
            </div> -->
            <!-- step 3 end here -->

            <div class="flex flexJustifyCenter">
                <!-- <button class="btn btn-back ripple" *ngIf="currentStep > 1" (click)="currentStep = currentStep - 1">
                    {{'back' | translate}}
                </button> -->
                <button class="btn btn-continue ripple" (click)="goToNext()">
                    {{ (currentStep == 3 ? 'keep me updated' : 'continue') | translate}}
                </button>
            </div>
            <img src="assets/images/foot_print.svg" class="footPrint">
            <img src="assets/images/find_job_footer.svg" class="findJobFooter">
        </div>
    </div>
</div>