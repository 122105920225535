<div id="confirmAccount" class="width100">
    <div class="pad10 header flex flexAlignItemsCenter flexJustifyCenter posRelative">
        <img src="assets/images/connex_door_logo.svg">
    </div>
    <div class="body alignCntr">
        <div class="height100p">
            <div class="width100  minHeight195px flex flexAlignItemsCenter flexDirectionCol flexJustifyStart">
                <div class="imHereTo lineHeight36px font22 mrgntop40">{{'Forgot your ConnexDoor account password' | translate}}</div>
                <div class=" width65 mrgntop15 minHeight120px padleft10 padright10">
                    <div class="flex ">
                        <form class="width100" [formGroup]="forgotPasswordForm">
                            <ng-container formGroupName="user">
                                <ng-container>
                                    <div class="width100 mrgnbtm15">
                                        <div class="materialTextfield width100 ">
                                            <input formControlName="username" [ngClass]="{'borderRed': isInvalidInput || (f('username')?.invalid && (f('username')?.dirty || f('username')?.touched)), 'disabled': isOtpShow}" class="width100" placeholder=" " type="text" appDebounce [debounceTime]="100"
                                                (debounceClick)="checkIsValidMobileOrEmail($event)">
                                            <label [ngClass]="{'redClr': isInvalidInput || (f('username')?.invalid && (f('username')?.dirty || f('username')?.touched))}">{{'e mail or mobile number' | translate}}</label>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="isOtpShow">
                                    <div class="mrgnbtm15 flex flexAlignItemsCenter ">
                                        <div class="flex2 flex flexAlignItemsCenter font13 otpSentMsg minHeight40px">
                                            {{ otpMessageString | translate }}
                                        </div>
                                        <div class="flex flexAlignItemsCenter minHeight40px" *ngIf="otpMessageString != 'otp successfully verified'">
                                            <div id="countdown" *ngIf="timer >= 1">
                                                <div id="countdown-number">{{timer}}</div>
                                                <svg>
                                                    <circle r="18" cx="20" cy="20"></circle>
                                                </svg>
                                            </div>
                                            <div *ngIf="timer == 0 && !(f('otp').valid && !isInvalidOtp)" class="otpSentMsg txtDecorationUnderline font13 mrgnleft10 cursorPtr" (click)="resendOtp()">
                                                {{'resend' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="width100 mrgnbtm15">
                                        <div class="materialTextfield width100 ">
                                            <input class="width100" placeholder=" " [ngClass]="{'borderRed': (f('otp')?.invalid && (f('otp')?.dirty || (f('otp')?.touched)) || !f('otp').errors?.required && isInvalidOtp),'disabled': submittedOtp}" formControlName="otp" type="text" appDebounce [debounceTime]="100"
                                                (debounceClick)="verifyOTP($event)" minlength="6" maxlength="6">
                                            <label [ngClass]="{'redClr': (f('otp')?.invalid && (f('otp')?.dirty || f('otp')?.touched)) || !f('otp').errors?.required && isInvalidOtp}">{{'enter otp recieved' | translate}}</label>
                                        </div>
                                        <ng-container *ngIf="(f('otp').invalid && (f('otp').dirty || f('otp').touched) ) || isInvalidOtp">
                                            <div class="helpBlock" *ngIf="f('otp').errors?.required">
                                                {{"this field is required" | translate}}
                                            </div>
                                            <div class="helpBlock" *ngIf="isInvalidOtp && !f('otp').errors?.required">
                                                {{"invalid otp" | translate}}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="pwdEnterVisible">
                                        <div class="width100 mrgnbtm15">
                                            <div class="materialTextfield width100  passwordEyeContainer">
                                                <input autocomplete="new-password" [ngClass]="{'borderRed':(f('password')?.invalid && (f('password')?.dirty || (f('password')?.touched))),'disabled': f('otp')?.invalid || isInvalidOtp}" formControlName="password" id="password" class="width100" placeholder=" "
                                                    type="{{passwordType}}">
                                                <label [ngClass]="{'redClr':(f('password')?.invalid && (f('password')?.dirty || (f('password')?.touched)))}">{{'New Password' | translate}}</label>
                                                <div class="iconRound passwordEye ripple">
                                                    <i class="icns d20 icon-visibiliy" (click)="toggleEye()" [ngClass]="{'icon-visibiliy-off': passwordType == 'password', 'icon-visibiliy': passwordType == 'text'}"></i>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="(f('password').invalid && (f('password').dirty || f('password').touched) )">
                                                <div class="helpBlock" *ngIf="f('password').errors?.required">
                                                    {{"this field is required" | translate}}
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="width100 mrgnbtm15">
                                            <div class="materialTextfield width100  passwordEyeContainer">
                                                <input autocomplete="new-password" [ngClass]="{'borderRed':(f('password_confirmation')?.invalid && (f('password_confirmation')?.dirty || (f('password_confirmation')?.touched))),'disabled': f('otp')?.invalid || isInvalidOtp}" formControlName="password_confirmation"
                                                    id="password" class="width100" placeholder=" " appDebounce [debounceTime]="100" (debounceClick)="mustMatchValid($event)" type="{{passwordType}}">
                                                <label [ngClass]="{'redClr':(f('password_confirmation')?.invalid && (f('password_confirmation')?.dirty || (f('password_confirmation')?.touched)))}">{{'Confirm Password' | translate}}</label>
                                                <div class="iconRound passwordEye ripple">
                                                    <i class="icns d20 icon-visibiliy" (click)="toggleEye()" [ngClass]="{'icon-visibiliy-off': passwordType == 'password', 'icon-visibiliy': passwordType == 'text'}"></i>
                                                </div>

                                            </div>
                                            <ng-container *ngIf="(f('password_confirmation').invalid && (f('password_confirmation').dirty || f('password_confirmation').touched) ) ">
                                                <div class="helpBlock" *ngIf="f('password_confirmation').errors?.required">
                                                    {{"this field is required" | translate}}
                                                </div>
                                                <div class="helpBlock" *ngIf="f('password_confirmation').errors?.mustMatch">Passwords must match</div>
                                            </ng-container>

                                        </div>

                                    </div>
                                </ng-container>

                                <div class="flex flexJustifyCenter pad10">
                                    <button type="button" class="btn btn-continue ripple signUp" [ngClass]="{'disabled': forgotPasswordForm.invalid || isInvalidInput}" (click)="submitForm()">
                                        {{ (isOtpShow ? 'Reset Password' : 'next') | translate}}
                                    </button>

                                </div>
                            </ng-container>
                        </form>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>