import { Injectable } from '@angular/core';
import { CanActivate, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class IsloggedinGaurd implements CanActivate {

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

   /* Setting up the Auth guard by the user status */
   canActivate(){
    if(this.appService.isLoggedIn()){
      return true;
    } else {
      this.router.navigate(['/home'])
      this.appService.togglePopup({
        type: 'signIn',
        load: true,
        refreshParent: true,
        overlayClose: true,
        context: {}
      });
    }
  }
}