<div id="signUpPopup" class="bg-white width100">
    <div class="pad10 header flex flexAlignItemsCenter flexJustifyCenter posRelative">
        <img src="assets/images/connex_door_logo.svg">
        <div class="iconClose ripple cursorPtr" (click)="closePopup()">
            <i class="icns icon-close d15"></i>
        </div>
    </div>
    <div class="body">
        <div class="height100p">
            <div class="messageHeader font24 padtop15 txtCenter padbtm30">{{'have a connexDoor account' | translate}}
            </div>
            <div class="flex flexResponsive padbtm15">
                <div class="width55 signUpFormContainer flex flexJustifyCenter">
                    <form class="width73" [formGroup]="signUpForm">
                        <ng-container formGroupName="user_profile">
                            <div class="flex width100 flexResponsive flexJustifyBetween">
                                <div class="width48 mrgnbtm15">
                                    <div class="materialTextfield width100 ">
                                        <input formControlName="first_name" [ngClass]="{'disabled': isOtpShow, 'borderRed': f('first_name')?.invalid && (f('first_name')?.dirty || f('first_name')?.touched)}" class="width100" placeholder=" " type="text">
                                        <label [ngClass]="{'redClr': f('first_name')?.invalid && (f('first_name')?.dirty || f('first_name')?.touched)}">{{'name' | translate}}</label>
                                    </div>
                                    <ng-container *ngIf="f('first_name').invalid && (f('first_name').dirty || f('first_name').touched)">
                                        <div class="helpBlock" *ngIf="f('first_name').errors?.required">
                                            {{"this field is required" | translate}}
                                        </div>
                                    </ng-container>

                                </div>
                                <div class="width48 mrgnbtm15">
                                    <div class="materialTextfield width100 ">
                                        <input formControlName="last_name" class="width100" [ngClass]="{'disabled': isOtpShow, 'borderRed': f('last_name')?.invalid && (f('last_name')?.dirty || f('last_name')?.touched)}" placeholder=" " type="text">
                                        <label [ngClass]="{'redClr': f('last_name')?.invalid && (f('last_name')?.dirty || f('last_name')?.touched)}">{{'surname' | translate}}</label>
                                    </div>
                                    <ng-container *ngIf="f('last_name').invalid && (f('last_name').dirty || f('last_name').touched)">
                                        <div class="helpBlock" *ngIf="f('last_name').errors?.required">
                                            {{"this field is required" | translate}}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="width100 mrgnbtm15">
                                <div class="materialTextfield width100">
                                    <input formControlName="email_or_phone_no" [ngClass]="{'borderRed': isInvalidInput || (f('email_or_phone_no')?.invalid && (f('email_or_phone_no')?.dirty || f('email_or_phone_no')?.touched)), 'disabled': isOtpShow}" class="width100" placeholder=" " type="text"
                                        appDebounce [debounceTime]="100" (debounceClick)="checkIsValidMobileOrEmail($event)">
                                    <label [ngClass]="{'redClr': isInvalidInput || (f('email_or_phone_no')?.invalid && (f('email_or_phone_no')?.dirty || f('email_or_phone_no')?.touched))}">{{'e mail or mobile number' | translate}}</label>
                                </div>
                                <ng-container *ngIf="f('email_or_phone_no')?.touched || f('email_or_phone_no')?.dirty">
                                    <div class="helpBlock" *ngIf="f('email_or_phone_no')?.errors?.required">
                                        {{"this field is required" | translate}}
                                    </div>
                                    <div class="helpBlock" *ngIf="isInvalidInput && !f('email_or_phone_no').errors?.required">
                                        {{"required valid mobile no or email id" | translate}}
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="isOtpShow">
                                <div class="mrgnbtm15 flex flexAlignItemsCenter ">
                                    <div class="flex2 flex flexAlignItemsCenter font13 otpSentMsg minHeight40px">
                                        {{ otpMessageString | translate }}
                                    </div>
                                    <div class="flex flexAlignItemsCenter minHeight40px" *ngIf="otpMessageString != 'otp successfully verified'">
                                        <div id="countdown" *ngIf="timer > 1">
                                            <div id="countdown-number">{{timer}}</div>
                                            <svg>
                                                <circle r="18" cx="20" cy="20"></circle>
                                            </svg>
                                        </div>
                                        <div class="otpSentMsg txtDecorationUnderline font13 mrgnleft10 cursorPtr" (click)="resendOtp()" *ngIf="timer == 0">
                                            {{'resend' | translate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="width100 mrgnbtm15">
                                    <div class="materialTextfield width100 ">
                                        <input class="width100" placeholder=" " [ngClass]="{'borderRed': (f('otp')?.invalid && (f('otp')?.dirty || f('otp')?.touched)) || !f('otp').errors?.required && isInvalidOtp, 'disabled': f('otp').valid && !isInvalidOtp}" formControlName="otp" type="text"
                                            appDebounce [debounceTime]="100" (debounceClick)="verifyOTP($event)">
                                        <label [ngClass]="{'redClr': (f('otp')?.invalid && (f('otp')?.dirty || f('otp')?.touched)) || !f('otp').errors?.required && isInvalidOtp}">{{'enter otp recieved' | translate}}</label>
                                    </div>
                                    <ng-container *ngIf="(f('otp').invalid && (f('otp').dirty || f('otp').touched) ) || isInvalidOtp">
                                        <div class="helpBlock" *ngIf="f('otp').errors?.required">
                                            {{"this field is required" | translate}}
                                        </div>
                                        <div class="helpBlock" *ngIf="isInvalidOtp && !f('otp').errors?.required">
                                            {{"invalid otp" | translate}}
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="width100 mrgnbtm20">
                                    <div class="materialTextfield width100  passwordEyeContainer">
                                        <input autocomplete="new-password" [ngClass]="{'disabled': f('otp')?.invalid || isInvalidOtp, 'borderRed': f('password')?.invalid && (f('password')?.dirty || f('password')?.touched)}" formControlName="password" id="password" class="width100" placeholder=" "
                                            type="{{passwordType}}">
                                        <label [ngClass]="{'redClr': f('password')?.invalid && (f('password')?.dirty || f('password')?.touched)}">{{'password' | translate}}</label>
                                        <div class="iconRound passwordEye ripple">
                                            <i class="icns d20 icon-visibiliy" (click)="toggleEye()" [ngClass]="{'icon-visibiliy-off': passwordType == 'password', 'icon-visibiliy': passwordType == 'text'}"></i>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="f('password').invalid && (f('password').dirty || f('password').touched)">
                                        <div class="helpBlock" *ngIf="f('password').errors?.required">
                                            {{"this field is required" | translate}}
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="flex flexJustifyCenter">
                                <button *ngIf="isOtpShow" class="btn btn-continue mrr" (click)="cancel()">
                                    Cancel
                                </button>
                                <button type="button" class="btn btn-continue ripple signUp " [ngClass]="{'disabled': signUpForm.invalid}" (click)="submitForm()">
                                    {{ (isOtpShow ? 'sign up' : 'request otp') | translate}}
                                </button>
                            </div>
                        </ng-container>
                        <div class="policyInfo font12 width85 txtCenter" [innerHTML]="'By signing up, I agree to connexdoor terms of services and privacy policy' | translate">
                        </div>
                    </form>
                </div>
                <div class="width45 socialSignInMainContainer flex flexDirectionCol flexAlignItemsCenter">
                    <img src="assets/images/sign_with_linkedin.svg" class="mrgnbtm15 cursorPtr padtop30" (click)="socialSignIn('linkedin')">
                    <!-- <img src="assets/images/sign_with_facebook.svg" class="mrgnbtm15 cursorPtr" (click)="socialSignIn('facebook')">
                    <img src="assets/images/sign_with_google.svg" class="mrgnbtm15 cursorPtr" (click)="socialSignIn('google')"> -->
                </div>

            </div>
            <div class="flex flexJustifyCenter flexAlignItemsCenter padbtm30 padtop30">
                <div class="messageHeader font18  txtCenter">{{'have a connexDoor account' | translate}}
                </div>
                <div class="font18 signInLink mrgnleft10 cursorPtr" (click)="signIn()">
                    {{'sign in' | translate}}
                </div>
            </div>
        </div>

    </div>
</div>