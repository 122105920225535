<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap" rel="stylesheet">
<div class="fintech-menu">
    <div class="width100">
        <div class="flex flexResponsive width100 flexAlignItemsCenter">
            <div class="width100 flex flexResponsive flexJustifyStart flexAlignItemsCenter robo">
                <div class="mx-auto robo">
                    <span [ngClass]="{'active' : currentTab == 'Finsip'}" class="displayInline robo  menu-item  txtTransformUp color707070 font15  cursorPtr" [routerLinkActive]="'current'" [routerLink]="['/fintecastic-women']">{{ 'FinteCastic Women' | translate }}</span>
                    <span [ngClass]="{'active' :currentTab == 'Fintech Cafe'}" class="displayInline robo txtTransformUp color707070 font15  cursorPtr" [routerLinkActive]="'current'" [routerLink]="['/fintech-cafe']">{{ 'Fintech Cafe' | translate }}</span>

                </div>
            </div>
        </div>
    </div>
</div>

<section id="fintecCafe">

    <div class="headSec">
        <div class="width80 mrgnauto">
            <div class="flex flexResponsive">
                <div class="width50">
                    <div class="headTxt padtop50">FINTECH CAFE </div>
                    <div class="subTxt txtTransformUp">Let’s Talk Fintech!</div>
                    <div class="bodyTxt padtop30 padright20 robo">This forum allows the founders to talk about their idea with the mentors in our ecosystem. We have mentors from across the fintech domains, who will provide you with honest feedback in this candid chat with some chai or coffee!</div>
                    <div class="font16 ylw padtop25 fontWeight500 robo">Back2School - an initiative to promote entrepreneurship<br>in the Working Professional/Student community.</div>
                    <div class="flex padtop50 padbtm30">
                        <div class="button-container">
                            <button class="font16 padtop15 padbottom15 btn btn-job ripple btn btn-continue bdrRadius50px" (click)="navigateForm()">REGISTER NOW</button>
                        </div>
                    </div>
                </div>
                <div class="width50">
                    <img src="assets/images/cafe.png" />
                </div>
            </div>
        </div>
    </div>
    <div class="width80 padtop20 mrgnauto">

        <div class="head font24 fontWeight700 txtTransformUp txtCenter padtop20 padbtm5 color-3a3a3a">What’s in it for a Fintech Founder?</div>
        <div class="bodyTxt txtCenter padbtm30">1:1 Mentoring session for fintech founders with<br>Subject Matter Experts.</div>
        <div class="flex flexResponsive">
            <div class="width32 finCard pad40">
                <div class="padbtm20"><img src="assets/images/feed.png" /></div>
                <div class="head font20 fontWeight700 padbtm10 color-EAB328">Unbiased Feedback</div>
                <div class="bodyTxt padbtm35 robo">We will arrange your conversation with the best of the Fintech domain experts to ensure honest, yet a knowledgeable scoop of advice!</div>
                <!-- <div class="padbtm20"><a href="#" class="font16 fontWeight700">Learn More</a></div> -->
            </div>
            <div class="width2">&nbsp;</div>
            <div class="width32 finCard pad40">
                <div class="padbtm20"><img src="assets/images/chat.png" /></div>
                <div class="head font20 fontWeight700 padbtm10 color-EAB328">Candid Chat</div>
                <div class="bodyTxt padbtm20 robo">These conversations are not extremely formal, you can speak your heart out and we assure the same.</div>
                <!-- <div class="padbtm20"><a href="#" class="font16 fontWeight700">Learn More</a></div> -->
            </div>
            <div class="width2">&nbsp;</div>
            <div class="width32 finCard pad40">
                <div class="padbtm20"><img src="assets/images/connect.png" /></div>
                <div class="head font20 fontWeight700 padbtm10 color-EAB328">Connect with BFSI Network</div>
                <div class="bodyTxt padbtm5 robo">We will help you get in touch with The Fintech Meetup’s BFSI network, thereby providing a better and efficient reach for partnerships and business development.</div>
                <!-- <div class="padbtm20"><a href="#" class="font16 fontWeight700">Learn More</a></div> -->
            </div>

        </div>

        <div class="flex flexResponsive flexAlignItemsCenter padtop70 color707070">
            <div class="font24 fontWeight700 color-3a3a3a padright20 txtRight width45">Recent Happenings</div>
            <div class="padleft20 bdrRgt5px">
                <div class="bodyTxt padbtm10 padtop5 robo">We've conversed with 80+ Fintech startup founders till date, & it gives us immense pleasure to confirm that we've funded 1 startup through the efforts of The Fintech Cafe & Back2School initiatives. Searching for the next best 4!</div>
                <!-- <div class="flex padbtm10 flexResponsive">
                    <div class="width30 flex flexAlignItemsCenter">
                        <img src="assets/images/cal-icon.png" class="padright20" />
                        <div class="font16 color-7a7a7a">26th March 2021</div>
                    </div>
                    <div class="width30 flex flexAlignItemsCenter">
                        <img src="assets/images/clock-icon.png" class="padright20" />
                        <div class="font16 color-7a7a7a">3:30 PM to 4:30 PM.</div>
                    </div>
                    <div class="width40 flex flexAlignItemsCenter">
                        <img src="assets/images/pin.png" class="padright20" />
                        <div class="font16 color-7a7a7a">Zoom / Powai Chaayos</div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="head font24 fontWeight600 txtTransformUp txtCenter padtop70 padbtm15 color-3a3a3a posRelative">
            Speakers
            <div class="posAbsolute posTop posRight padtop60">
                <img src="assets/images/left-arrw.png" (click)="scrollLeft()" class="padright20" />
                <img src="assets/images/right-arrw.png" (click)="scrollRight()" />
            </div>

        </div>
        <div class="flex flexResponsive padbtm50">

            <div #scrollBox class="scrollBox">

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/AbhishantPant.jpg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Abhishant Pant</span> <br>Founder<br><span class="bodyTxt">The Fintech Meetup</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Sayali.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Sayali Karanjkar</span> <br>Fintech Entrepreneur<br><span class="bodyTxt">Angel Investor & Advisor</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Madhusudanan.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Madhusudanan R</span> <br>Co-Founder<br><span class="bodyTxt">YAP</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Jose.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Jose Thatill</span> <br>Co-Founder<br><span class="bodyTxt">PhiCommerce</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Chiranth.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Chiranth Patil</span> <br>Co-Founder<br><span class="bodyTxt">Riskcovry</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/Parijat.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Parijat Garg</span> <br>Consultant<br><span class="bodyTxt">Digital Lending</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/asutosh.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Asutosh Upadhyay</span> <br>Investment Professional<br><span class="bodyTxt">Axilor Ventures</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/pranshu.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Pranshu Diwan</span> <br>Director Head of Insurance<br><span class="bodyTxt">Ola Financial Service</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/nishant.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Nishant Jaspara</span> <br>Head - Digital Business<br><span class="bodyTxt">Fullerton India</span></div>
                </div>

                <div class="spCard flexAlignItemsCenter pad40">
                    <div class="flex flexJustifyCenter">
                        <div class="imgB">
                            <img src="assets/images/ajay.jpeg" class="icon d100 bdrRadius50p" />
                        </div>
                    </div>
                    <div class="padleft20 txtCenter padtop20"><span class="fontWeight700 color-319966 font16">Ajay Desai</span> <br>Co-Founder<br><span class="bodyTxt">Saakav Finserv</span></div>
                </div>

            </div>

        </div>

    </div>

    <div class="fq-sec padtop30 padbtm30 mrgnbtm50" robo>
        <div class="width80 mrgnauto">
            <div class="flex flexResponsive flexAlignItemsCenter">
                <div class="width50 robo">
                    <div class="font30 color-3a3a3a padtop30 padbtm30">Frequently Asked Questions</div>
                    <div class='faq'>
                        <input id='faq-a' type='checkbox'>
                        <label for='faq-a'>
                    <p class="faq-heading">How to Participate?</p>
                    <div class='faq-arrow'></div>
                      <p class="faq-text">Register on the link above, you will receive a call where you need to opt between a zoom call or physical meet in Mumbai. Post that, your time slot will be booked and you will receive a mail along with the invite link in case of a zoom call.</p>
                  </label>
                        <input id='faq-b' type='checkbox'>
                        <label for='faq-b'>
                    <p class="faq-heading">Who can participate?</p>
                    <div class='faq-arrow'></div>
                      <p class="faq-text">Any Fintech founder who is actively pursuing the idea and has at least Prototype or MVP of the product / service ready. Incorporation is not necessary.</p>
                  </label>
                        <input id='faq-c' type='checkbox'>
                        <label for='faq-c'>
                    <p class="faq-heading">I am a founder, but not in the Fintech space, can I apply?</p>
                    <div class='faq-arrow'></div>
                      <p class="faq-text">Unfortunately, we only have conversations with Fintech founders.</p>
                  </label>
                        <input id='faq-d' type='checkbox'>
                        <label for='faq-d'>
                    <p class="faq-heading">Do I have to pay anything to participate?</p>
                    <div class='faq-arrow'></div>
                      <p class="faq-text">No, this conversation is completely free. </p>
                  </label>
                        <input id='faq-e' type='checkbox'>
                        <label for='faq-e'>
                    <p class="faq-heading">How long will the session be?</p>
                    <div class='faq-arrow'></div>
                      <p class="faq-text">One session will typically last for anywhere between 60-90 minutes.</p>
                  </label>
                        <input id='settings' type='checkbox'>
                        <input id='faq-f' type='checkbox'>
                        <label for='faq-f'>
                    <p class="faq-heading">Is there any deadline to apply for the program?</p>
                    <div class='faq-arrow'></div>
                      <p class="faq-text">No. It’s an open application, throughout the year. You can apply any time.</p>
                  </label>
                    </div>
                    <div class=" pad20 font16 color-3a3a3a padbtm40"> Any other queries? Reach us out at <a href="mailto:info@connexdoor.com" style="text-decoration: underline !important;font-weight: bolder !important;">info@connexdoor.com</a></div>
                </div>
                <div class="width50 pad20">
                    <img src="assets/images/fq.png" />
                </div>
            </div>
        </div>
    </div>

</section>