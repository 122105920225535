<div class="flex flexResponsive mrgntop40">
    <ng-container *ngFor="let item of cardData; let i= index">
        <div class="flex3 pad10" *ngIf="i<5" (click)="onJobView(item?.title, item?.id)" >
            <div class="card-container">
                <div class="flex pad15" style="min-height: 80px;">
                    <div class="flex2 mrgnright10">
                        <img *ngIf="item?.company_profile?.logo" src="{{item?.company_profile?.logo}}" class="d80">
                    </div>
                    <div class="flex5">
                        <p class=" mrgn0 circular-bold font16 colorBlack">{{item?.company_profile?.name}}</p>
                        <p class="flex roboto-regular font13 coloraaaaaa padbtm15 mrgn0 flexAlignItemsCenter">
                            <img src="assets/images/location.svg" class="padright5 d20">{{item?.company_profile?.location?.name}}</p>
                        <p class="font11 mrgn0">{{item?.posted_on | dateAgo }}</p>
                    </div>
                </div>
                <div class=" bottom-card pad15" style="min-height: 98px;z-index: 100;">
                    <p class="circular-bold font14 colorBlack mrgn0">{{item?.title}}</p>
                    <div class="img-tag-container">
                        <span class="tag">
                            <span>{{item?.job_title?.title}}</span>
                        </span>
                    </div>
                    <div class="tag-container">
                        <ng-container *ngFor="let data of item?.skills; let i= index">
                        <span class="tag" *ngIf="i <= 3">
                            <i>{{data?.name}}</i>
                        </span>
                    </ng-container>
                        <span *ngIf="item?.skills.length > 3">...</span>
                    </div>
                </div>
                
            </div>
            <div class="flex flexJustifyBetween">
                <div>
                    <img class="mrgnleftM70p mrgntopM50p" src="assets/images/group_1.svg" style="opacity: 0.1;">
                </div>
            </div>
        </div>
        
    </ng-container>


</div>

