<div *ngIf="loaderService.isLoading | async" >
  <div class="app-loading">
      <div class="logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="0.5" stroke-miterlimit="10"/>
      </svg> 
      <!-- <div class="LoaderBalls">
        <div class="LoaderBalls__item"></div>
        <div class="LoaderBalls__item"></div>
        <div class="LoaderBalls__item"></div>
      </div> -->
  </div>
</div> 

