import { UserProfileModel } from './user.profile.model';
import { ProfileDropDownModel } from './profile-form.model';
import { PermissionModel } from './permission.model';

export class UserModel {
    authentication_token ?: string = '';
    id ?: number = 0;
    user_profile ?: UserProfileModel = new UserProfileModel();
    username ?: string = '';
    updated_at ?: string = '';
    email ?: string = '';
    created_at ?: string = '';
    user_profile_id ?: string = '';
};


export class UserPermissionModel {
    user ?: UserModel = new UserModel();
    permissions ?: PermissionModel = new PermissionModel();
    profile_completion ?: number = 0;
}

export class CreateProfileFormModel {
    location : ProfileDropDownModel = new ProfileDropDownModel();
    company : ProfileDropDownModel = new ProfileDropDownModel();
    jobTitle : ProfileDropDownModel = new ProfileDropDownModel();
    domainExpert : ProfileDropDownModel = new ProfileDropDownModel();
}

export class BasicInfoFormModel {
    location : ProfileDropDownModel = new ProfileDropDownModel();
    domainExpert : ProfileDropDownModel = new ProfileDropDownModel();
    role: ProfileDropDownModel = new ProfileDropDownModel();
    skills: ProfileDropDownModel = new ProfileDropDownModel();

}
export class JobDescriptionFormModel {
    jobTitle : ProfileDropDownModel = new ProfileDropDownModel();
    location : ProfileDropDownModel = new ProfileDropDownModel();
    skills: ProfileDropDownModel = new ProfileDropDownModel();

}