import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import { CreateProfileFormModel, BasicInfoFormModel, EducationModel, ExperienceModel, ListingModel, UserProfileModel } from '../../../../core/models';
import * as _ from 'lodash';
import { JobPostedListModel } from '../../../../core/models/jobposted.model';
import { JobApplicantsModel } from '../../../../core/models/job-applicants-model';
import { CompanyProfileModel, JobsListedCompanyProfileModel, CompanyDetailModel } from '../../../../core/models/company.model';




@Injectable({
    providedIn: 'root'
})

export class JobPostedStore {
    @observable apiResp: any;
    @observable jobApplicants = new JobApplicantsModel();
    @observable jobPostedList: JobPostedListModel[] = []
    @observable jobPostedDetail= new JobPostedListModel();
    @observable companyList: CompanyDetailModel[] = [];
    constructor() { }
    
    /* job Posted list page */
    @action jobPostedListPage() {
        this.jobPostedList = this.apiResp[0];
        this.companyList = this.apiResp[1].company_profiles;
    }

    /* job Posted list api */
    @action jopPostedList() {
        this.jobPostedList = this.apiResp;
    }

    @action getJobPostedDetail() {
        this.jobPostedDetail= this.apiResp;
    }

    /* job application info */
    @action jobApplicantsInformation() {
        this.jobApplicants = this.apiResp;
    }
}
