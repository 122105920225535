import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { AppStore } from 'src/app/core/stores/app.store';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, OnDestroy {
  @Output() public refreshPage = new EventEmitter();
  constructor(
    public appStore: AppStore,
    public appService: AppService,

  ) { }

  /* Close Popup Parent Method */
  public closePopup(close_type ?: string){
    if(this.appStore.popup.refreshParent){
      this.refreshPage.emit(close_type);
    }
    switch (close_type) {
      case 'overlay':
        if(this.appStore.popup.overlayClose){
          this.appStore.popup.load = false;
        }
        break;
      default:
        this.appStore.popup.load = false;
        break;
    }
    document.body.classList.remove('overflowHidden');
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    document.body.classList.remove('overflowHidden');

  }

}
