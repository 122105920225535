import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import * as _ from 'lodash';
import { JobApplicantsModel } from '../../../../core/models/job-applicants-model';
import { JobPostingDetailModel } from 'src/app/core/models';




@Injectable({
    providedIn: 'root'
})

export class JobAppliedStore {
    @observable apiResp: any;
    @observable jobAppliedList: JobApplicantsModel[] = []
    @observable jobDetail:JobPostingDetailModel = new JobPostingDetailModel();
    constructor() { }
    
    @action getJobAppliedList() {
        this.jobAppliedList = this.apiResp;
    }
    @action getJobDetail(){
        this.jobDetail = this.apiResp;
    }
}
