import { Component, Input, Output, EventEmitter, OnDestroy, SimpleChanges } from '@angular/core';
import { PopupModel } from 'src/app/core/models';
import { FormBuilder, Validators } from '@angular/forms';
import { AppService } from '../../../../core/services/app.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-find-or-post-job-popup',
  templateUrl: './find-or-post-job-popup.component.html',
  styleUrls: ['./find-or-post-job-popup.component.scss']
})
export class FindOrPostJobPopupComponent implements OnDestroy {
  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  subscription: Subscription = new Subscription();
  formRequestData: any = {
    profileType: "fintech startup",
    jobProfileType: ""
  };


  signUpTypes:any = [{
    id: "fintechStartup",
    value: "Startup",
    name: "fintech startup",
    icon: "icon-type-startup"
  },{
    id: "investor",
    value: "Investor",
    name: "investor",
    icon: "icon-type-inverstor"
  },{
    id: "bfsiCorporate",
    value: "Corporate",
    name: "bfsi corporate",
    icon: "icon-post-job"
  },{
    id: "individual",
    value: "Individual",
    name: "individual",
    icon: "icon-find-job"
  }]

  radioBtnValues: any = {
    "job": {
      "Fintech": "Fin-tech startup employee",
      "Founder": "Co-Founder/Founder of fin-tech startup"
    },
    "company": {
      "Fintech": "Fin-tech startup",
      "Founder": "BFSI Corporate"
    }
  }

  notifyForm: any;
  currentStep: number = 1;
  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService
  ) {
    // this.notifyForm = formBuilder.group({
    //   "email": ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    // });
  }

  // /* form controls returns */
  // f(controlName?: any) {
  //   return this.notifyForm.get(controlName)
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.popup.currentValue && this.popup.context.profileType) {
      this.formRequestData['profileType'] = this.popup.context.profileType;
      this.goToNext();
    }
  }

  /* next button CTA */
  goToNext() {
    switch (this.currentStep) {
      case 1:
        // this.formRequestData['jobProfileType'] = this.radioBtnValues[this.formRequestData['profileType']].Fintech;
        this.formRequestData['jobProfileType'] = this.formRequestData['profileType'];
        this.appService.togglePopup({
          type: 'signUp',
          load: true,
          refreshParent: true,
          overlayClose: true,
          context: {
            job_profile_type: this.formRequestData['jobProfileType']
          }
        });
        console.log("======================================")
        this.currentStep += 1;
        break;
      case 2:
        // if (this.formRequestData.jobProfileType == 'None')
        //   this.currentStep += 1;
        // else
          // this.appService.togglePopup({
          //   type: 'signUp',
          //   load: true,
          //   refreshParent: true,
          //   overlayClose: true,
          //   context: {
          //     job_profile_type: this.formRequestData['jobProfileType']
          //   }
          // });
        break;
      case 3:
        // const param = {
        //   email: this.notifyForm.value.email,
        //   profile_type: this.formRequestData.profileType
        // }
        // this.subscription.add(
        //   this.appService.createLead(param).subscribe(response => {
        //     this.appService.toggleToast(
        //       {
        //         type: 'success',
        //         load: true,
        //         position: 'bottom-center',
        //         context: { description: `Updates will be send to ${this.notifyForm.value.email}` }
        //       }
        //     );
        //     this.close.emit('icon');
        //   })
        // )
      default:
        break;
    }

  }

  /* Close Popup Method */
  closePopup() {
    this.close.emit('icon');
  }

  /* ng destroy life cycle */
  ngOnDestroy(): void {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

}
