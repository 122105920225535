import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

/* External Libs Start Here */
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ImageCropperModule } from 'ngx-image-cropper';


/* External Libs End Here */

/* External Directive Start Here */
import { DebounceDirective } from '../core/directives/debounce.directive';
import { InfiniteScrollerDirective } from '../core/directives/infinite-scroller.directive';
/* External Directive End Here */

/* Customised pipe start here */
import { SearchPipe } from './pipes/search.pipe';
import { DatemonthPipe } from './pipes/date.month.year';

/* Customised pipe end here */

/* Shared Components Start Here */
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/shared-headers/header/header.component';
import { StartupHeaderComponent } from './components/shared-headers/startup-header/startup-header.component';
import { FindOrPostJobPopupComponent } from './components/popup/find-or-post-job-popup/find-or-post-job-popup.component';
import { PopupComponent } from './components/popup/popup.component';
import { FooterComponent } from './components/footer/footer.component';
import { SignupPopupComponent } from './components/popup/signup-popup/signup-popup.component';
import { ForgotPasswordPopupComponent } from './components/popup/forgot-password-popup/forgot-password-popup.component';
import { SigninPopupComponent } from './components/popup/signin-popup/signin-popup.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ExperienceCardComponent } from './components/card/experience-card/experience-card.component';
import { EducationCardComponent } from './components/card/education-card/education-card.component';
import { PageNotFoundComponent } from '../connexdoor/components/page-not-found/page-not-found.component';
import { DropdownSelectComponent } from './components/profile-forms/dropdown-select/dropdown-select.component';
import { DropdownMultiselectComponent } from './components/profile-forms/dropdown-multiselect/dropdown-multiselect.component';
import { LandingCardComponent } from './components/card/landing-card/landing-card.component';
import { ProfileLeftmenuComponent } from './components/profile-leftmenu/profile-leftmenu.component';
import { MediaCardComponent } from './components/card/media-card/media-card.component';
import { FundingCardComponent } from './components/card/funding-card/funding-card.component';
import { PeopleCardComponent } from './components/card/people-card/people-card.component';
import { FoundersCardComponent } from './components/card/founders-card/founders-card.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { JobDescriptionSuccessPopupComponent } from './components/popup/job-description-success-popup/job-description-success-popup.component';
import { LinkedinloginComponent } from './components/linkedinlogin/linkedinlogin.component';
import { ApplyJobComponent } from './components/popup/apply-job/apply-job.component';
import { ApplyJobSuccessComponent } from './components/popup/apply-job-success/apply-job-success.component';
import { DeleteStartupPopupComponent } from './components/popup/delete-startup-popup/delete-startup-popup.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DashboardHeaderComponent } from './components/shared-headers/dashboard-header/dashboard-header.component';
import { MenuComponent } from './components/popup/menu/menu.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationWithActionComponent } from './components/notification/notification-with-action/notification-with-action.component';
import { InactivityComponent } from './components/popup/inactivity/inactivity.component';
import { ErrorComponent } from './components/popup/error/error.component';
import { NoInternetComponent } from './components/popup/no-internet/no-internet.component';
import { IncompatibleComponent } from './components/popup/incompatible/incompatible.component';
import { BulkUploadErrorPopupComponent } from './components/popup/bulk-upload-error-popup/bulk-upload-error-popup.component';
import { InviteadminComponent } from './components/popup/inviteadmin/inviteadmin.component';
import { BulkUploadPopupComponent } from './components/popup/bulk-upload-popup/bulk-upload-popup.component';
import { MystartupCardComponent } from './components/card/mystartup-card/mystartup-card.component';
import { JobCardComponent } from './components/card/job-card/job-card.component';
import { DashboardJobCardComponent } from './components/card/dashboard-job-card/dashboard-job-card.component';
import { StartupCardComponent } from './components/card/startup-card/startup-card.component';
import { InvestorCardComponent } from './components/card/investor-card/investor-card.component';
import { PastInvestmentCardComponent } from './components/card/past-investment-card/past-investment-card.component';
import { CorporateHeaderComponent } from './components/shared-headers/corporate-header/corporate-header.component';
import { UserProfileCardComponent } from './components/card/user-profile-card/user-profile-card.component';
import { InvestorHeaderComponent } from './components/shared-headers/investor-header/investor-header.component';
import { FinshipCardComponent } from './components/card/finship-card/finship-card.component';
import { FilterDropdownComponent } from './components/profile-forms/filter-dropdown/filter-dropdown.component';
import { EmptyScreenCardComponent } from './components/empty-screen-card/empty-screen-card.component';
import { ConnectButtonComponent } from './components/button/connect-button/connect-button.component';
import { ProblemStatementPopupComponent } from './components/popup/problem-statement-popup/problem-statement-popup.component';
import { RefreshParentComponent } from './components/popup/refresh-parent/refresh-parent.component';
import { PreviewJdPopupComponent } from './components/popup/preview-jd-popup/preview-jd-popup.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareModule } from 'ngx-sharebuttons';
import { ImageCropComponent } from './components/popup/image-crop/image-crop.component';
import { ResearchReportCardComponent } from './components/card/research-report-card/research-report-card.component';
/* Shared Components End Here */



/* Initializing and Exporting the Translate Loader as Factory */
export function TranslatorFactory(httpClient: HttpClient) { return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json'); }

@NgModule({
  declarations: [
    HeaderComponent,
    LoaderComponent,
    FindOrPostJobPopupComponent,
    PageNotFoundComponent,
    PopupComponent, FooterComponent,
    LoaderComponent,
    FindOrPostJobPopupComponent,
    PopupComponent,
    SignupPopupComponent,
    ForgotPasswordPopupComponent,
    SigninPopupComponent,
    ToasterComponent,
    DebounceDirective,
    InfiniteScrollerDirective,
    ExperienceCardComponent,
    EducationCardComponent,
    DropdownSelectComponent,
    DropdownMultiselectComponent,
    SearchPipe,
    DatemonthPipe,
    LandingCardComponent,
    ProfileLeftmenuComponent,
    MediaCardComponent,
    FundingCardComponent,
    PeopleCardComponent,
    FoundersCardComponent,
    RichTextEditorComponent,
    JobDescriptionSuccessPopupComponent,
    LinkedinloginComponent,
    ApplyJobComponent,
    ApplyJobSuccessComponent,
    DeleteStartupPopupComponent,
    DateAgoPipe,
    DashboardHeaderComponent,
    StartupHeaderComponent,
    MenuComponent,
    NotificationComponent,
    NotificationWithActionComponent,
    InactivityComponent,
    ErrorComponent,
    NoInternetComponent,
    IncompatibleComponent,
    BulkUploadErrorPopupComponent,
    InviteadminComponent,
    BulkUploadPopupComponent,
    MystartupCardComponent,
    JobCardComponent,
    DashboardJobCardComponent,
    StartupCardComponent,
    InvestorCardComponent,
    PastInvestmentCardComponent,
    CorporateHeaderComponent,
    UserProfileCardComponent,
    InvestorHeaderComponent,
    FinshipCardComponent,
    FilterDropdownComponent,
    EmptyScreenCardComponent,
    ConnectButtonComponent,
    ProblemStatementPopupComponent,
    RefreshParentComponent,
    PreviewJdPopupComponent,
    ImageCropComponent,
    ResearchReportCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    ShareButtonsModule,
    ShareModule,
    ShareIconsModule,
    ImageCropperModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslatorFactory,
        deps: [HttpClient]
      },
      isolate: true
    })
  ],
  exports: [
    TranslateModule,
    LoaderComponent,
    HeaderComponent,
    PopupComponent,
    ImageCropperModule,
    ReactiveFormsModule,
    FormsModule,
    FooterComponent,
    ForgotPasswordPopupComponent,
    ToasterComponent,
    DebounceDirective,
    InfiniteScrollerDirective,
    ExperienceCardComponent,
    EducationCardComponent,
    PageNotFoundComponent,
    DropdownSelectComponent,
    DropdownMultiselectComponent,
    SearchPipe,
    DatemonthPipe,
    LandingCardComponent,
    ProfileLeftmenuComponent,
    MediaCardComponent,
    FundingCardComponent,
    PeopleCardComponent,
    FoundersCardComponent,
    RichTextEditorComponent,
    DateAgoPipe,
    DashboardHeaderComponent,
    StartupHeaderComponent,
    NotificationComponent,
    MystartupCardComponent,
    JobCardComponent,
    DashboardJobCardComponent,
    StartupCardComponent,
    InvestorCardComponent,
    PastInvestmentCardComponent,  
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    PastInvestmentCardComponent,
    CorporateHeaderComponent,
    ConnectButtonComponent,
    UserProfileCardComponent,
    InvestorHeaderComponent,
    FinshipCardComponent,
    FilterDropdownComponent,
    EmptyScreenCardComponent,
    RefreshParentComponent,
    ResearchReportCardComponent
  ]
})
export class SharedModule {
  constructor(public translateService: TranslateService) {
    translateService.setDefaultLang('en'); /* Setting up the Translate Json to English - `en` */
  }
}
