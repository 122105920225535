<div id="signInPopup" class="bg-white width100">
    <div class="pad10 header flex flexAlignItemsCenter flexJustifyCenter posRelative">
        <img src="assets/images/connex_door_logo.svg">
        <div class="iconClose ripple cursorPtr" (click)="closePopup()">
            <i class="icns icon-close d15"></i>
        </div>
    </div>
    <div class="body">
        <div class="height100p">
            <div class="messageHeader font24 padtop15 txtCenter padbtm30">{{'Login to ConnexDoor account' | translate}}
            </div>
            <div class="flex flexResponsive padbtm15">
                <div class="width55 signInFormContainer flex flexJustifyCenter">
                    <form class="width73" [formGroup]="signInForm" autocomplete="off">
                        <ng-container formGroupName="user">
                            <div class="width100 mrgnbtm20">
                                <div class="materialTextfield width100 ">
                                    <input [ngClass]="{'borderRed': isInvalidInput || (f('username')?.invalid && (f('username')?.dirty || f('username')?.touched))}" autocomplete="off" id="username" formControlName="username" class="width100" placeholder=" " type="text" appDebounce [debounceTime]="100"
                                        (debounceClick)="checkIsValidMobileOrEmail($event)">
                                    <label [ngClass]="{'redClr': isInvalidInput || (f('username')?.invalid && (f('username')?.dirty || f('username')?.touched))}">{{'e mail or mobile number' | translate}}</label>
                                </div>
                                <ng-container *ngIf="f('username')?.touched || f('username')?.dirty">
                                    <div class="helpBlock" *ngIf="f('username')?.errors?.required">
                                        {{"this field is required" | translate}}
                                    </div>
                                    <div class="helpBlock" *ngIf="isInvalidInput && !f('username').errors?.required">
                                        {{"required valid mobile no or email id" | translate}}
                                    </div>
                                </ng-container>
                            </div>
                            <div class="width100 mrgnbtm20">
                                <div class="materialTextfield width100  passwordEyeContainer">
                                    <input autocomplete="new-password" [ngClass]="{'borderRed': f('password')?.invalid && (f('password')?.dirty || f('password')?.touched)}" formControlName="password" id="password" class="width100" placeholder=" " type="{{passwordType}}">
                                    <label [ngClass]="{'redClr': f('password')?.invalid && (f('password')?.dirty || f('password')?.touched)}">{{'password' | translate}}</label>
                                    <div class="iconRound passwordEye ripple">
                                        <i class="icns d20 icon-visibiliy" (click)="toggleEye()" [ngClass]="{'icon-visibiliy-off': passwordType == 'password', 'icon-visibiliy': passwordType == 'text'}"></i>
                                    </div>
                                </div>
                                <ng-container *ngIf="f('password').invalid && (f('password').dirty || f('password').touched)">
                                    <div class="helpBlock" *ngIf="f('password').errors?.required">
                                        {{"this field is required" | translate}}
                                    </div>
                                </ng-container>
                            </div>
                            <!-- <div class="helpBlock" *ngIf="errorMsg">
                                {{ errorMsg }}
                            </div> -->
                            <div style="display: inline-flex;">
                                <div class="forgotPassword txtTransformCaps font14 padbtm20 cursorPtr" (click)="forgotPassword()">
                                    {{'forgot password' | translate}}
                                </div>
                                <div class="forgotPassword txtTransformCaps font14 padbtm20 padleft130 cursorPtr" (click)="signUp()">
                                    {{'sign up' | translate}}
                                </div>
                            </div>
                            <div class="flex flexJustifyCenter">
                                <button type="button" class="btn btn-continue ripple signUp" [ngClass]="{'disabled': signInForm.invalid}" (click)="onSubmit()">
                                    {{'login' | translate}}
                                </button>
                            </div>
                        </ng-container>

                    </form>
                </div>
                <div class="width45 socialSignInMainContainer flex flexDirectionCol flexAlignItemsCenter">
                    <img src="assets/images/sign_with_linkedin.svg" class="mrgnbtm15 cursorPtr padtop30 " (click)="socialSignIn('linkedin')">
                    <!-- <img src="assets/images/sign_with_facebook.svg" class="mrgnbtm15 cursorPtr" (click)="socialSignIn('facebook')">
                    <img src="assets/images/sign_with_google.svg" class="mrgnbtm15 cursorPtr" (click)="socialSignIn('google')"> -->
                </div>
            </div>
        </div>
    </div>
</div>