import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-card',
  templateUrl: './landing-card.component.html',
  styleUrls: ['./landing-card.component.scss']
})
export class LandingCardComponent implements OnInit {
  @Input() public cardData;
  constructor(private router: Router) { }
  ngOnInit() {
  }

  onJobView(jobtitle: string, id:number) {
    this.router.navigateByUrl('/jobs/detail/'+ btoa(`${id}`));
  }
}
