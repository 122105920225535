<div id="jdPopup" class="bg-white width100 height60p">
    <div class="iconClose ripple cursorPtr" (click)="closePopup()">
        <i class="icns icon-close d15"></i>
    </div>
    <div class="body">
        <div class="height30p width100" class="jd_thankyou">
            <div class="jd_thankyou_img width30">         
                <div class="pad20"> <img src="assets/images/noun_Door.png" class="noundoor"></div>  
            </div>
            <div class="flex pad20 width70 font18 jd_thankyou_txt" >
                <p>Thankyou for posting your job <br/>with Connexdoor!</p>
            </div>
        </div>

        <div class="height30p width100" class="jd_thankyou_alert">
            <div class="jd_thankyou_img width30">         
                <div class="pad20"> <img src="assets/images/alert-icon.svg" class="alert-icon"></div>  
            </div>
            <div class="flex pad10 width70 font12 jd_view_now">
                <p>Your job will be posted on our job field after verification.You can<br/>
                    see the list of applicants on your dashboard.
                    <a  routerLink="/dashboard/mystartups"  class="cursorPtr view_nw">view now</a></p>
            </div>
        </div>       
    </div>
</div>