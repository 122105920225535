import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppService } from '../../../core/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { HomeService } from './home.service';
import { AppStore } from 'src/app/core/stores/app.store';
import { isValidEmail } from 'src/app/core/essentials/common.libs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('scrollBox') scrollBox: ElementRef;
  jobURL: any = { "job": "/jobs", "company": "/dashboard/mystartups/organization-list", "fintech startup": "/startup", "investor": "/investors", "bfsi corporate": "/corporates", "individual": "/people/list", "finship": "/finsip" }
  subscription: Subscription = new Subscription();
  recentJobs: any;
  userType:string;
  partner: any;
  feedbackCard:boolean =false;
  feedbackForm: any;
  submitted:boolean=false
  invalidEmail:boolean=false;
  partnerScroll:boolean=false;
  @ViewChild('outSection') outSection: ElementRef;
  constructor(
    private appService: AppService,
    private route: ActivatedRoute ,
    private router: Router,
    private formBuilder: FormBuilder,
    private homeService : HomeService,
    private appStore: AppStore,
    private renderer: Renderer2
  ){
    this.renderer.listen('window', 'click', (e: Event) => {
        if (this.outSection.nativeElement.contains(e.target)) {
          this.feedbackCard = false;
        }
    });
    this.route.queryParams.subscribe(params => {
      this.userType = params['type'];
    });
    this.feedbackForm = this.formBuilder.group({
      "name": ['', [Validators.required,Validators.pattern('^[a-zA-Z .]+$')]],
      "email": [''],
      "discription": ['', Validators.required],
    });
  }
  
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  /* job Action BTN */
  jobCTA(actionType?: string) {
    this.router.navigate([`${this.jobURL[actionType]}`]);
    /* 
      if (this.appService.isLoggedIn()) {
        
      } else {
        if(actionType == 'job'){
          this.appService.togglePopup({
            type: 'findOrPostSignUp',
            load: true,
            refreshParent: false,
            overlayClose: true,
            context: {
              // profileType: actionType
            }
          });
        }else{
          this.appService.togglePopup({
            type: 'signUp',
            load: true,
            refreshParent: false,
            overlayClose: true,
            context: {
              profileType: actionType
            }
          });
        }
      }
    */
  }

  checkEmail(){
    if (isValidEmail(this.f.email.value)) {
      this.invalidEmail = false;
    }
    else{
      this.invalidEmail = true;
    }
  }

  enableFeedbackCard(){
    this.feedbackCard = true
  }

  get f() { return this.feedbackForm.controls; }

  submitFeedback(){
    this.submitted = true
    if(this.f.email.value == 0){
      this.invalidEmail = false;
    }
    if (this.feedbackForm.invalid || this.invalidEmail) {
      return;
    }
    var data
    if(this.appService.isLoggedIn()){
      data = {
        name : this.f.name.value,
        email : this.f.email.value,
        message : this.f.discription.value,
        user_id : this.appStore.userPermissions.user.id
      }
    } else {
      data = {
        name : this.f.name.value,
        email : this.f.email.value,
        message : this.f.discription.value,
      }
    }
    this.homeService.feedbackFormSend(data).subscribe(response => {
      this.appService.toggleToast(
        {
          type: 'success',
          load: true,
          position: 'bottom-center',
          context: { description: `Feedback Submitted successfully` }
        }
      );
      this.feedbackCard = false
      this.feedbackForm.reset()
      this.submitted=false
    })
  }

  // scrollLeft(){
  //   document.getElementById('sliderDiv').scrollLeft -= 130;
  // }

  // scrollRight(){
  //   document.getElementById('sliderDiv').scrollLeft += 130;
  // }

  ngOnInit() {
  /**if user came through invitation show signup popup */
    if(this.userType  == 'invitation'){
        this.signUp();   
    }
    this.getRecentJobs();
    this.getpartners();
  }

  signUp() {
    this.appService.togglePopup({
      type: 'signUp',
      load: true,
      refreshParent: true,
      overlayClose: true,
      context: {
        job_profile_type: "invitation"
      }
    });
  }

  getRecentJobs() {
    this.subscription.add(
      this.appService.getRecentJobs().subscribe(response => {
        this.recentJobs = response;
      })
    );

  }

  getpartners() {
    this.subscription.add(
      this.appService.getpartners().subscribe(response => {
        this.partner = response;
        if(this.partner.length > 4 ){
          this.partnerScroll = true
        }
      })
    );
  }

  navigateCalculator() {
    window.open('https://startupfalcon.com/signup?via=sagrika', "_blank");
  }

  scrollLeft(){
    this.scrollBox.nativeElement.scrollLeft -= 382;
  }

  scrollRight(){
    this.scrollBox.nativeElement.scrollLeft += 382;
  }
  

}
