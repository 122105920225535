<div id="commonErrorPopup" class="bg-white width100 height60p">
    <div class="body">
        <!-- 401 Error Message -->
        <div class="height30p flex width100" class="mainBox" *ngIf="popup.context.status == '401'">
            <div class=" pad20 width50 font18" >
                <!-- <img src="assets/images/idleicn.svg" /> -->
                <!-- <img src="assets/images/internalServerError.svg" /> -->
                <img src="assets/images/userAlreadyAppliedError.svg" class="errorImg"/>
            </div>
            <div class="height30p width50 flex" class="button_div">
                <span class="title">{{'status' | translate:{popupStatus: popup.context.status} }}</span>
                <p class="description">{{'session expired login again' | translate}}</p>
                <div class="flex mrgntop30">
                    <button class="btn btn-sm red mrgnauto" [routerLink]="['/logout']" routerLinkActive="router-link-active"
                    (click)="closePopup('icon')">{{'Login Again' | translate}}</button>
                </div>
            </div>   
        </div>
        <!-- 401 Error Message -->
        <!-- 400 Error Message -->
        <div class="height30p flex width100" class="mainBox" *ngIf="popup.context.status == '400'">
            <div class=" pad20 width50 font18" >
                <!-- <img src="assets/images/idleicn.svg" /> -->
                <!-- <img src="assets/images/internalServerError.svg" /> -->
                <img src="assets/images/userAlreadyAppliedError.svg" class="errorImg"/>
            </div>
            <div class="height30p width50 flex" class="button_div">
                <span class="title">{{'status' | translate:{popupStatus: popup.context.status} }}</span>
                <p class="description">{{ popup.context.error.resultMessage }}</p>
                <div class="flex mrgntop30">
                    <button class="btn red mrgnauto" (click)="closePopup('icon')">{{'close' | translate}}</button>
                </div>
            </div>   
        </div>
        <!-- 400 Error Message -->   
        <!-- 500 Error Message -->
        <div class="height30p flex width100" class="mainBox" *ngIf="popup.context.status == '500' || popup.context.status == '404'">
            <div class=" pad20 width50 font18" >
                <!-- <img src="assets/images/idleicn.svg" /> -->
                <img src="assets/images/internalServerError.svg" class="errorImg"/>
                <!-- <img src="assets/images/userAlreadyAppliedError.svg" class="errorImg"/> -->
            </div>
            <div class="height30p width50 flex" class="button_div">
                <span class="title">{{ popup.context.title }}</span>
                <p class="description">{{ popup.context.description }}</p>
                <div class="flex mrgntop30">
                    <button class="btn red mrgnauto" (click)="closePopup('icon')">{{'close' | translate}}</button>
                </div>
            </div>   
        </div>
        <!-- 500 Error Message -->   
        <!-- Generic Error Message -->
        <div class="height30p flex width100" class="mainBox" *ngIf="popup.context.status == 'error'">
            <div class=" pad20 width50 font18" >
                <!-- <img src="assets/images/idleicn.svg" /> -->
                <!-- <img src="assets/images/internalServerError.svg" /> -->
                <img src="assets/images/userAlreadyAppliedError.svg" class="errorImg"/>
            </div>
            <div class="height30p width50 flex" class="button_div">
                <span class="title">{{ popup.context.title }}</span>
                <p class="description">{{ popup.context.description }}</p>
                <div class="flex mrgntop30">
                    <button class="btn red mrgnauto" (click)="closePopup('icon')">{{'close' | translate}}</button>
                </div>
            </div>   
        </div>
        <!-- Generic Error Message -->                      
    </div>
</div>