<div id="detailPage" class=" bg-white">

    <div class="width100">
        <div class="iconClose d25 ripple cursorPtr" (click)="closePopup()">
            <i class="icns icon-close d10"></i>
        </div>
        <div class="padtop30 padleft15 padright15">
            <div class="width100 posRelative">
                <div class="start-right-box">
                    <div class="right-content">
                        <div class="content">
                            <div class="flex width100">
                                <div class="width100">
                                    <div class="circular-bold font24 flex flexAlignItemsCenter flexResponsive">
                                        <span class="padright10">
                                        {{jdDetails?.title}}
                                        
                                    </span>
                                        <span class="chips-head">
                                        {{jdDetails?.employment_type}}
                                </span>
                                        <span class="chips-head" *ngIf="jdDetails?.remote_work">Remote</span>
                                    </div>
                                    <div class="padtop10 color707070 roboto-medium font14 subtext">
                                        {{jdDetails?.role_description}}
                                    </div>
                                    <div class="roboto-regular font13 padtop10 padbtm10"><span class=" roboto-bold">
                                    {{location}}
                                   
                                </span></div>

                                    <div class="roboto-regular font13 padtop10 padbtm10 flex ">
                                        <div class="circular-bold font16 COLOR38453F padbtm10">{{'Number of positions : ' | translate }}</div>
                                        <span class="roboto-bold flex padleft10 font14 lineHeight22">{{jdDetails?.no_of_positions}}</span>
                                    </div>
                                    <div class="flex roboto-bold font16 color707070 padtop15" *ngIf="jdDetails?.salary!= 'do not display'">
                                        <img src="assets/images/cash.svg" />
                                        <span *ngIf="jdDetails?.salary!= 'do not display'" class="padleft10 txtTransformCaps">{{jdDetails?.salary}}</span>
                                        <span *ngIf="jdDetails?.equity!= 'do not display'" class="padleft10 txtTransformCaps">{{jdDetails?.equity}}</span>
                                    </div>
                                    <div class="flex padtop30 flexResponsive">
                                        <div class="width50">
                                            <div class="circular-bold font16 COLOR38453F padbtm10">Roles</div>
                                            <span class="roles roboto-medium">{{role}}</span>

                                        </div>
                                        <div class="width50" *ngIf="skills?.length >0">
                                            <div class="circular-bold font16 COLOR38453F padbtm20">Skills</div>
                                            <span class="skills" *ngFor="let skillslist of skills">
                                        {{ skillslist }}
                                    </span>
                                        </div>

                                    </div>
                                    <div class="padtop25" *ngIf="jdDetails?.description">
                                        <div class="circular-bold font16 COLOR38453F padbtm5">Job description
                                        </div>
                                        <div class="roboto-medium font14 color707070" [innerHTML]="jdDetails?.description"></div>
                                    </div>
                                    <div class="ml-auto">
                                        <img src="assets/images/bookmark.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>