<app-popup (refreshPage)="initPage($event)"></app-popup>
<header id="headerComponent">
    <div class="bg-white headerContainer maxHeight70px flex flexJustifyBetween flexAlignItemsCenter">
        <div class="flex flexJustifyStart flexAlignItemsCenter width80 flex1">
            <img src="assets/images/connex_door_logo.svg" [routerLink]="['/home']" class="desktopVisible cursorPtr mrgnbtm10">
            <img src="assets/images/connexdoor_mobile_logo.svg" [routerLink]="['/home']" class="mobiVisible connexDoorLogo">
            <div class="desktopVisible">
                <div class="leftNavigationContainer flex flexJustifyBetween flexAlignItemsCenter ">
                    <a class=" mrgnleft10 mrgnright10" [routerLink]="['/startup']" [routerLinkActive]="'active'">{{'Startups' | translate}}</a>
                    <a class=" blk mrgnleft5 mrgnright5 " [routerLink]="['/jobs']" [routerLinkActive]="'active'">{{'Jobs' | translate}}</a>
                    <a class=" blk mrgnleft5 mrgnright5" [routerLink]="['/investors']" [routerLinkActive]="'active'">{{'investors' | translate}}</a>
                    <a class=" blk mrgnleft5 mrgnright5" [routerLink]="['/corporates']" [routerLinkActive]="'active'">{{'corporates' | translate}}</a>
                    <a class=" blk mrgnleft5 mrgnright5" [routerLink]="['/people/list']" [routerLinkActive]="'active'">{{'people' | translate}}</a>
                    <a class="elementToFadeInAndOut flex mrgnleft5 mrgnright5" [routerLink]="['/fintecastic-women']">
                        <img src="assets/images/mike.svg" alt="">
                        <img src="assets/images/Fintech program.svg" alt="">
                    </a>
                    <!-- <a class="blk elementToFadeInAndOut width10 mrgnleft5 mrgnright5" [routerLink]="['/fintech-cafe']"
                          [routerLinkActive]="'active'">{{'Fintech Cafe' | translate}}</a>                 -->
                </div>
            </div>

        </div>

        <div class=" flex1 width50 desktopVisible">
            <div class="flex flexJustifyEnd  width100" *ngIf="!(appService.isLoggedIn())">
                <button class="btn btn-signup ripple" (click)="signUp()">{{'Sign up' | translate}}</button>
                <button class="btn btn-signin ripple mrgnleft15" (click)="signIn()">{{'Sign in' | translate}}</button>
            </div>


            <div class="flex flexJustifyEnd width100" *ngIf="(appService.isLoggedIn())">
                <ul>
                    <li>
                        <span class="posRelative displayInline">
                            <div class="ripple cursorPtr icon-btn" (click)="isNotificationShow = !isNotificationShow">
                                <i class="icns icon-bell d25"></i>
                            </div>
                            <span class="bubble posAbsolute posTop posRight"
                                *ngIf="appStore.notfcationList?.length != 0">{{appStore.notfcationList.length}}
                            </span>
                        </span>
                    </li>
                    <li>
                        <a>
                            <div class="ripple cursorPtr icon-btn"><i class="icns icon-user d25"></i></div>
                        </a>
                        <ul class="dropdown cursorPtr">
                            <li [routerLink]="['/user-profile',appStore?.userPermissions?.user?.user_profile?.id]" class="ripple roboto-medium"><a><span>{{'my profile' | translate}}</span></a></li>
                            <li [routerLink]="['/dashboard']" class="ripple roboto-medium"><a><span>{{'Dashboard' | translate}}</span></a></li>
                            <li (click)="logout()" class="ripple"><a class="cursorPtr roboto-medium"><span>{{'logout' | translate}}</span></a></li>
                        </ul>
                    </li>
                </ul>

                <!-- <div class="ripple cursorPtr icon-btn" (click)="isNotificationShow = !isNotificationShow">
                    <i class="icns icon-bell d25"></i>
                </div>
                <span class="desktopVisible">
                    <div class="ripple cursorPtr icon-btn"
                        [routerLink]="['/user-profile',appStore?.userPermissions?.user?.user_profile?.id]">
                        <i class="icns icon-user d25"></i>
                    </div>
                </span>

                <div class="ripple cursorPtr desktopVisible icon-btn" style="padding-left: 15px;
                width: 65px;
                color: #eab328;" (click)="logout()">
                    <i class="icns icon-user d25"></i> -->
                <!-- <span>Logout</span>
                </div> -->
            </div>
        </div>
        <div class="ripple cursorPtr icon-btn mobiVisible" (click)="toggleMenu()">
            <i class="fa fa-bars font30 COLOR319966" aria-hidden="true"></i>
        </div>
    </div>
</header>
<div class="width25 notificationHeader" *ngIf="isNotificationShow">
    <app-notification [data]="appStore.notfcationList" (closeEvent)="closeEvent($event)"></app-notification>
</div>