import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { config } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    public http: HttpClient,
  ) { }

  feedbackFormSend(data: object) {
    return this.http.post(`${config.apiURL}${config.feedbacksURI}.json`, data)
  }


}
