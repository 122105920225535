<!-- Popup for no-internet starts -->
<!-- <div id="noInternetPopUp">
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
            <g id="Group_13312" data-name="Group 13312" transform="translate(14806 14537)">
                <g id="Group_13305" data-name="Group 13305" transform="translate(-14775 -14505.188)">
                    <path id="Path_15880" data-name="Path 15880"
                        d="M22.982,63.239A2.222,2.222,0,0,1,20.8,61.057V47.8a2.559,2.559,0,0,1,1.175-2.014l15.777-8.56a2.217,2.217,0,1,1,2.182,3.86l-14.6,7.889V60.889A2.522,2.522,0,0,1,22.982,63.239Z"
                        transform="translate(14.111 25.045)" fill="#fff" />
                    <path id="Path_15881" data-name="Path 15881"
                        d="M46.584,42.542A10.742,10.742,0,1,1,35.842,31.8,10.709,10.709,0,0,1,46.584,42.542Z"
                        transform="translate(17.028 21.573)" fill="#259bc9" />
                    <path id="Path_15891" data-name="Path 15891"
                        d="M36.477,59.738a9.784,9.784,0,0,1-4.028,8.056,9.972,9.972,0,0,1-6.21,2.182A10.238,10.238,0,1,1,36.477,59.738Z"
                        transform="translate(10.854 33.581)" fill="#259bc9" />
                    <rect id="Rectangle_3661" data-name="Rectangle 3661" width="20.477" height="48.842"
                        transform="translate(26.854 93.319)" fill="#32b7e0" />
                    <path id="Path_15892" data-name="Path 15892"
                        d="M59.813,79.816V99.286H5.6v-19.3A9.217,9.217,0,0,1,9.8,72.1l13.259-8.9H43.532l12.42,8.9A9.242,9.242,0,0,1,59.813,79.816Z"
                        transform="translate(3.799 42.875)" fill="#259bc9" />
                    <path id="Path_15893" data-name="Path 15893"
                        d="M59.813,79.767V94.7H5.6V79.767A9.562,9.562,0,0,1,15.167,70.2H50.413A9.421,9.421,0,0,1,59.813,79.767Z"
                        transform="translate(3.799 47.624)" fill="#32b7e0" />
                    <path id="Path_15894" data-name="Path 15894"
                        d="M60.084,81.943H5.871a.671.671,0,0,1,0-1.343H60.084a.722.722,0,0,1,.671.671C60.923,81.607,60.587,81.943,60.084,81.943Z"
                        transform="translate(3.528 54.68)" fill="#bf0202" />
                    <path id="Path_15895" data-name="Path 15895"
                        d="M60.084,80.143H5.871a.671.671,0,0,1,0-1.343H60.084a.722.722,0,0,1,.671.671C60.923,79.807,60.587,80.143,60.084,80.143Z"
                        transform="translate(3.528 53.458)" fill="#bf0202" />
                    <circle id="Ellipse_2305" data-name="Ellipse 2305" cx="1.007" cy="1.007" r="1.007"
                        transform="translate(30.547 96.508)" fill="#be1e2d" />
                    <circle id="Ellipse_2306" data-name="Ellipse 2306" cx="1.007" cy="1.007" r="1.007"
                        transform="translate(30.547 100.872)" fill="#00141b" />
                    <g id="Group_11846" data-name="Group 11846" transform="translate(80.293 -44.346) rotate(60)">
                        <g id="Group_11842" data-name="Group 11842" transform="translate(43.34)">
                            <path id="Path_15882" data-name="Path 15882"
                                d="M91.578,89.491a19.133,19.133,0,0,1-3.189,1.678C70.6,99.394,49.115,92.177,36.862,76.064a46.433,46.433,0,0,1-5.539-9.231c-11.077-24.84-5.2-55.555,15.609-65.29A27.984,27.984,0,0,1,50.29.2C44.919,10.438,47.1,30.747,56.668,51.391,61.7,62.133,67.745,71.2,74.123,77.91h0C80.165,84.12,86.208,88.148,91.578,89.491Z"
                                transform="translate(-25.822 0.136)" fill="#fff" />
                            <path id="Path_15883" data-name="Path 15883"
                                d="M40.568,0c0,.168-.168.168-.168.336a1.009,1.009,0,0,1,.671-.168A.617.617,0,0,0,40.568,0Z"
                                transform="translate(-15.933)" fill="#fff" />
                            <path id="Path_15884" data-name="Path 15884"
                                d="M65.671,53.1c-.168.168-.168.336-.336.5H65A2.317,2.317,0,0,0,65.671,53.1Z"
                                transform="translate(0.756 36.023)" fill="#fff" />
                        </g>
                        <path id="Path_15885" data-name="Path 15885"
                            d="M83.335,89.627C74.1,87.781,62.859,77.542,53.8,62.6h0a113.212,113.212,0,0,1-5.874-11.077C38.354,30.715,36.172,10.07,41.711,0,53.963,1.846,68.9,15.945,78.636,36.421c.168.336.168.5.336.839s.168.5.336.839l.5,1.007A98.88,98.88,0,0,1,86.86,62.772C88.371,73.682,87.2,83.417,83.335,89.627Z"
                            transform="translate(26.265)" fill="#c8d3d7" />
                        <path id="Path_15886" data-name="Path 15886"
                            d="M49.2,34.1c-.336,0-.671-.168-.671-.5a.748.748,0,0,1,.5-1.007L75.38,23.025a.8.8,0,1,1,.5,1.511L49.532,34.1Z"
                            transform="translate(32.877 15.578)" fill="#259bc9" />
                        <path id="Path_15887" data-name="Path 15887"
                            d="M71.452,23.443A5.293,5.293,0,1,1,64.4,20.926,5.148,5.148,0,0,1,71.452,23.443Z"
                            transform="translate(41.673 13.817)" fill="#be1e2d" />
                        <path id="Path_15890" data-name="Path 15890"
                            d="M81.572,91.113c-3.86,0-9.9-3.692-16.113-9.9-5.2-5.035-12.756-14.1-19.973-28.029A83.883,83.883,0,0,1,35.92,16.927C35.584,7.7,37.094,2.157,37.262,1.822s.5-.671.839-.5.671.5.5.839h0a55.329,55.329,0,0,0-1.175,14.77A80.954,80.954,0,0,0,47,52.509,106.6,106.6,0,0,0,66.634,80.2c5.874,5.874,11.749,9.4,15.106,9.4a.722.722,0,0,1,.671.671A.793.793,0,0,1,81.572,91.113Z"
                            transform="translate(24.335 0.864)" fill="#bf0202" />
                        <path id="Path_15896" data-name="Path 15896"
                            d="M87.116,58.827a19.133,19.133,0,0,1-3.189,1.678C66.136,68.73,44.652,61.513,32.4,45.4c3.86,2.518,15.106,7.553,37.261,2.014h0C75.7,53.456,81.745,57.485,87.116,58.827Z"
                            transform="translate(21.98 30.8)" fill="#bdd9e5" />
                    </g>
                    <path id="Path_15897" data-name="Path 15897"
                        d="M65.671,53.1c-.168.168-.168.336-.336.5H65A2.317,2.317,0,0,0,65.671,53.1Z"
                        transform="translate(44.096 36.023)" fill="#bdd9e5" />
                    <path id="Path_15898" data-name="Path 15898"
                        d="M48.687,89.11v19.47H39.12V84.243c0-2.014-3.692-5.707-6.881-8.392C30.056,74,28.21,72.494,28.21,72.494V59.738C28.21,53.864,22,49.5,22,49.5A10.172,10.172,0,0,1,32.238,59.738V72.494l12.42,8.9A8.983,8.983,0,0,1,48.687,89.11Z"
                        transform="translate(14.925 33.581)" fill="#bdd9e5" />
                    <path id="Path_15899" data-name="Path 15899"
                        d="M136.958,85.811H.671A.768.768,0,0,1,0,84.971.722.722,0,0,1,.671,84.3H136.79a.722.722,0,0,1,.671.671A.587.587,0,0,1,136.958,85.811Z"
                        transform="translate(0 57.19)" fill="#fff" />
                </g>
                <rect id="bound" width="200" height="200" transform="translate(-14806 -14537)" fill="none" />
            </g>
        </svg>

    </div>
    <h4 class="title">{{popup.context.title | translate}}</h4>
    <div class="description mrgnauto">{{popup.context.description | translate}}</div>
</div> -->
<!-- Popup for no-internet Ends -->

<div class="height30p flex width100" class="mainBox" >
    <div class=" pad20 width50 font18" >
        <!-- <img src="assets/images/idleicn.svg" /> -->
        <!-- <img src="assets/images/internalServerError.svg" /> -->
        <img src="assets/images/userAlreadyAppliedError.svg" class="errorImg"/>
    </div>
    <div class="height30p width50 flex" class="button_div">
        <span class="title">{{ popup.context.title }}</span>
        <p class="description">{{ popup.context.description }}</p>
        <div class="flex mrgntop30">
            <button class="btn red" (click)="closePopup('icon')">{{'close' | translate}}</button>
        </div>
    </div>   
</div>