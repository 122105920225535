<!-- Popup for inactivity starts -->
<!-- <div id="incompatiblePopUp">
    <div>
        <i class="icns icon-oops d200"></i>
    </div>
    <h4 class="title">{{popup.context | translate}}</h4>
    <div class="description mrgnauto">{{'you will time out in' | translate:{idleCountdown: appStore.idleCountdown} }}
    </div>
    <div class="pad20">
        <button class="btnNoInternet ripple mrgnauto" (click)="closePopup('icon')">{{'close' | translate}}</button>
    </div>
</div> -->
<!-- Popup for inactivity ends -->

<div id="commonErrorPopup" class="bg-white width100 height60p">
    <div class="body">
        <div class="height30p flex width100" class="mainBox">
            <div class=" pad20 width50 font18" >
                <img src="assets/images/idleicn.svg" class="errorImg"/>
                <!-- <img src="assets/images/internalServerError.svg" /> -->
                <!-- <img src="assets/images/userAlreadyAppliedError.svg" class="errorImg"/> -->
            </div>
            <div class="height30p width50 flex" class="button_div">
                <span class="title">{{popup.context | translate}}</span>
                <p class="description">{{'you will time out in' | translate:{idleCountdown: appStore.idleCountdown} }}</p>
                <div class="flex mrgntop30">
                    <button class="btn btn-continue ripple" type="submit" (click)="closePopup()">{{'close' | translate}}</button>
                </div>
            </div>   
        </div>
    </div>
</div>