import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PopupModel } from 'src/app/core/models';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent {

  @Input('popup') popup: PopupModel = new PopupModel();
  @Output('close') close = new EventEmitter();
  constructor() { }

  /* Close Popup Icon Method */
  public closePopup(close_type ?: string){
    this.close.emit(close_type);
  }

}
