import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { config } from 'src/environments/environment';
import { MyStartupsStore } from '../store/mystartups.store';
import { AppService } from '../../../../core/services/app.service';
@Injectable({
  providedIn: 'root'
})
export class MyStartupsService {

  constructor(
    public http: HttpClient,
    public appService: AppService,
    private myStartupStore: MyStartupsStore
  ) {

  };


  /* get team api*/
  public getTeam(id: number) {
    return this.http.get(`${config.apiURL}${config.companyURI}/${id}/${config.teamURI}.json`, )
      .pipe(map(response => this.myStartupStore.apiResp = response));
  }

  /* post api team api*/
  public companyAdmin(data: any) {
    return this.http.post(`${config.apiURL}${config.companyAdminURI}.json`, data)
      .pipe(map(response => this.myStartupStore.apiResp = response));
  }




  /* fork join api for create job description page */
  public getJobDescriptionPageAPI() {
    return forkJoin([
      this.http.get(`${config.apiURL}${config.jobTitleURI}.json`),
      this.http.get(`${config.apiURL}${config.locationURI}.json`),
      this.http.get(`${config.apiURL}${config.skillURI}.json`),
    ]).pipe(map(
      response => this.myStartupStore.apiResp = response
    ));
  };

  /* job description form send */
  public jobDescriptionFormSend(data: object) {
    return this.http.post(`${config.apiURL}${config.jobPostingURI}.json`, data)
      .pipe(map(response => this.myStartupStore.apiResp = response));
  };

  /* update job description */
  public updateJobDescription(data: object, id: number) {
    return this.http.patch(`${config.apiURL}${config.jobPostingURI}/${id}.json`, data)
      .pipe(map(response => this.myStartupStore.apiResp = response));
  };

  /* api method for get organisation profile */
  public getOrganisationProfileData() {
    return this.http.get(`${config.apiURL}${config.companyProfilesURI}.json`)
      .pipe(map(response => this.myStartupStore.apiResp = response));
  };
  /** get job description from profile id */
  public getJobDescriptionDetails(id: any) {
    return this.http.get(`${config.apiURL}${config.jobPostingURI}/${id}.json`)
      .pipe(map(
        response => this.myStartupStore.apiResp = response
      ));
  }

  public updateBulkUpload(data: any, id) {
    return this.http.post(`${config.apiURL}${config.companyProfilesURI}/${id}/${config.uploadJobsURI}.json`, data)
      .pipe(map(response => this.myStartupStore.apiResp = response));
  };

  /** Send admin invite */
  public sendAdminInvite(data: any) {
    return this.http.post(config.apiURL + config.createLeadURI + ".json", data)
      .pipe(map(response => this.myStartupStore.apiResp = response));
  }


  /* api for get location list */
  getLocationList(data?: any) {
    return this.http.get(`${config.apiURL}${config.locationURI}.json`, { params: data })
      .pipe(map(
        response => this.myStartupStore.apiResp = response
      ));
  }

  /* api for get job titles list */
  getJobTitleList(data?: any) {
    return this.http.get(`${config.apiURL}${config.jobTitleURI}.json`, { params: data })
      .pipe(map(
        response => this.myStartupStore.apiResp = response
      ));
  }

  /* api for get location list */
  getSkillList(data?: any) {
    return this.http.get(`${config.apiURL}${config.skillURI}.json`, { params: data })
      .pipe(map(
        response => this.myStartupStore.apiResp = response
      ));
  }

  /** delete startup */
  deleteStartup(id:number){
    return this.http.delete(`${config.apiURL}${config.companyProfilesURI}/${id}.json`)
    .pipe(map(response => this.myStartupStore.apiResp = response));
  }

}
