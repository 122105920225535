import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { AppService } from 'src/app/core/services/app.service';
import { config } from 'src/environments/environment';
import { JobPostedStore } from '../store/jobsposted.store';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class JobspostedService {

  constructor(public http: HttpClient,
    public appService: AppService,
    private jobPostedStore: JobPostedStore
  ) { }

  /* get job post listed api */
  getJobPostedList(data?: any) {
    return this.http.get(`${config.apiURL}${config.jobPostingURI}/${config.myJobsURI}.json`, { params: data })
      .pipe(map(
        response => this.jobPostedStore.apiResp = response
      ));
  };

  /* fork join api for create profile */
  getJobPostedForkAPI() {
    return forkJoin([
      this.http.get(`${config.apiURL}${config.jobPostingURI}/${config.myJobsURI}.json`),
      this.http.get(`${config.apiURL}${config.companyProfilesURI}.json`),
    ]).pipe(map(
      response => this.jobPostedStore.apiResp = response
    ));
  }


  /* api method for get getJobApplicants */
  getJobApplicants(data?: any) {
    return this.http.get(`${config.apiURL}${config.jobApplicationsURI}.json`, { params: data })
      .pipe(map(response => this.jobPostedStore.apiResp = response));
  }

  /** api method to update the posting status */
  updateJobPostingStatus(id: number, data?: any) {
    return this.http.patch(`${config.apiURL}${config.jobPostingURI}/${id}.json`, data)
      .pipe(map(response => this.jobPostedStore.apiResp = response));
  }


  updateJobApplicantsStatus(data?: any) {
    return this.http.post(`${config.apiURL}${config.jobApplicationsURI}/${config.statusUpdateMultipleURI}.json`, data)
      .pipe(map(response => this.jobPostedStore.apiResp = response));
  }

}






