import { Injectable } from '@angular/core';
import { observable, action } from "mobx-angular";
import { MediaModel } from '../../../../core/models/media.model';
import * as _ from 'lodash';
import { ListingModel } from '../../../../core/models/other.model';
import { CompanyDetailModel ,CorporateListFormModel} from '../../../../core/models/company.model';
// import { JobsListedCompanyProfileModel } from '../../../../core/models/company.model';

@Injectable({
    providedIn: 'root'
})

export class CorporateProfileStore {
    @observable apiResp: any;
    @observable startupProfileInfo: any;
    @observable media: MediaModel = new MediaModel();
    @observable locationList: ListingModel[] = [];
    @observable companyList: ListingModel[] = [];
    @observable industryList: ListingModel[] = [];
    @observable corporateListForm: CorporateListFormModel = new CorporateListFormModel();
    @observable segmentList: ListingModel[] = [];
    @observable companyDetails: CompanyDetailModel = new CompanyDetailModel();
    @observable startupList: CompanyDetailModel[] =[];
    @observable investorList: ListingModel[] = [];
    // @observable myStartupList: JobsListedCompanyProfileModel[] = []
    constructor() { }

    /* create startup store action */
    @action createStartup() {
        this.locationList = this.apiResp[0];
        this.companyList = this.apiResp[1];
        this.industryList = this.apiResp[2];
        this.segmentList = this.apiResp[3];
    }

    @action getStartupList(){
        this.startupList =  this.apiResp.company_profiles;
    }
    /* company list  store action*/
    @action getCompanyList() {
        this.companyList = this.apiResp
    }

    /* company list  store action*/
    @action getLocationList() {
        this.locationList = this.apiResp
    }

    /* industry list  store action*/
    @action getIndustryList() {
        this.industryList = this.apiResp
    }

    /* segment list  store action*/
    @action getSegmentList() {
        this.segmentList = this.apiResp
    }


    @action updateCompanyProfile() {
        this.companyDetails.company_profile = this.apiResp
    }

    /* store action for media creation */
    @action createMedia() {
        this.media = this.apiResp;
    }

    /* company Details store action */
    @action getCompanyDetails() {
        this.companyDetails = this.apiResp;
    }
    // @action companyProfileInformation() {
    //     this.myStartupList = this.apiResp.company_profiles;

    // }

    /* get Investor List Store Action */
    @action getInvestorList() {
        this.investorList = this.apiResp;
    }


}