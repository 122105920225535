import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fintecastic-women',
  templateUrl: './fintecastic-women.component.html',
  styleUrls: ['./fintecastic-women.component.scss']
})
export class FintecasticWomenComponent {
  @ViewChild('scrollBox') scrollBox: ElementRef;
  currentTab='FinteCastic Women';
  stepNumber: number = 1;
  constructor(
    private router: Router
  ) { }

  /* Navigate page CTA Method */
  navigatePage(type ?: string) {
    switch (type) {
      case '-':
        this.stepNumber = this.stepNumber - 1;
        break;
      case '+':
        this.stepNumber = this.stepNumber + 1;
        break;
      default:
        this.stepNumber = this.stepNumber - 1;
        break;
    }
    this.redirectRouterPath();
  }

  /* method for redirect to specific paths */
  redirectRouterPath() {
    switch (this.stepNumber) {
      case 1:
        this.router.navigate(['/fintecastic-women']);
        break;
      case 2:
        this.router.navigate(['/fintech-cafe']);
        break;
      default:
        this.router.navigate(['/fintecastic-women']);
        break;
    }
  }

  scrollLeft(){
    this.scrollBox.nativeElement.scrollLeft -= 382;
  }

  scrollRight(){
    this.scrollBox.nativeElement.scrollLeft += 382;
  }

  navigateForm(){
    window.open('https://forms.zohopublic.in/connexdoor/form/FintecasticWomenInitiativebyConnexdoorincollaborat/formperma/qdw9RjRwA1zgzjmVqcEMcYp5fU020aInCe2RraMkDKU', '_blank')
  }


}
